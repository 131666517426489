import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer'
import Logo from '../../images/logo2.png'
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PageTitle from '../../components/pagetitle';
import ListadoClientes from '../../components/AdministracionClientes/ListadoClientes';
import PageLoader from '../../components/PageLoader/PageLoader';
import './style.scss'
const AdministracionClientesPage = () => {


    // const authenticated = useSelector(store => store.session)
    const isLoading = useSelector(store => store.pagina.isLoading)
    return (
        <Fragment>
            {isLoading && (<PageLoader />)}

            <Navbar2 Logo={Logo} />

            <PageTitle pagesub={'Administración / Clientes'} />
            <ListadoClientes />
            <Footer />
        </Fragment>
    )
};
export default AdministracionClientesPage;