import React, { useState } from "react";
import { ModalFooter, Modal, ModalBody, Button, Row, Col } from "react-bootstrap-v5";
import { ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";


const ModalInformacion = ({ mostrarModal, onCerrarModal }) => {

  const mensajeInformacion = useSelector((store) => store.modalInformacion.mensaje);


  return (
    <div>
      <Modal show={mostrarModal} centered>
        <ModalHeader>Atención</ModalHeader>
        <ModalBody>

          <Row>
            <Col xs={12} className="my-3">
              <p>{mensajeInformacion}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            onClick={(e) => onCerrarModal()}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalInformacion;
