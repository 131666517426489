import config from "../api/axiosAuthInterceptor"

const urlApi = process.env.REACT_APP_API_GATEWAY
const axios = config.iAxios;

export const getClientes = async () => {
    return axios.get(`${urlApi}/api/Cliente`).then(res => { return res.data })
}

export const getClientePorId = async (clienteId) => {
    return axios.get(`${urlApi}/api/Cliente/${clienteId}`).then(res => { return res.data })
}

export const getGenerarApiKeyPorClienteId = async (clienteId) => {
    return axios.get(`${urlApi}/api/Cliente/${clienteId}/generaApiKey`).then(res => { return res.data })
}

export const saveCliente = async (payload) => {
    return axios.post(`${urlApi}/api/Cliente`, payload).then(res => { return res.data })
}

export const updateCliente = async (payload) => {
    return axios.put(`${urlApi}/api/Cliente`, payload).then(res => { return res.data })
}
export const deleteCliente = async (clienteId) => {
    return axios.delete(`${urlApi}/api/Cliente/${clienteId}`).then(res => { return res.data })
}

export const saveSuscripcion = async (payload) => {
    return axios.post(`${urlApi}/api/Suscripcion`, payload).then(res => { return res.data })
}
