import React, { useEffect, useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { PerfilPlataformaEnum } from "../../helpers/Enums";
import { logout } from "../../appStatus/actions/sessionActions";
import { toast } from "react-toastify";

const MobileMenu = () => {
  const [menus, setMenus] = useState([
    {
      id: 1,
      title: "Home",
      link: "/home",
    },

    {
      id: 2,
      title: "Visor de Datos",
      link: "/",
    },
    {
      id: 3,
      title: "Laboratorio",
      link: "/",
      submenu: [
        {
          id: 31,
          title: "Laboratorio de Datos Sitio",
          link: "/labSitio",
        },
        {
          id: 32,
          title: "Laboratorio de Datos Multisitio",
          link: "/labMultiSitio",
        },
      ],
    },
  ]);
  const [isMenuShow, setMenuShow] = useState(false);
  const [isOpen, setOpen] = useState(0);

  const dispatch = useDispatch();

  const productos = useSelector((store) => store.pagina.productos);
  const authenticated = useSelector((store) => store.session.authenticated);
  const checked = useSelector((store) => store.session.checked);
  const user = useSelector((store) => store.session.user);
  const reportesVariables = useSelector((store) => store.pagina.reportes);

  const dominios = useSelector((store) => store.pagina.dominio);

  useEffect(() => {
    if (dominios) {
      const ubicacionMenuVisor = menus.map((x) => x.id).indexOf(2); // id=2 -> menu de Visor
      const subMenuDominios = dominios
        .filter((e) => e.IdDominio !== -1)
        .map((dominio, index) => {
          return {
            id: 20 + index,
            title: dominio.NombreCodigo,
            link: `/visor/${dominio.IdDominio}`,
          };
        });

      Object.assign(menus[ubicacionMenuVisor], { submenu: subMenuDominios });
      setMenus(menus);
    }
  }, [dominios]);

  useEffect(() => {
    if (reportesVariables) {
      const ubicacionMenuGraficos = menus.map((x) => x.id).indexOf(4); // id=4 -> menu de graficos
      if (ubicacionMenuGraficos) {
        const subMenuGraficosReportes = reportesVariables.map(
          (variable, index) => {
            return {
              id: 40 + index,
              title: variable.NombreVariable,
              link: `/`,
              submenu: variable.Reportes.filter(
                (item) => item.FlagReporte
              ).map((rep, index) => {
                return {
                  id: 400 + index,
                  title: rep.NombreReporte,
                  link: `/Graficos/${rep.IdReporte}`,
                };
              }),
            };
          }
        );
        const subMenuRepo = {
          id: 4,
          title: "Gráficos",
          link: `/`,
          submenu: subMenuGraficosReportes,
        };
        menus.push(subMenuRepo);
        setMenus(menus);
      }
    }
  }, [reportesVariables]);

  useEffect(() => {
    if (productos) {
      const ubicacionMenuProductos = menus.map((x) => x.id).indexOf(6); // id=6 -> menu de Productos
      if (ubicacionMenuProductos < 0) {
        const subMenuProductosInstitucion = productos.map(
          (institucion, index) => {
            return {
              id: 60 + index,
              title: institucion.Institucion,
              link: `/`,
              submenu: institucion.Productos.filter(
                (item) => item.MostrarEnMenu
              ).map((prod, index) => {
                return {
                  id: 600 + index,
                  title: prod.nombreProducto,
                  link: `/productos/${prod.Codigo}`,
                };
              }),
            };
          }
        );

        const subMenuProductos = {
          id: 6,
          title: "Productos",
          link: `/`,
          submenu: subMenuProductosInstitucion,
        };

        menus.push(subMenuProductos);
        setMenus(menus);
      }
    }
  }, [productos]);

  useEffect(() => {
    if (user && user.perfilId) {
      const ubicacionMenuAdministracion = menus.map((x) => x.id).indexOf(7); // id=7 -> menu de Adminitracion
      if (ubicacionMenuAdministracion < 0) {
        const opcionAdministracion = {
          id: 7,
          title: "Administración",
          link: "/",
          submenu: [],
        };

        const opcionAdmProductos = {
          id: 71,
          title: "Productos",
          link: "/administracion/productos",
        };
        const opcionAdmClientes = {
          id: 72,
          title: "Clientes",
          link: "/administracion/clientes",
        };
        const opcionAdmUsuario = {
          id: 73,
          title: "Usuarios",
          link: "/administracion/usuarios",
        };
        const opcionMiCliente = {
          id: 73,
          title: "Mi Cliente",
          link: "/administracion/cliente",
        };
        const subMenuAdministracion = [];
        switch (user.perfilId) {
          case PerfilPlataformaEnum.Administrador.value:
            subMenuAdministracion.push(
              opcionAdmProductos,
              opcionAdmClientes,
              opcionAdmUsuario
            );
            break;
          case PerfilPlataformaEnum.Super_Usuario.value:
            subMenuAdministracion.push(opcionAdmUsuario, opcionMiCliente);
            break;
          case PerfilPlataformaEnum.Usuario_Autentificado.value:
            subMenuAdministracion.push(opcionMiCliente);
            break;
          default:
            break;
        }
        opcionAdministracion.submenu = subMenuAdministracion;
        menus.push(opcionAdministracion);
        setMenus(menus);
      }
    }
  }, [user]);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const menuHandler = () => {
    setMenuShow(!isMenuShow);
  };

  const setIsOpen = (id) => {
    setOpen(id === isOpen ? 0 : id);
  };

  const checkIsOpen = (menuItem) => {
    //verifico si el menu abierto es el actual
    const isCurrentMenuOpen = menuItem.id === isOpen;

    if (isCurrentMenuOpen) {
      return true;
    } else {
      //si no es el actual, valido si es uno de sus hijos
      //tiene hijos?
      if (menuItem.submenu) {
        return menuItem.submenu.map((x) => x.id).indexOf(isOpen) >= 0;
      } else {
        //no tiene hijos, no esta abierto
        return false;
      }
    }
  };

  const renderMenu = (menuItem) => {
    return (
      <li key={menuItem.id}>
        {menuItem.submenu ? (
          <p onClick={() => setIsOpen(menuItem.id)}>
            {menuItem.title}
            {menuItem.submenu ? (
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            ) : (
              ""
            )}
          </p>
        ) : (
          <Link onClick={() => ClickHandler()} to={menuItem.link}>
            {menuItem.title}
          </Link>
        )}
        {menuItem.submenu ? (
          <Collapse isOpen={checkIsOpen(menuItem)}>
            <Card>
              <CardBody>
                <ul>
                  {menuItem.submenu.map((subMenu) => {
                    return renderMenu(subMenu);
                  })}
                </ul>
              </CardBody>
            </Card>
          </Collapse>
        ) : (
          ""
        )}
      </li>
    );
  };
  const onCerrarSession = (e) => {
    e.preventDefault();
    dispatch(logout());
    toast.success("Sesión cerrada.");
  };
  return (
    <div>
      <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
        <div className="menu-close">
          <div onClick={() => menuHandler()} className="clox">
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item) => {
            return renderMenu(item);
          })}
          {authenticated ? (
            <li>
              <a
                href="/"
                className="btn btn-outline-primary"
                onClick={(e) => onCerrarSession(e)}
              >
                Cerrar Sesión
              </a>
            </li>
          ) : (
            <li>
              <Link
                onClick={ClickHandler}
                className="btn btn-outline-primary"
                to="/login"
              >
                Iniciar Sesión
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className="showmenu" onClick={menuHandler}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};
/*
 {authenticated ?
                      <button className="btn btn-primary"
                        style={{ backgroundColor: '#0b5ed7', borderColor: '#0a58ca' }}
                        onClick={() => onCerrarSession()}>Cerrar Sesión</button>
                      :
                      <Link
                        onClick={ClickHandler}
                        className="btn btn-primary"
                        to="/login"
                      >
                        Iniciar Sesión
                      </Link>}
*/
export default MobileMenu;
