import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./index.css";
import api from "../../services/apiMenuService";
import ReCAPTCHA from "react-google-recaptcha";
import {
  setIsLoading,
  setIsScreenShoot,
} from "../../appStatus/actions/actionPagina";
import PageLoader from "../PageLoader/PageLoader";
import { pingUsuario } from "../../services/privateService";
import ModalError from "../Modals/ModalError";
// import { useForm } from "react-hook-form";

const PowerBi = ({
  reportId,
  requiereFiltros,
  filtrosReporte,
  calculadora,
  esTemporal
}) => {
  const dispatch = useDispatch();

  const formFiltroReporte = useSelector((store) => store.pagina.formularioFiltroReporte);
  const filtroCargado = useSelector((store) => store.pagina.filtroCargado);
  const isScreenShoot = useSelector((store) => store.pagina.isScreenShoot);

  const recaptchaRef = React.useRef();

  const [reportConf, setReportConf] = useState(null);
  const [reportEmbed, setReportEmbed] = useState(null);
  const [serieOSensibilidad, setSerieOSensibilidad] = useState(null);

  const [show, setShow] = useState(false)
  
  useEffect(() => {
    if (reportId && !requiereFiltros) {
      const fetchData = async () => {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        const reporteID = await api.getReportePowerBI(
          reportId,
          captchaToken,
          esTemporal
        );
        setReportConf(reporteID);
      };
      fetchData();
    }
  }, [reportId, requiereFiltros, esTemporal]);

  useEffect(() => {
    if (reportId && filtroCargado && requiereFiltros) {
      const fetchData = async () => {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        const reporteID = await api.getReportePowerBI(
          reportId,
          captchaToken,
          esTemporal,
        );
        setReportConf(reporteID);
      };
      fetchData();
    }
  }, [reportId, filtroCargado, requiereFiltros, esTemporal]);

  useEffect(() => {
    if (reportId && filtroCargado && requiereFiltros && calculadora) {
      const fetchData = async () => {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        const reporteID = await api.getReportePowerBI(
          reportId,
          captchaToken,
          calculadora,
          esTemporal,
        );
        setReportConf(reporteID);
      };
      fetchData();
    }
  }, [reportId, filtroCargado, requiereFiltros, calculadora, esTemporal]);

  const { selSerie, selSensibilidad, selCalculos, SelSerieClima, selCalculo, selEstadisticas } = formFiltroReporte || [];

  useEffect(() => {
    if (selCalculo === "calculosAvanzadosSel" && selCalculos === "6" && selSensibilidad !== undefined) {
      setSerieOSensibilidad(selSensibilidad);
    }
    if (selCalculo === "calculosAvanzadosSel" && selCalculos === "5" && SelSerieClima){
      setSerieOSensibilidad(SelSerieClima)
    }
    if (selCalculo === "estadisticaSel" && selEstadisticas === "Min"){
      setSerieOSensibilidad(selSerie);
    }
    if (selCalculo === "estadisticaSel" && selEstadisticas === "Max"){
      setSerieOSensibilidad(selSerie);
    }
    if (selCalculo === "estadisticaSel" && selEstadisticas === "Promedio"){
      setSerieOSensibilidad(selSerie);
    }
    if (selCalculo === "estadisticaSel" && selEstadisticas === "Acumulado"){
      setSerieOSensibilidad(selSerie);
    }
  }, [selSerie, selSensibilidad, selCalculos, SelSerieClima, selCalculo, selEstadisticas]);


  useEffect(() => {
    if (reportEmbed) {
      const fetchData = async () => {
        reportEmbed.on("loaded", async (event) => {
          if (requiereFiltros && filtrosReporte && filtrosReporte.length > 0) {
            //Remueve filtros aplicados a nivel de la página
            let pages = await reportEmbed.getPages();
            // Retrieve the active page.
            let activePage = pages.filter(function (page) {
              return page.isActive;
            })[0];
            //await activePage.updateFilters(models.FiltersOperations.RemoveAll);
            await reportEmbed.updateFilters(models.FiltersOperations.RemoveAll);
            //Remueve filtros aplicados a nivel de la página
            reportEmbed
              .updateFilters(
                models.FiltersOperations.ReplaceAll,
                filtrosReporte
              )
              .catch((errors) => {
                console.log(errors);
              });
          }
        });
      };
      fetchData();
    }
  }, [reportEmbed]);

  useEffect(() => {
    if (reportEmbed) {
      const fetchData = async () => {
        //reportEmbed.on("rendered",  async (event) => 
        /*  */
        await reportEmbed.updateFilters(models.FiltersOperations.RemoveAll);
        /* */

        reportEmbed.updateFilters(
          models.FiltersOperations.ReplaceAll,
          filtrosReporte
        );
        //});
      };
      fetchData();
    }
  }, [filtrosReporte, reportEmbed]);

  useEffect(() => {
    try {
      if (isScreenShoot) {
        reportEmbed.print();
        dispatch(setIsScreenShoot(false));
      }
    } catch (error) {
      console.log(error);
    }
  }, [isScreenShoot]);

  useEffect(() => {
    if (reportEmbed) {
      reportEmbed.off("buttonClicked");

      // report.on will add an event listener.
      reportEmbed.on("buttonClicked", function (event) {
        let data = event.detail;
        reportEmbed.print();
      });
    }
  }, [reportEmbed]);

  const expiracionToken = (data) => {
    if(data){
      const { message } = data
      if(message === "TokenExpired"){
        setShow(true);
      }
    }
  }

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_KEY_CAPTCHA}
      />
      {reportConf ? (
        <>
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: reportConf?.embedReport[0].reportId,
              embedUrl: reportConf?.embedReport[0].embedUrl,
              accessToken: reportConf?.embedToken.token,
              tokenType: models.TokenType.Embed,
              pageName: serieOSensibilidad,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  pageSize: {
                    type: models.PageSizeType.Custom,
                    width: "100%",
                    height: "100%",
                  },
                  displayOption: models.DisplayOption.ActualSize,
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    pingUsuario(dispatch);
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  function (event) {
                    expiracionToken(event.detail);
                    console.log("Error al cargar reporte");
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"report-style-class"}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
              console.log(embeddedReport)
              setReportEmbed(embeddedReport);
            }}
          />
        </>
      ) : (
        <PageLoader />
      )}

      {show && (
         <ModalError 
         mostrarModal={show}
         ocultarModal={() => setShow(false)}
       ></ModalError>
      )}
    </>
  );
};

export default PowerBi;
