import React, { useEffect, useState } from "react";
import {
  addValueCombobox,
  addRomanoCombobox,
} from "../../appStatus/actions/actionPagina";
import options from "../../api/regiones.json";
import { useDispatch, useSelector } from "react-redux";
import { romanize } from "../../helpers/utils/numberToRomano";

const Combobox = () => {
  const dispatch = useDispatch();

  const valueCombobox = useSelector((store) => store.pagina.valueCombobox);
  const region = useSelector((store) => store.pagina.region);

  //const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [regionesLimpias, setRegionesLimpias] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [nombreRegion, setNombreRegion] = useState("");
  const [mensaje, setMensaje] = useState(null);
  const [mensajeValido, setMensajeValido] = useState(false);

  useEffect(() => {
    if (region !== null) {
      let regionSinRegionesNegativas = region.filter(
        (e) => e.IdRegion !== -1 && e.IdRegion !== -2
      );

      setRegionesLimpias(regionSinRegionesNegativas);

      if (valueCombobox !== null && nombreRegion === "") {
        let estacionesSeleccionadas = regionSinRegionesNegativas.filter(
          (e) => e.IdRegion.toString() === valueCombobox
        );
        setNombreRegion(estacionesSeleccionadas[0].NombreRegion);
      }
    }
  }, [valueCombobox, nombreRegion, region]);

  const handleClick = () => {
    if (selectedRegion !== "") {
      setMensaje(null);
      setMensajeValido(false);
      dispatch(addValueCombobox(selectedRegion));
      dispatch(addRomanoCombobox(romanize(selectedRegion)));
    } else {
      setMensajeValido(true);
      setMensaje("Debe seleccionar una región");
    }
  };

  const onChangeRegion = (e) => {
    setSelectedRegion(e.target.value);
  };

  return (
    <div className="col-md-auto">
      <div className="input-group">
        <select
          className="form-control"
          type="text"
          name="region"
          defaultValue={nombreRegion}
          onChange={(e) => onChangeRegion(e)}
        >
          <option hidden >Seleccione una región</option>
          {regionesLimpias?.map((o) => (
            <option key={o.IdRegion} value={o.IdRegion}>
              {o.NombreRegion}
            </option>
          ))}
        </select>
        <div className="input-group-append">
          <button
            className="btn btn-success"
            type="button"
            onClick={handleClick}
          >
            <i className="fa fa-search" />
          </button>
        </div>
      </div>
      {mensajeValido && (
        <span className="valid-message">{mensaje}</span>
      )}
    </div>
  );
};

export default Combobox;
