import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { login } from '../../appStatus/actions/sessionActions';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router-dom"
import { Link } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect } from 'react';
import ModalRecuperacion from './ModalRecuperacion';
import { getProductosUsuario } from '../../appStatus/actions/actionPagina';
import { pingUsuario } from '../../services/privateService';

const LoginPage = (props) => {
    const recaptchaRef = React.useRef();

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const autenticado = useSelector(store => store.session.authenticated)

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            username: '',
            password: '',
            recordarCuenta: false
        }
    });

    const submitForm = (data) => {
        try {
            setLoading(true)
            recaptchaRef.current.executeAsync().then(responseCaptch => {
                recaptchaRef.current.reset();

                dispatch(login(data, responseCaptch)).then(response => {
                    if (response === false) {
                        toast.error('Credenciales inválidas.')
                    } else {
                        dispatch(getProductosUsuario())
                        props.history.push('/home')
                        pingUsuario(dispatch)
                    }
                    setLoading(false)
                })
            })
        }
        catch (exception) {
            toast.error('Credenciales inválidas.')
            setLoading(false)
            console.log(exception)
        }
    }

    return (
        <Grid className="loginWrapper">
            {autenticado ? (
                <Redirect to={{
                    pathname: '/'
                }} />
            ) : null}
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_KEY_CAPTCHA}
            />
            <ModalRecuperacion
                mostrarModal={showModal}
                onCerrarModal={() => setShowModal(false)}
                captcha={recaptchaRef}
            ></ModalRecuperacion>
            <Grid className="loginForm">
                <h2>Iniciar Sesión</h2>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                name="username"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        className="inputOutline"
                                        fullWidth
                                        placeholder="E-mail"
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        name="username"
                                        label="E-mail"
                                        error={!!error}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                rules={{
                                    required: 'Este campo es requerido.',
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                }}
                            />
                            {errors?.username && errors?.username.type === "required" && <span className="invalid">{errors?.username.message}</span>}
                            {errors?.username && errors?.username.type === "pattern" && <span className="invalid">Ingrese un Email válido.</span>}

                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        className="inputOutline"
                                        fullWidth
                                        error={!!error}
                                        placeholder="Password"
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                rules={{ required: 'Este campo es requerido.' }}

                            />
                            {errors?.password && errors?.password.type === "required" && <span className="invalid">{errors?.password.message}</span>}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formAction">
                                <Controller
                                    name="recordarCuenta"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <FormControlLabel
                                            control={<Checkbox checked={value} onChange={onChange} />}
                                            label="Recordar"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid className="formFooter">
                                <Button fullWidth disabled={isLoading} className="cBtnTheme" type="submit">
                                    {isLoading ? <i className="fa fa-spinner fa-spin fa-2x"></i> : null}
                                    Iniciar Sesión
                                </Button>
                            </Grid>
                            <Grid className="formFooter" style={{ justifyContent: 'center' }}>
                                <Link
                                    type='button'
                                    component="button"
                                    variant="body2"
                                    onClick={() => { setShowModal(true) }}
                                >
                                    ¿Olvidaste tu contraseña?
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default withRouter(LoginPage);