import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Card,
  Badge,
  FormCheck,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap-v5";
import Multiselect from "multiselect-react-dropdown";
import api from "../../services/apiMenuService";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { es } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import {
  addFiltroReportes,
  setExportar,
  setEsCalculadora,
  addDatosReporte,
  setIsScreenShoot,
  setIsLoading,
  LimpiaFiltros,
  setEsJsonApi,
  random,
  isRandom,
} from "../../appStatus/actions/actionPagina";
import { format, subDays } from "date-fns";
import ModalDownload from "../Modals/ModalDownload";
import "./fecha.scss";
import "./labMultisitio.scss";
import ModalReportes from "../Modals/ModalReportes";

const SidebarLabMultiSitio = () => {
  // isScreenShoot cambiar a setScreenShoot
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ reValidateMode: "onBlur" });

  const dispatch = useDispatch();
  const multiselectRef = useRef();
  const multiselectRef2 = useRef();
  const multiselectRef3 = useRef();

  const valueCombobox = useSelector((store) => store.pagina.valueCombobox);
  const IdReporteCalculadora = useSelector((store) => store.pagina.random);
  const setIsRandom = useSelector((store) => store.pagina.isRandom);
  const variableEntidades = useSelector((store) => store.pagina.varEntidad);
  const categorias = useSelector((store) => store.pagina.categoria);

  const onSubmit = async (data) => {
    if (data) {
      let [variable] = data.selVariable || [];

      let nombreSerie;
      [nombreSerie] = nombreSerieFinal || [];

      const informacionFiltro = data.selEstacion.map((item) => {
        return item.est_Estacion_Id;
      });
      const nombreEstacion = data.selEstacion.map((item) => {
        return item.est_Estacion_Nombre;
      });
      /* Bien chamullado, arreglar */
      if (data.selCalculos === "5" && nombreSerie === "Dias") {
        Object.assign(data, {
          SelSerieClima: "ReportSection499372c740a9a7073aab",
        });
      } else if (data.selCalculos === "5" && nombreSerie === "Mes") {
        Object.assign(data, {
          SelSerieClima: "ReportSectionce9be802b512e57c3b34",
        });
      } else if (data.selCalculos === "5" && nombreSerie === "Año") {
        Object.assign(data, {
          SelSerieClima: "ReportSectionda5dee4cb290820707e1",
        });
      }

      /* Esto es para hacer la diferencia entre estadisticos Promedio y Acumulado */
      if (variable === 6) {
        Object.assign(data, { SelEstadisticasAcum: "Acumulado" });
      } else if (data.selCalculos === "5" || data.selCalculos === "6") {
        Object.assign(data, { SelEstadisticasAcum: "Promedio" });
      }
      /* Esto es para hacer la diferencia entre estadisticos Promedio y Acumulado */
      /* Bien chamullado, arreglar */
      Object.assign(data, { Estaciones: informacionFiltro });
      Object.assign(data, { NombreEstacion: nombreEstacion });
      if (data.selCalculos === "6") {
        Object.assign(data, { EstadisticaCalculos: tipoEstadisticaAnomalias });
      }
      if (data.selTemporalidad === "mes") {
        let month = format(new Date(), "yyyy-MM-dd");
        let lastMonth = format(subDays(new Date(), 30), "yyyy-MM-dd");
        Object.assign(data, { FechaInicio: lastMonth });
        Object.assign(data, { FechaFin: month });
      } else if (data.selTemporalidad === "anio") {
        let year = format(new Date(), "yyyy-MM-dd");
        let lastYear = format(subDays(new Date(), 365), "yyyy-MM-dd");
        Object.assign(data, { FechaInicio: lastYear });
        Object.assign(data, { FechaFin: year });
      } else if (data.selTemporalidad === "calendario") {
        Object.assign(data, { FechaInicio: data.FechaInicio });
        Object.assign(data, { FechaFin: data.FechaFin });
      }

      dispatch(addFiltroReportes(data));

      if (calculator) {
        setShow(true);
      }
    }
  };

  const [entidad, setEntidad] = useState([]);
  const [estaciones, setEstaciones] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [variable, setVariable] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const [reporte, setReporte] = useState([]);
  const [dominioSeleccionado, setDominioSeleccionado] = useState();
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState();
  const [dominioIdSeleccionado, setDominioIdSeleccionado] = useState();
  const [categoriaIdSeleccionada, setCategoriaIdSeleccionada] = useState();
  const [variableObtenida, setVariableObtenida] = useState();
  const [entidadObtenida, setEntidadObtenida] = useState(null);
  const [entidadPorVariable, setEntidadPorVariable] = useState([]);
  const [estacionesFiltrada, setEstacionesFiltrada] = useState();
  const [estacionesSeleccionadas, setEstacionesSeleccionadas] = useState();
  const [serieObtenida, setSerieObtenida] = useState(null);
  const [nombreSerieFinal, setNombreSerieFinal] = useState(null);
  const [estacionesFormateadas, setEstacionesFormateadas] = useState();
  const [reportesSeleccionado, setReportesSeleccionados] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const isMobile = window.innerWidth >= 590;
  const [estadisticaCheck, setEstadisticaCheck] = useState(true);
  const [calculosAvanzados, setCalculosAvanzados] = useState(false);
  const [serie, setSerie] = useState("ReportSection7");
  const [estadistica, setEstadistica] = useState("Promedio");
  const [calculator, setCalculator] = useState(false);
  const [reporteCalculadora, setReporteCalculadora] = useState([]);
  const [ultimoMes, setUltimoMes] = useState(false);
  const [ultimoAnio, setUltimoAnio] = useState(false);
  const [calendario, setCalendario] = useState(false);
  const [exportarJsonApi, setExportarJsonApi] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [subReporte, setSubReporte] = useState(null);
  const [show, setShow] = useState(false);
  const [showModalReportes, setShowModalReportes] = useState(false);
  const [isReporteVariable, setIsReporteVariable] = useState(false);
  const [huboErrorEnCalculadora, setHuboErrorEnCalculadora] = useState(false);
  const [mensajeCalculadora, setMensajeCalculadora] = useState(null);
  const [calculoAvanzadoSeleccionado, setCalculoAvanzadoSeleccionado] =
    useState(null);
  const [nombreBoton, setNombreBoton] = useState(false);
  const [isEstacionesValidas, setIsEstacionesValidas] = useState(true);
  const [estacionesSeleccionada, setEstacionesSeleccionada] = useState(null);
  const [esCantidadEstaciones, setEsCantidadEstaciones] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [esNombreSerieValido, setEsNombreSerieValido] = useState(false);
  const [reportSectionSensibilidad, setReportSectionSensibilidad] =
    useState(null);

  const [reportSeccionClima, setReportSeccionClima] = useState(null);
  const [reporteSensibilidad, setReporteSensibilidad] = useState(null);
  const [reporteClima, setReporteClima] = useState(null);
  const [tokenSensibilidad, setTokenSensibilidad] = useState(null);
  const [tokenSensibilidadFinal, setTokenSensibilidadFinal] = useState(null);
  const [serieValidoReporte, setSerieValidoReporte] = useState(null);
  const [variableEntidad, setVariableEntidad] = useState(null);
  const [variableSeleccionada, setVariableSeleccionada] = useState(null);
  const [entidadesObtenidas, setEntidadesObtenidas] = useState(null);
  const [entidadEstaciones, setEntidadEstaciones] = useState(null);
  const [estacionesObtenidas, setEstacionesObtenidas] = useState(null);
  const [dominioCategoria, setDominioCategoria] = useState(null);
  const [valorVariableSeleccionada, setValorVariableSeleccionada] =
    useState(null);
  const [calculoAvanzadoSelected, setCalculoAvanzadoSelected] = useState(null);

  /* Validar Filtros segun serie temporal */
  const [serieParaCalculosAvanzados, setSerieParaCalculosAvanzados] =
    useState(false);
  const [serieParaEstadisticas, setSerieParaEstadisticas] = useState(false);
  /* Validar Filtros segun serie temporal */
  /* Tipo de estadistica para anomalias */
  const [tipoEstadisticaAnomalias, setTipoEstadisticaAnomalias] =
    useState(null);
  /* Tipo de estadistica para anomalias */

  /* Reportsection Clima */
  const [reportSectionClima, setReportSectionClima] = useState(null);
  /* Reportsection Clima */

  const [seccionReporte, setSeccionReporte] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const entidades = await api.getAllEntidades();
      setEntidad(entidades);
      const estacion = await api.getAllEstaciones();
      setEstaciones(estacion);
      const vari = await api.getAllVariables();
      setVariable(vari);
      const cat = await api.getAllCategorias();
      setCategoria(cat);
      const sec = await api.getSeccionReportesById(3); //3
      setSeccion(sec);
      const repo = await api.getAllReportes();
      setReporte(repo);
      const seccionReporteSensibilidad = await api.getSeccionReportesById(6);
      setReportSectionSensibilidad(seccionReporteSensibilidad);

      const seccionReporteClima = await api.getSeccionReportesById(5);
      setReportSeccionClima(seccionReporteClima);
    };
    fetchData();
  }, []);

  /* Parte nueva de variable entidad y estacion */
  useEffect(() => {
    if (variableSeleccionada) {
      if (variableSeleccionada === valorVariableSeleccionada) {
        const fetchData = async () => {
          const obtenerEntidad = await api.getObtenerEntidadVariable({
            Variables:
              variableSeleccionada instanceof Array
                ? variableSeleccionada
                : [variableSeleccionada],
          });
          setVariableEntidad(obtenerEntidad);
        };
        fetchData();
      } else {
        reset();
      }
    }
  }, [variableSeleccionada, valorVariableSeleccionada, reset]);

  useEffect(() => {
    if (categorias && variableSeleccionada) {
      let varSelected = categorias.filter(
        (v) => v.var_Variable_Id === Number(variableSeleccionada)
      );
      setDominioCategoria(varSelected);
    }
  }, [categorias, variableSeleccionada]);

  useEffect(() => {
    if (
      entidadEstaciones?.length > 0 &&
      valueCombobox &&
      variableSeleccionada
    ) {
      const fetchData = async () => {
        const obtenerEstaciones = await api.getObtenerEntidadEstaciones({
          Entidades:
            entidadEstaciones instanceof Array
              ? entidadEstaciones
              : [entidadEstaciones],
          IdRegion: valueCombobox,
          IdVariable: variableSeleccionada,
        });
        setEstacionesObtenidas(obtenerEstaciones);
      };
      fetchData();
    }
  }, [entidadEstaciones, valueCombobox, variableSeleccionada]);

  useEffect(() => {
    if (dominioCategoria?.length > 0) {
      setDominioSeleccionado(dominioCategoria[0].dom_Dominio_Variable_Codigo);
      setCategoriaSeleccionada(
        dominioCategoria[0].cat_Categoria_Variable_Nombre
      );
      setCategoriaIdSeleccionada(dominioCategoria[0].cat_Categoria_Variable_Id);
      setDominioIdSeleccionado(dominioCategoria[0].dom_Dominio_Variable_Id);
    } else {
      setDominioSeleccionado(null);
      setCategoriaSeleccionada(null);
      setCategoriaIdSeleccionada(null);
      setDominioIdSeleccionado(null);
    }
  }, [dominioCategoria]);

  useEffect(() => {
    if (variableObtenida !== undefined && reporte !== undefined) {
      let reportSelected = reporte.filter((report) => {
        return report.IdVariable === parseInt(variableObtenida);
      });

      if (reportSelected.length > 0) {
        setSubReporte(reportSelected);
        setIsReporteVariable(true);
      } else {
        setIsReporteVariable(false);
      }
    }
  }, [variableObtenida, reporte]);

  useEffect(() => {
    if (reportSectionSensibilidad) {
      let sensibilidad = reportSectionSensibilidad.filter(
        (v) => v.IdReporte === 6
      );

      setReporteSensibilidad(sensibilidad);
    }
  }, [reportSectionSensibilidad]);

  //Obtener ReportSection de Clima
  useEffect(() => {
    if (reportSeccionClima) {
      let clima = reportSeccionClima.filter((v) => v.IdReporte === 5);
      setReporteClima(clima);
    }
  }, [reportSeccionClima]);

  useEffect(() => {}, [calculoAvanzadoSeleccionado]);

  const onSelectEstaciones = (selectedList) => {
    let ObtenerEstacionesSeleccionadas = selectedList.map(
      (item) => item.est_Estacion_Id
    );
    setValue("selEstacion", selectedList);
    setEstacionesSeleccionada(ObtenerEstacionesSeleccionadas);
  };

  const onRemoveEstaciones = (selectedList) => {
    let ObtenerEstacionesSeleccionadas = selectedList.map(
      (item) => item.est_Estacion_Id
    );
    setValue("selEstacion", selectedList);
    setEstacionesSeleccionada(ObtenerEstacionesSeleccionadas);
  };

  const onSelectEntidad = (selectedList) => {
    //field.onChange(value);
    let ObtenerEntidadesSeleccionadas = selectedList.map(
      (item) => item.IdEntidad
    );
    setEntidadEstaciones(ObtenerEntidadesSeleccionadas);
    //setEntidadObtenida(value);
    setValue("selEntidad", ObtenerEntidadesSeleccionadas);
  };
  const onRemoveEntidad = (selectedList) => {
    let ObtenerEntidadesSeleccionadas = selectedList.map(
      (item) => item.IdEntidad
    );
    setEntidadEstaciones(ObtenerEntidadesSeleccionadas);
    setValue("selEntidad", ObtenerEntidadesSeleccionadas);
    multiselectRef3.current.resetSelectedValues();
  };

  useEffect(() => {
    if (estacionesSeleccionada) {
      const estaciones = estacionesSeleccionada.length;

      if (estaciones === 3 || estaciones === 2) {
        setEsCantidadEstaciones(true);
      } else if (estaciones === 1) {
        setEsCantidadEstaciones(false);
      }
    }
  }, [estacionesSeleccionada]);

  useEffect(() => {
    if (isMobile) {
      setIsFilterOpen(true);
    } else {
      setIsFilterOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (reporte) {
      const reportesSeleccionados = reporte.filter(
        (e) => e.FlagReporte === false
      );
      setReportesSeleccionados(reportesSeleccionados);
    }
  }, [reporte]);

  useEffect(() => {
    if (serieObtenida) {
      let serieExtraida = serieObtenida.substring(13);

      if (serieExtraida !== 6 || serieExtraida !== 7) {
        let obtenernombreSerie = seccion.filter((s) => {
          return s.TokenSeccion === serieExtraida.toString();
        });
        const nombreSerieFinal = obtenernombreSerie.map(
          (item) => item.NombreSeccion
        );
        setNombreSerieFinal(nombreSerieFinal);
      }
    }
  }, [serieObtenida, seccion]);

  useEffect(() => {
    if (tokenSensibilidad) {
      let serieExtraida = tokenSensibilidad.substring(13);
      let obtenerTokenSensibilidad = reportSectionSensibilidad.filter((s) => {
        return s.TokenSeccion === serieExtraida.toString();
      });
      setTokenSensibilidadFinal(obtenerTokenSensibilidad);
    }
  }, [tokenSensibilidad, reportSectionSensibilidad]);

  useEffect(() => {
    let nombreSerie;
    [nombreSerie] = nombreSerieFinal || [];
    if (
      (estadisticaCheck && nombreSerie === undefined) ||
      (estadisticaCheck && nombreSerie === "Original") ||
      (estadisticaCheck && nombreSerie === "Hora")
    ) {
      setSerieParaEstadisticas(false);
    } else {
      setSerieParaEstadisticas(true);
    }
    if (
      (calculosAvanzados && nombreSerie === undefined) ||
      (calculosAvanzados && nombreSerie === "Original") ||
      (calculosAvanzados && nombreSerie === "Hora")
    ) {
      setSerieParaCalculosAvanzados(false);
    } else {
      setSerieParaCalculosAvanzados(true);
    }
  }, [nombreSerieFinal, calculosAvanzados, estadisticaCheck]);

  const changeSerie = (field, value) => {
    field.onChange(value);
    setSerieObtenida(value);

    setValue("nombreSerieTemporal", {
      nombreSerieFinal,
    });
    setValue("reportSectionDinamico", {
      value,
    });
  };

  const onSelectVariable = (selectedList) => {
    let ObtenerVariablesSeleccionadas = selectedList.map(
      (item) => item.IdVariable
    );

    let ObtenerEstadisticaVariable = selectedList.map(
      (item) => item.NombreEstadistica
    );

    setVariableSeleccionada(ObtenerVariablesSeleccionadas);
    setValorVariableSeleccionada(ObtenerVariablesSeleccionadas);
    setTipoEstadisticaAnomalias(ObtenerEstadisticaVariable);

    setValue("selVariable", ObtenerVariablesSeleccionadas);
  };

  const onRemoveVariable = (selectedList) => {
    let ObtenerVariablesSeleccionadas = selectedList.map(
      (item) => item.IdVariable
    );

    setVariableSeleccionada(ObtenerVariablesSeleccionadas);
    setValorVariableSeleccionada(ObtenerVariablesSeleccionadas);

    setValue("selVariable", ObtenerVariablesSeleccionadas);

    multiselectRef2.current.resetSelectedValues();
    multiselectRef3.current.resetSelectedValues();
  };

  const changeEstadisticas = (field, value) => {
    field.onChange(value);
  };
  const changeCalculos = (field, value) => {
    field.onChange(value);
    /* Obtener reportes de calculos avanzados */
    /*Obtener clima o anomalias*/
    setCalculoAvanzadoSeleccionado(value);

    if (value === "0") {
      dispatch(LimpiaFiltros());
    }
  };
  const onChangeSensibilidad = (field, value) => {
    field.onChange(value);
    setTokenSensibilidad(value);

    setValue("reportSensibilidad", {
      tokenSensibilidadFinal,
    });
  };
  const onChangeFechaInicio = (value) => {
    setStartDate(value);
    setValue("FechaInicio", startDate ? format(startDate, "yyyy-MM-dd") : null);
  };
  const onChangeFechaFin = (value) => {
    setEndDate(value);
    setValue("FechaFin", endDate ? format(endDate, "yyyy-MM-dd") : null);
    setValue("selFecha", true);
  };

  //Verificar csv
  const ValidarFormulario = (e) => {
    e.preventDefault();
    //clearErrors();
    if (e.target.name === "grafico") {
      setNombreBoton(true);
      handleSubmit(onSubmit)();
      setExportarJsonApi(false);
      dispatch(setEsJsonApi(false));
      dispatch(setExportar(false));
    } else if (e.target.name === "api") {
      handleSubmit(onSubmit)();
      setNombreBoton(true);
      dispatch(setExportar(true));
      setExportarJsonApi(true);
      dispatch(setEsJsonApi(true));
    }
  };

  useEffect(() => {
    //cambiar la forma de validar para el random, ya que ingresa en muchas ocasiones
    if (setIsRandom && IdReporteCalculadora) {
      Calculadora();
    }
  }, [setIsRandom, IdReporteCalculadora]);

  let intervalSec = 0;
  let intervalID = null;
  const Calculadora = async () => {
    intervalID = setInterval(async () => {
      intervalSec++;
      const sec = await api.getReporteCalculadora(IdReporteCalculadora);
      setMensajeCalculadora(sec[0].Query_Estado);

      if (sec[0].Query_Estado === "Completado" && sec.length > 0) {
        setReporteCalculadora(sec);
        clearInterval(intervalID);
        setShow(false);
        dispatch(addDatosReporte(sec));
        setIsLoading(false);
        dispatch(setIsLoading(false));
        setIsLoadingScreen(true);
        dispatch(random(null));
        dispatch(isRandom(false));
      }
      //LIMPIAR ERROR Y ENVIAR UN CERRAR DENTRO DEL MODAL DOWNLOAD
      if (intervalSec === 240 || sec[0].Query_Estado === "Error") {
        clearInterval(intervalID);
        setHuboErrorEnCalculadora(true);
        setIsLoading(false); //REVISAR
        dispatch(setIsLoading(false));
        dispatch(random(null));
        dispatch(isRandom(false));
      }
    }, 1000);
  };

  const handleOnChangeDatos = (value) => {
    if (value === "estadisticaSel") {
      setEstadisticaCheck(true);
      setCalculosAvanzados(false);
      setCalculator(false);
      dispatch(setEsCalculadora(false));
      dispatch(addDatosReporte(null));
      dispatch(LimpiaFiltros());
    } else if (value === "calculadoraSel") {
      setEstadisticaCheck(false);
      setCalculosAvanzados(false);
      setCalculator(true);
      dispatch(setEsCalculadora(true));
      dispatch(LimpiaFiltros());
    } else if (value === "calculosAvanzadosSel") {
      setEstadisticaCheck(false);
      setCalculosAvanzados(true);
      setCalculator(false);
      dispatch(setEsCalculadora(false));
      dispatch(addDatosReporte(null));
      dispatch(LimpiaFiltros());
    }
  };

  const handleOnChangeTemporalidad = (value) => {
    if (value === "mes") {
      setUltimoMes(true);
      setUltimoAnio(false);
      setCalendario(false);
    } else if (value === "anio") {
      setUltimoAnio(true);
      setUltimoMes(false);
      setCalendario(false);
    } else if (value === "calendario") {
      setCalendario(true);
      setUltimoMes(false);
      setUltimoAnio(false);
    }
  };

  const onCerrarModalCalculadora = () => {
    setShow(false);
    dispatch(LimpiaFiltros());
    dispatch(setIsLoading(false));
    setHuboErrorEnCalculadora(false);
    clearInterval(intervalID);
  };

  const onLimpiarErrorCalculadora = () => {
    setHuboErrorEnCalculadora(false);
  };

  return (
    <nav className="sidebar-root">
      <header className="logo">
        <span className="title">Filtros</span>
      </header>
      <Accordion>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            {errors.selVariable && errors.selVariable.type === "required" ? (
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="valid-message"
              >
                Filtro Variable es Obligatorio
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle as={Card.Header} eventKey="3">
                Filtro Variable
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div className="mb-3">
                  {setValue("dominio", {
                    dominioIdSeleccionado,
                    dominioSeleccionado,
                  })}
                  <Badge pill bg="primary">
                    {dominioSeleccionado}
                    {/*dominioNuevo*/}
                  </Badge>{" "}
                  {setValue("categoria", {
                    categoriaIdSeleccionada,
                    categoriaSeleccionada,
                  })}
                  <Badge pill bg="success">
                    {categoriaSeleccionada}
                    {/*categoriaNuevo*/}
                  </Badge>{" "}
                </div>
                <div className="mb-3">
                  <label className="form-label">Seleccione Variable</label>
                  <Controller
                    control={control}
                    name="selVariable"
                    render={({ field }) => {
                      return (
                        <Multiselect
                          displayValue="NombreVariable"
                          hidePlaceholder={true}
                          selectionLimit="1"
                          onSelect={onSelectVariable}
                          onRemove={onRemoveVariable}
                          loading={variableEntidades ? false : true}
                          loadingMessage={"Cargando..."}
                          placeholder="Seleccione una opción"
                          options={variableEntidades ?? []}
                          emptyRecordMsg={"No se encontraron opciones."}
                          ref={multiselectRef}
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                  {errors.selVariable &&
                    errors.selVariable.type === "required" && (
                      <span className="valid-message">
                        Campo Variable es obligatorio
                      </span>
                    )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            {(errors.selEntidad && errors.selEntidad.type === "required") ||
            (errors.selEstacion && errors.selEstacion.type === "required") ? (
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="valid-message"
              >
                Filtro Estación es Obligatorio
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle as={Card.Header} eventKey="0">
                Filtro Estación
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="mb-3">
                  <label className="form-label">Seleccione Entidad</label>
                  <Controller
                    control={control}
                    name="selEntidad"
                    render={({ field }) => {
                      return (
                        <Multiselect
                          displayValue="NombreEntidad"
                          hidePlaceholder={true}
                          selectionLimit="2"
                          onSelect={onSelectEntidad}
                          onRemove={onRemoveEntidad}
                          loading={variableEntidad ? false : true}
                          loadingMessage={"Cargando..."}
                          placeholder="Seleccione una opción"
                          options={variableEntidad ?? []}
                          emptyRecordMsg={"No se encontraron opciones."}
                          ref={multiselectRef2}
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                  {errors.selEntidad &&
                    errors.selEntidad.type === "required" && (
                      <span className="valid-message">
                        Campo entidad es obligatorio
                      </span>
                    )}
                </div>

                <div className="mb-3">
                  <label className="form-label">Seleccione Estación</label>
                  <Controller
                    control={control}
                    name="selEstacion"
                    render={({ field }) => {
                      return (
                        <Multiselect
                          displayValue="est_Estacion_Nombre"
                          hidePlaceholder
                          groupBy="e_Entidad_Nombre"
                          selectionLimit="3"
                          onSelect={onSelectEstaciones}
                          onRemove={onRemoveEstaciones}
                          loading={estacionesObtenidas ? false : true}
                          loadingMessage={"Cargando..."}
                          placeholder="Seleccione una opción"
                          options={estacionesObtenidas ?? []}
                          ref={multiselectRef3}
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                  {errors.selEstacion &&
                    errors.selEstacion.type === "required" && (
                      <span className="valid-message">
                        Campo estación es obligatorio
                      </span>
                    )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            {(errors.selFecha && errors.selFecha.type === "required") ||
            (errors.selTemporalidad &&
              errors.selTemporalidad.type === "required") ? (
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="valid-message"
              >
                Filtro Temporalidad es Obligatorio
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle as={Card.Header} eventKey="1">
                Filtro Temporalidad
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="mb-3">
                  <label className="form-label">Seleccione una opción</label>
                  <FormCheck
                    {...register("selTemporalidad", { required: true })}
                    type="radio"
                    label="Último Mes"
                    value="mes"
                    name="selTemporalidad"
                    onChange={(e) => handleOnChangeTemporalidad(e.target.value)}
                  />
                  <FormCheck
                    {...register("selTemporalidad", { required: true })}
                    type="radio"
                    label="Último Año"
                    value="anio"
                    name="selTemporalidad"
                    onChange={(e) => handleOnChangeTemporalidad(e.target.value)}
                  />
                  <FormCheck
                    {...register("selTemporalidad", { required: true })}
                    type="radio"
                    label="Calendario"
                    value="calendario"
                    name="selTemporalidad"
                    onChange={(e) => handleOnChangeTemporalidad(e.target.value)}
                  />
                </div>
                {errors.selTemporalidad &&
                  errors.selTemporalidad.type === "required" && (
                    <span className="valid-message">
                      Campo selección es obligatorio
                    </span>
                  )}
                <div className="mb-3">
                  {calendario && (
                    <>
                      {setValue(
                        "FechaInicio",
                        startDate ? format(startDate, "yyyy-MM-dd") : null
                      )}
                      {setValue(
                        "FechaFin",
                        endDate ? format(endDate, "yyyy-MM-dd") : null
                      )}
                      <label className="form-label">Seleccione Fecha</label>
                      <Controller
                        control={control}
                        name="selFecha"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onStartDateChange={(value) =>
                              onChangeFechaInicio(value)
                            }
                            onEndDateChange={(value) => onChangeFechaFin(value)}
                            format="dd/MM/yyyy"
                            locale={es}
                          >
                            {({
                              startDateInputProps,
                              endDateInputProps,
                              focus,
                            }) => (
                              <div className="date-range">
                                <input
                                  id="StartDate"
                                  autoComplete="off"
                                  required
                                  className={
                                    "inputs" +
                                    (focus === START_DATE ? " -focused" : "")
                                  }
                                  {...startDateInputProps}
                                  placeholder="Inicio"
                                />
                                <span className="date-range_arrow" />
                                <input
                                  id="EndDate"
                                  autoComplete="off"
                                  required
                                  className={
                                    "inputs" +
                                    (focus === END_DATE ? " -focused" : "")
                                  }
                                  {...endDateInputProps}
                                  placeholder="Fin"
                                />
                              </div>
                            )}
                          </DateRangePicker>
                        )}
                      />
                    </>
                  )}
                  {errors.selFecha && errors.selFecha.type === "required" && (
                    <span className="valid-message">
                      Campo fecha inicio y fecha fin es obligatorio
                    </span>
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Filtro Serie Temporal
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className="mb-3">
                  <label className="form-label">
                    Seleccione Serie Temporal
                  </label>
                  <Controller
                    control={control}
                    defaultValue={serie}
                    name="selSerie"
                    render={({ field }) => (
                      <select
                        className="form-control"
                        onChange={(e) => changeSerie(field, e.target.value)}
                        onBlur={field.onBlur}
                      >
                        {seccion.map((item) => {
                          return (
                            <>
                              <option
                                value={"ReportSection" + item.TokenSeccion}
                              >
                                {item.NombreSeccion}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    )}
                  />
                  {setValue("nombreSerieTemporal", {
                    nombreSerieFinal,
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              Filtro Tratamiento de Datos
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="4">
              <Card.Body>
                {estacionesSeleccionada && estacionesSeleccionada.length < 2 ? (
                  <div className="mb-3">
                    <FormCheck
                      {...register("selCalculo", { required: true })}
                      type="radio"
                      label="Estadísticas"
                      defaultChecked
                      value="estadisticaSel"
                      name="selCalculo"
                      onChange={(e) => handleOnChangeDatos(e.target.value)}
                    />
                    <div className="row g-1">
                      <div className="col-6">
                        <FormCheck
                          {...register("selCalculo", { required: true })}
                          type="radio"
                          label="Calculadora"
                          value="calculadoraSel"
                          name="selCalculo"
                          onChange={(e) => handleOnChangeDatos(e.target.value)}
                        />
                      </div>
                      <div className="col-6">
                        <Badge pill bg="warning">
                          Sólo para Reportes
                        </Badge>
                      </div>
                    </div>
                    <FormCheck
                      {...register("selCalculo", { required: true })}
                      type="radio"
                      label="Cálculos Avanzados"
                      value="calculosAvanzadosSel"
                      name="selCalculo"
                      onChange={(e) => handleOnChangeDatos(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <FormCheck
                      {...register("selCalculo", { required: true })}
                      type="radio"
                      label="Estadísticas"
                      defaultChecked
                      value="estadisticaSel"
                      name="selCalculo"
                      onChange={(e) => handleOnChangeDatos(e.target.value)}
                    />
                    <div className="row g-1">
                      <div className="col-6">
                        <FormCheck
                          {...register("selCalculo", { required: true })}
                          type="radio"
                          label="Calculadora"
                          value="calculadoraSel"
                          name="selCalculo"
                          onChange={(e) => handleOnChangeDatos(e.target.value)}
                        />
                      </div>
                      <div className="col-6">
                        <Badge pill bg="warning">
                          Sólo para Reportes
                        </Badge>
                      </div>
                    </div>
                    <div className="row g-1">
                      <div className="col-md-8">
                        <FormCheck
                          {...register("selCalculo", { required: true })}
                          type="radio"
                          label="Cálculos Avanzados"
                          value="calculosAvanzadosSel"
                          name="selCalculo"
                          disabled
                          onChange={(e) => handleOnChangeDatos(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled"> Recuerda que para visualizar los reportes de{" "}
                            <strong>Cálculos Avanzados</strong>, es necesario solo una{" "}
                            <strong>Estación</strong> o serie temporal distinta a{" "}
                            <strong>Original</strong> y <strong>Hora</strong>{" "}</Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <i
                              class="fa fa-question-circle fa-2x"
                              style={{ pointerEvents: "none", color: "red" }}
                            >
                            </i>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                )}
                {estadisticaCheck ? (
                  <Alert variant="warning">
                    Recuerda que para visualizar los reportes de{" "}
                    <strong>Estadísticas</strong>, es necesario un serie{" "}
                    temporalidad distinta a <strong>Original</strong> y{" "}
                    <strong>Hora</strong>
                  </Alert>
                ) : (
                  <Alert variant="warning">
                    Recuerda que para visualizar los reportes de{" "}
                    <strong>Calculos Avanzados</strong>, es necesario solo una{" "}
                    <strong>Estación</strong> o serie temporalidad distinta a{" "}
                    <strong>Original</strong> y <strong>Hora</strong>,{" "}
                  </Alert>
                )}
                {estadisticaCheck && (
                  <div className="mb-3">
                    <label className="form-label">
                      Seleccione Estadísticas
                    </label>
                    <Controller
                      control={control}
                      defaultValue={estadistica}
                      name="selEstadisticas"
                      render={({ field }) => (
                        <select
                          className="form-control"
                          onChange={(e) =>
                            changeEstadisticas(field, e.target.value)
                          }
                        >
                          <option value="Promedio">Promedio</option>
                          <option value="Max">Max</option>
                          <option value="Min">Min</option>
                          <option value="Acumulado">Acumulado</option>
                        </select>
                      )}
                    />
                  </div>
                )}
                {calculosAvanzados && estacionesSeleccionada.length < 2 && (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Cálculos Avanzados</label>
                      <Controller
                        control={control}
                        name="selCalculos"
                        render={({ field }) => (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              changeCalculos(field, e.target.value)
                            }
                          >
                            <option value="">Seleccione una opción</option>
                            {reportesSeleccionado.map((item, key) => {
                              return (
                                <option key={key} value={item.IdReporte}>
                                  {item.NombreReporte}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        rules={{ required: true }}
                      />
                      {errors.selCalculos &&
                        errors.selCalculos.type === "required" && (
                          <span className="valid-message">
                            Campo cálculos avanzados es obligatorio
                          </span>
                        )}
                    </div>
                  </>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {calculosAvanzados &&
            estacionesSeleccionada.length < 2 &&
            !esCantidadEstaciones &&
            !esNombreSerieValido &&
            calculoAvanzadoSeleccionado === "6" && (
              <Card>
                {errors?.selSensibilidad ? (
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="5"
                    className="valid-message"
                  >
                    Filtro Sensibilidad Anomalías es Obligatorio
                    {errors.selSensibilidad.message}
                  </Accordion.Toggle>
                ) : (
                  <Accordion.Toggle as={Card.Header} eventKey="5">
                    Filtro Sensibilidad Anomalías
                  </Accordion.Toggle>
                )}
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className="mb-3">
                      <label className="form-label">
                        Seleccione Sensibilidad
                      </label>
                      <Controller
                        control={control}
                        name="selSensibilidad"
                        render={({ field }) => (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              onChangeSensibilidad(field, e.target.value)
                            }
                          >
                            <option value="">Seleccione una opción</option>
                            {reporteSensibilidad.map((item, key) => {
                              return (
                                <>
                                  <option
                                    value={"ReportSection" + item.TokenSeccion}
                                  >
                                    {item.NombreSeccion}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        )}
                        rules={{ required: true }}
                      />
                      {errors?.selSensibilidad &&
                        errors.selSensibilidad.type === "required" && (
                          <span className="valid-message">
                            Filtro Sensibilidad Anomalías es Obligatorio
                          </span>
                        )}
                      {setValue("reportSensibilidad", {
                        tokenSensibilidadFinal,
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          <Card>
            <div className="btn-group" role="group">
              {!serieParaEstadisticas || !serieParaCalculosAvanzados ? (
                <button
                  disabled
                  name="grafico"
                  onClick={(e) => ValidarFormulario(e)}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  Reportes
                </button>
              ) : (
                <button
                  name="grafico"
                  onClick={(e) => ValidarFormulario(e)}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  Reportes
                </button>
              )}

              {!calculator ? (
                <>
                  <button
                    name="api"
                    onClick={(e) => ValidarFormulario(e)}
                    type="button"
                    className="btn btn-outline-primary"
                  >
                    Exportar
                  </button>
                </>
              ) : isLoadingScreen ? (
                <button
                  name="print"
                  onClick={() => dispatch(setIsScreenShoot(true))}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-camera"></i>
                </button>
              ) : (
                <button
                  name="print"
                  disabled
                  onClick={() => dispatch(setIsScreenShoot(true))}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  <i className="fa fa-camera"></i>
                </button>
              )}
            </div>
          </Card>
        </form>
      </Accordion>
      <div>
        {calculator && (
          <ModalDownload
            mostrarModal={show}
            huboErrorEnCalculadora={huboErrorEnCalculadora}
            limpiarErrorEnCalculadora={() => onLimpiarErrorCalculadora()}
            /* con esto envio informacion del hijo al padre, en este caso del modal download a sidebarmultiditio */
            cerrarModalCalculadora={() => onCerrarModalCalculadora()}
            mensajeCalculadora={mensajeCalculadora}
            ocultarModal={() => setShow(false)}
          />
        )}
      </div>
      <div>
        {isReporteVariable && (
          <ModalReportes
            mostrarModal={showModalReportes}
            reportesModal={subReporte}
            ocultarModal={() => setShowModalReportes(false)}
          />
        )}
      </div>
    </nav>
  );
};

export default SidebarLabMultiSitio;
