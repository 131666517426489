import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap-v5";
import { updateCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator'
import { TipoProductoEnum } from '../../helpers/Enums'
import { useDispatch } from "react-redux";
import { setIsLoading } from '../../appStatus/actions/actionPagina';

const TabProductosCliente = ({ clienteEditar, productos }) => {

    const [selectedRows, setSelectedRows] = useState([])
    const [nonSelectablesRows, setNonSelectablesRows] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (clienteEditar) {

            setSelectedRows([])
            setNonSelectablesRows([])
            const noSeleccionableAux = productos
                .filter(p =>
                    p.EsSoloAdmin //no asignables los productos solo para administradores
                    || p.TipoProductoId === TipoProductoEnum.Publico.value //no asignables productos publicos, ya que por su caracteristicas ya debiesen ser visibles
                    || p.InstitucionId === clienteEditar.InstitucionId)//no asignables productos propios de la institucion, ya que posee acceso por defecto
                .map(p => p.ProductoId);
            setNonSelectablesRows(noSeleccionableAux);

            if (clienteEditar.Productos && clienteEditar.Productos.length > 0) {
                const productosAsignados = clienteEditar.Productos.map(p => p.ProductoId);
                setSelectedRows(productosAsignados);
            }

        }
    }, [clienteEditar])




    const onSubmitProductos = async (data) => {
        //console.log(selectedRows)
        let formData = {
            "ProductosId": selectedRows,
            "ClienteId": clienteEditar.ClienteId,
        }

        dispatch(setIsLoading(true))
        try {
            const res = await updateCliente(formData);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };


    const columns = [{
        dataField: 'Descripcion',
        text: 'Producto',
        sort: true,
    },
    {
        dataField: 'Institucion.Descripcion',
        text: 'Institución',
        sort: true,
    },
    {
        dataField: 'EsSoloAdmin',
        text: 'Solo Administrador',
        formatter: (cell, row) => { return row.EsSoloAdmin ? "Si" : "No" },
        sort: true,
    },
    {
        dataField: 'TipoProducto.Descripcion',
        text: 'Tipo Producto',
        sort: true
    }
    ];

    const onSelectRow = {
        selected: selectedRows,
        nonSelectable: nonSelectablesRows,
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                const ids = selectedRows
                ids.push(row.ProductoId)
                setSelectedRows(ids)
            }
            else {
                setSelectedRows(selectedRows.filter(item => item !== row.ProductoId))
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setSelectedRows(productos.filter(p => nonSelectablesRows.indexOf(p.ProductoId) < 0).map(p => p.ProductoId))
            }
            else {
                setSelectedRows([])
            }
        }
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {from} a {to} de {size} resultados
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }]
    };

    return (

        <Card>
            <Card.Body>
                <form>
                    <Row className="my-3">
                        <Col xs={12}><h5>Productos</h5></Col>
                        <Col xs={12}>
                            {clienteEditar && productos && (
                                <BootstrapTable
                                    bootstrap4
                                    keyField='ProductoId'
                                    data={productos}
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                    selectRow={onSelectRow}
                                // cellEdit={cellEditFactory({
                                //     mode: 'click',
                                //     blurToSave: true
                                // })}
                                />
                            )}

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => onSubmitProductos()}> Confirmar</Button>
                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>

    );
};

export default TabProductosCliente;