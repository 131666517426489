import React, { useEffect, useState } from "react";
import {
  ModalFooter,
  Modal,
  ModalBody,
  Button,
  Alert,
} from "react-bootstrap-v5";
import { ModalHeader, Label } from "reactstrap";
import api from "../../../services/apiMenuService";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setResultado,
  addRomanoCombobox,
  addValueCombobox,
} from "../../../appStatus/actions/actionPagina";
import { romanize } from "../../../helpers/utils/numberToRomano";

const ModalMessage = ({ mostrarModal, onCerrarModal }) => {
  const dispatch = useDispatch();

  const regiones = useSelector((store) => store.pagina.region);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: "onBlur" });

  const [regionesLimpias, setRegionesLimpias] = useState([]);
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const detalleIP = await api.getIPCity();
      setDetails(detalleIP);
    };
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    let aux = {
      formulario: data,
    };

    const { region } = data;
    romanize(region);

    dispatch(addValueCombobox(region));
    dispatch(addRomanoCombobox(romanize(region)));

    try {
      const ingreso = await api.Registro_UserModal(aux);

      const { fueCorrecto, mensaje } = ingreso;

      if (fueCorrecto) {
        onCerrarModal();
        setIsLoading(false);
      }

      dispatch(
        setResultado({
          titulo: "Solicitud Ingreso user modal",
          fueCorrecto,
          mensaje: mensaje,
        })
      );
    } catch (error) {
      dispatch(
        setResultado({
          titulo: "Solicitud Ingreso user modal",
          fueCorrecto: false,
          mensaje:
            "Ha ocurrido un error al registrar solicitud de registro usuario modal",
        })
      );
      setIsLoading(false);
    }
  };

  /*useEffect(() => {
    const fetchData = async () => {
      const reg = await api.getAllRegiones();
      setRegion(reg);
    };
    fetchData();
  }, []);*/

  useEffect(() => {
    if (regiones !== null) {
      let regionSinRegionesNegativas = regiones.filter(
        (e) => e.IdRegion !== -1 && e.IdRegion !== -2
      );

      setRegionesLimpias(regionSinRegionesNegativas);
    }
  }, [regiones]);

  const changeRegion = (field, value) => {
    field.onChange(value);
  };

  const changeUser = (field, value) => {
    field.onChange(value);
  };

  const ValidarFormulario = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <div>
      <Modal show={mostrarModal} centered>
        <ModalHeader className="warning">
          <Alert key="warning" variant="warning">
           Este sitio web está optimizado para navegadores Chrome y  Firefox. 
          </Alert>
        </ModalHeader>
        <ModalHeader>Selección tipo de usuario</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3"></div>
            <div className="mb-3">
              <Label> Seleccione Tipo de usuario: </Label>
              <Controller
                control={control}
                name="user"
                render={({ field }) => (
                  <select
                    className="form-control"
                    onChange={(e) => changeUser(field, e.target.value)}
                    onBlur={field.onBlur}
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Academia">Academia</option>
                    <option value="Sector Público">Sector Público</option>
                    <option value="Sector privado">Sector privado</option>
                  </select>
                )}
                rules={{ required: true }}
              />
              {errors.user && errors.user.type === "required" && (
                <span className="invalid">
                  Campo tipo usuario es obligatorio
                </span>
              )}
            </div>
            <div className="mb-3">
              <Label> Seleccione una región de interés: </Label>
              <Controller
                control={control}
                name="region"
                render={({ field }) => (
                  <select
                    className="form-control"
                    onChange={(e) => changeRegion(field, e.target.value)}
                    onBlur={field.onBlur}
                  >
                    <option value="">Seleccione una opción</option>
                    {regionesLimpias.map((r, e) => (
                      <option key={e} value={r.IdRegion}>
                        {r.NombreRegion}
                      </option>
                    ))}
                  </select>
                )}
                rules={{ required: true }}
              />
              {errors.region && errors.region.type === "required" && (
                <span className="invalid">Campo región es obligatorio</span>
              )}
            </div>
            <div className="mb-3">
              {/*details && (
                <ul className="list-group">
                  <li className="list-group-item">
                    Location :{" "}
                    {`${details.city}, ${details.state}(${details.country_code})`}
                  </li>
                  <li className="list-group-item">IP: {details.IPv4}</li>
                </ul>
              )*/}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            onClick={(e) => ValidarFormulario(e)}
          >
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : null}
            &nbsp; Ingresar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalMessage;
