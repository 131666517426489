import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import AnomaliasSection from '../../components/AnomaliasSection'
import UsuariosConcurrentes from '../../components/UsuariosConcurrentes/UsuariosConcurrentes';

const AnomaliasPage =() => {
    return(
        <Fragment>
            <Navbar2 Logo={Logo}/>
            <PageTitle pagesub={'Gráficos Especializados Anomalias'}/> 
            <UsuariosConcurrentes componente={<AnomaliasSection/>} />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AnomaliasPage;