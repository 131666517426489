import React, { useState } from 'react';
import { useEffect } from 'react';
import { Tab, Tabs, Row, Col, Container, Form } from "react-bootstrap-v5";
import { getPrediccionHeladas } from '../../../services/apisService';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DatePicker } from "react-nice-dates";
import { es } from "date-fns/locale";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

const PrediccionTabs = () => {
    const [heladas, setHeladas] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const estaciones = useSelector((store) => store.pagina.estacion);
    const regionSeleccionada = useSelector((store) => store.pagina.valueCombobox);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            FechaPredicion: null,
            Estacion: null,
        }
    });

    const columns = [{
        dataField: 'NombreEstacion',
        text: 'Estación',
        sort: true
    },
    {
        dataField: 'FechaMedicion',
        text: 'Fecha medición',
        sort: true,//agregar la hora
        formatter: (cell, row) => `${row.FechaMedicion} ${row.HoraMedicion.toString().padStart(2, "0")}:00`,
    },
    {
        dataField: 'ValorBulbo',
        text: 'Valor bulbo',
        sort: true,
    },
    {
        dataField: 'FechaPrediccion',
        text: 'Fecha Predicción',
        sort: true
    },
    {
        dataField: 'NombreNivelPrediccion',
        text: 'Nivel predicción',
        sort: true,
        sort: true,
        align: 'center',
        headerAlign: 'center'
    }];

    const optionsPage = {
        sizePerPageList: [{
            text: '10', value: 10
        },
        {
            text: 'Todos', value: heladas?.length ?? 1000
        }],
        paginationSize: 10,
        prePageText: '‹',
        nextPageText: '›',
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        hideSizePerPage: false,
        withFirstAndLast: true,
        firstPageText: '‹‹',
        lastPageText: '››'
    };

    const submitForm = async (data) => {
        try {
            setLoading(true)
            const predicciones = await getPrediccionHeladas(data.FechaPredicion.toISOString().split('T')[0], data.Estacion[0].E_Estacion_Id)
            const newArray = predicciones.map((item, index) => ({ id: index, ...item }));

            setHeladas(newArray)
        }
        catch (exception) {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            console.log(exception)
        }
        finally {
            setLoading(false)
        }
    }
    const compare = (a, b) => {
        if (a.E_Estacion_Nombre < b.E_Estacion_Nombre) {
            return -1;
        }
        if (a.E_Estacion_Nombre > b.E_Estacion_Nombre) {
            return 1;
        }
        return 0;
    }
    return (
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <form onSubmit={handleSubmit(submitForm)} name="formPrediccionHeladas">
                <Row>
                    <Col xs={12} md={4} lg={4} className='mt-2'>
                        <Row>
                            <Col xs={7} md={10} lg={10} className='mt-2 pr-0 '>
                                <p className="body-3 muted  mb-0">Estación </p>
                                <Controller
                                    control={control}
                                    name="Estacion"
                                    render={({ field: { value, onChange } }) => (
                                        <Multiselect
                                            
                                            displayValue="E_Estacion_Nombre"
                                            singleSelect={true}
                                            options={regionSeleccionada && estaciones ? estaciones.filter(x => x.R_Region_Id === Number(regionSeleccionada)).sort(compare) : []}
                                            showCheckbox={true}
                                            loading={estaciones ? false : true}
                                            loadingMessage={'Cargando...'}
                                            hidePlaceholder={true}
                                            closeOnSelect={false}
                                            onSelect={onChange}
                                            onRemove={onChange}
                                            selectedValues={value}
                                            emptyRecordMsg={'No se encontraron opciones.'}
                                            placeholder="Seleccione una opción"
                                        />
                                    )}
                                    rules={{
                                        required: 'Este campo es requerido.'
                                    }}
                                />
                            </Col>
                        </Row>
                        {errors?.Estacion && errors?.Estacion.type === "required" && <span className="invalid">Este campo es requerido.</span>}
                    </Col>
                    <Col xs={12} md={4} lg={4} className='mt-2'>
                        <Row>
                            <Col xs={7} md={9} lg={9} className='mt-2 pr-0 maxcustom'>
                                <p className="body-3 muted mb-0">Fecha predicción </p>
                                <Controller
                                    control={control}
                                    name="FechaPredicion"
                                    render={({ field: { value, onChange } }) => (
                                        <DatePicker date={value} onDateChange={onChange} locale={es}>
                                            {({ inputProps, focused }) => (
                                                <div className="date-range">
                                                    <input
                                                        style={{ height: '45px' }}
                                                        className={'form-datePicker input' + (focused ? ' -focused' : '')}

                                                        {...inputProps}
                                                        placeholder='dd/mm/yyyy'
                                                    />
                                                </div>
                                            )}
                                        </DatePicker>
                                    )}
                                    rules={{
                                        required: 'Este campo es requerido.'
                                    }}
                                />
                                {errors?.FechaPredicion && errors?.FechaPredicion.type === "required" && <span className="invalid">Este campo es requerido.</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4} lg={4} className='mt-2'>
                        <Row className='mt-4 pt-2'>
                            <Col xs={7} md={9} lg={9} className='mt-2 pr-0'>
                                <button disabled={isLoading} className="btn btn-primary" type='submit'
                                    style={{ backgroundColor: '#0b5ed7', borderColor: '#0a58ca' }}
                                >
                                    {isLoading ? <i className="fa fa-spinner fa-spin fa-1x"></i> : null} Buscar
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
            <div className="table-responsive mt-4 pb-3 table-striped table-borderless table-hover">
                <BootstrapTable
                    pagination={paginationFactory(optionsPage)}
                    filter={filterFactory()}
                    noDataIndication="No se encontraron registros."
                    keyField='id'
                    data={heladas ?? []} columns={columns}
                />
                <br></br><br></br><br></br>
            </div>
        </div>
    )
};
export default PrediccionTabs;