import * as actionTypes from "../constants/actionTypes";

const initialState = {
  formularioFiltroReporte: null,
  valueCombobox: null,
  romanoCombobox: null,
  valueSubMenu: null,
  valueButton: null,
  pageReport: []
};


const setFiltroReporte = (state, { payload }) => {
  return {
    ...state,
    formularioFiltroReporte: payload,
  };
};

const addFiltroReporte = (state, { payload }) => {
  return {
    ...state,
    formularioFiltroReporte: payload,
  };
};

const setValueCombobox = (state, { payload }) => {
  return {
    ...state,
    valueCombobox: payload,
  };
};

const addValueCombobox = (state, { payload }) => {
  return {
    ...state,
    valueCombobox: payload,
  };
};

const setRomanoCombobox = (state, { payload }) => {
  return {
    ...state,
    romanoCombobox: payload,
  };
};

const addRomanoCombobox = (state, { payload }) => {
  return {
    ...state,
    romanoCombobox: payload,
  };
};

const setValueSubMenu = (state, { payload }) => {
  return {
    ...state,
    valueSubMenu: payload,
  };
};

const addValueSubMenu = (state, { payload }) => {
  return {
    ...state,
    valueSubMenu: payload,
  };
};
const setValueButton = (state, { payload }) => {
  return {
    ...state,
    valueButton: payload,
  };
};

const addValueButton = (state, { payload }) => {
  return {
    ...state,
    valueButton: payload,
  };
};

const addValorPageReportPowerBI = (state, { payload }) => {
  return {
    ...state,
    pageReport: [...state.pageReport, payload],
  };
};

const paginaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTRO_REPORTE_SUCCESS:
      return setFiltroReporte(state, action);
    case actionTypes.ADD_FILTRO_REPORTE_SUCCESS:
      return addFiltroReporte(state, action);
    case actionTypes.SET_VALUE_COMBOBOX:
      return setValueCombobox(state, action);
    case actionTypes.ADD_VALUE_COMBOBOX:
      return addValueCombobox(state, action);
    case actionTypes.ADD_ROMANO_COMBOBOX:
      return addRomanoCombobox(state, action);
    case actionTypes.SET_ROMANO_COMBOBOX:
      return setRomanoCombobox(state, action);
    case actionTypes.SET_VALUE_SUBMENU:
      return setValueSubMenu(state, action);
    case actionTypes.ADD_VALUE_SUBMENU:
      return addValueSubMenu(state, action);
    case actionTypes.SET_VALUE_BUTTON:
      return setValueButton(state, action);
    case actionTypes.ADD_VALUE_BUTTON:
      return addValueButton(state, action);
    case actionTypes.ADD_VALUE_PAGE_REPORT:
      return addValorPageReportPowerBI(state, action);

    default:
      return state;
  }
};

export default paginaReducer;


