import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Card, CardBody, CardTitle } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import LoaderGif from "../../images/Spinner.gif";

import {
  setExportar,
  setResultado,
} from "../../appStatus/actions/actionPagina";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { format, subDays } from "date-fns";
import {
  getEstadisticas,
  getEstadisticasCsv,
} from "../../services/apisService";
import { setIsLoading as setGlobalIsLoading } from "../../appStatus/actions/actionPagina";
import ModalGuiaConsumoApi from "./ModalGuiaConsumoApi";
import { ca } from "date-fns/locale";
import { Alert } from "react-bootstrap-v5";
import { useRef } from "react";
import { setMensajeInformacion } from "../../appStatus/actions/actionMensajeInformacion";

const urlApisBirh = process.env.REACT_APP_API_HELADAS;

const ListadoFiltro = () => {
  const estadisticasUrl = `${urlApisBirh}Estadisticas`;
  const estadisticasCsvUrl = `${urlApisBirh}EstadisticasCsv`;

  const dispatch = useDispatch();
  const componentMounted = useRef(true);

  const formFiltroReporte = useSelector((store) => store.pagina.formularioFiltroReporte);
  const botonExportar = useSelector((store) => store.pagina.exportarArchivo);

  const [nombreColumnas, setNombreColumnas] = useState(null);
  const [resultadoFiltro, setResultadoFiltro] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [dataModalGuiaConsumo, setDataModalGuiaConsumo] = useState(null);
  const [dataModalGuiaConsumoCsv, setDataModalGuiaConsumoCsv] = useState(null);

  const {
    selVariable,
    selEstadisticas,
    selEstadisticas2,
    FechaInicio,
    FechaFin,
    nombreSerieTemporal,
    Estaciones,
  } = formFiltroReporte || [];

  const { nombreSerieFinal } = nombreSerieTemporal || [];

  let nombreSerie;
  [nombreSerie] = nombreSerieFinal || [];

  if (nombreSerie === undefined || nombreSerie === null) {
    nombreSerie = "Original";
  }

  const handleError = (error) => {
    if (error?.response?.data?.statusCode === 403) {
      dispatch(setMensajeInformacion(error?.response?.data?.message))
    }
    else if (error?.response?.data?.statusCode === 429)
      dispatch(setMensajeInformacion("Demaciadas solicitudes intente nuevamente mas tarde"))
    else
      dispatch(setMensajeInformacion("Ocurrio un error inesperado, intente nuevamente mas tarde."))
  };

  useEffect(
    (page, sizePerPage) => {
      if (botonExportar) {
        const fetchEstadisticas = async () => {
          dispatch(setGlobalIsLoading(true));
          try {
            const resultadoFiltrosExportar = await getEstadisticas({
              Estaciones,
              FechaDesde: FechaInicio,
              FechaHasta: FechaFin,
              Variables:
                selVariable instanceof Array ? selVariable : [selVariable],
              SerieTemporal: nombreSerie,
              Estadistica: selEstadisticas,
              Estadistica2: selEstadisticas2,
              Pagina: page,
              CantidadRegistros: sizePerPage ? sizePerPage : 10,
            });

            if (componentMounted.current) {
              setResultadoFiltro(resultadoFiltrosExportar);
              setPage(page);
              setTotalSize(resultadoFiltrosExportar.TotalRegistros);
              setSizePerPage(Number(resultadoFiltrosExportar.Limite));
            }
            return () => {
              componentMounted.current(false);
            }

          } catch (error) {
            handleError(error);
          } finally {
            dispatch(setGlobalIsLoading(false));
            dispatch(setExportar(false));
            //setResultadoFiltro(null);
          }
        };
        fetchEstadisticas();
      }
    },
    [
      botonExportar,
      Estaciones,
      FechaInicio,
      FechaFin,
      selVariable,
      nombreSerie,
      selEstadisticas,
      selEstadisticas2,
    ]
  );

  const onTableChange = async (type, { page, sizePerPage }) => {
    //fetchEstadisticas(page, sizePerPage)
    dispatch(setGlobalIsLoading(true));
    try {
      const resultadoFiltrosExportar = await getEstadisticas({
        Estaciones,
        FechaDesde: FechaInicio,
        FechaHasta: FechaFin,
        Variables: selVariable instanceof Array ? selVariable : [selVariable],
        SerieTemporal: nombreSerie,
        Estadistica: selEstadisticas,
        Estadistica2: selEstadisticas2,
        Pagina: page,
        CantidadRegistros: sizePerPage ? sizePerPage : 10,
      });

      if (componentMounted.current) {
        setResultadoFiltro(resultadoFiltrosExportar);
        setPage(page);
        setTotalSize(resultadoFiltrosExportar.TotalRegistros);
        setSizePerPage(Number(resultadoFiltrosExportar.Limite));
      }
      return () => {
        componentMounted.current(false);
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setGlobalIsLoading(false));
      dispatch(setExportar(false));
      //setResultadoFiltro(null);
    }
  };

  useEffect(() => {
    const hideColumns = ["IdAutonumerico"];
    if (resultadoFiltro?.Items) {
      const columnas = [];
      for (var item in resultadoFiltro.Items[0]) {
        if (hideColumns.indexOf(item) < 0)
          columnas.push({
            dataField: item,
            text: item.replace("_", " "),
          });
      }

      setNombreColumnas(columnas);
    }
  }, [resultadoFiltro]);


  const exportarCSV = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (formFiltroReporte) {
        const resultadoFiltrosExportar = await getEstadisticasCsv({
          Estaciones,
          FechaDesde: FechaInicio,
          FechaHasta: FechaFin,
          Variables: selVariable instanceof Array ? selVariable : [selVariable],
          SerieTemporal: nombreSerie,
          Estadistica: selEstadisticas,
          Estadistica2: selEstadisticas2,
        });

        var blob = new Blob([resultadoFiltrosExportar.data], {
          type: resultadoFiltrosExportar.headers["content-type"],
        });
        saveAs(blob, "ReporteEstadisticas.csv");
      }
      //toast.success('Documento descargado correctamente.')
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      dispatch(setExportar(false));
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} resultados
    </span>
  );
  const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
  };

  const onVerModalGuiaConsumo = () => {
    const formData = {
      Estaciones,
      FechaDesde: FechaInicio,
      FechaHasta: FechaFin,
      Variables: selVariable instanceof Array ? selVariable : [selVariable],
      SerieTemporal: nombreSerie,
      Estadistica: selEstadisticas,
      Pagina: 1,
      CantidadRegistros: 10,
    };
    setDataModalGuiaConsumo(formData);
  };
  const onVerModalGuiaConsumoCsv = () => {
    const formData = {
      Estaciones,
      FechaDesde: FechaInicio,
      FechaHasta: FechaFin,
      Variables: selVariable instanceof Array ? selVariable : [selVariable],
      SerieTemporal: nombreSerie,
      Estadistica: selEstadisticas,
    };
    setDataModalGuiaConsumoCsv(formData);
  };
  return (
    <>
      <ModalGuiaConsumoApi
        handleClose={() => setDataModalGuiaConsumo(null)}
        queryData={dataModalGuiaConsumo}
        apiData={{ apiUrl: estadisticasUrl, titulo: "Estadisticas" }}
        show={dataModalGuiaConsumo ? true : false}
      />
      <ModalGuiaConsumoApi
        handleClose={() => setDataModalGuiaConsumoCsv(null)}
        queryData={dataModalGuiaConsumoCsv}
        apiData={{ apiUrl: estadisticasCsvUrl, titulo: "Estadisticas Csv" }}
        show={dataModalGuiaConsumoCsv ? true : false}
      />
      <Container>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="btn-group" role="group">
                <button
                  name="csv"
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => exportarCSV(e)}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Exportar Csv Estadística&nbsp;{" "}
                  {isLoading ? <i className="fa fa-spinner fa-spin"></i> : null}
                </button>
                <button
                  name="guiaConsumirApi"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onVerModalGuiaConsumo()}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Guia consumo API JSON
                </button>
                <button
                  name="guiaConsumirApi"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onVerModalGuiaConsumoCsv()}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Guia consumo API CSV
                </button>
              </div>
            </CardTitle>
            <Row>
              <Col md={12}>
                <div className="table-responsive table-striped table-borderless table-hover">
                  {resultadoFiltro && nombreColumnas?.length > 0 ? (
                    <>
                      <BootstrapTable
                        remote
                        loading={loading}
                        data={resultadoFiltro.Items}
                        keyField="IdAutonumerico"
                        columns={nombreColumnas}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          options,
                        })}
                        onTableChange={onTableChange}
                      ></BootstrapTable>
                    </>
                  ) : (
                    <Alert className="text-center" variant="warning">
                      <Alert.Heading>No se encontraron datos para esta consulta.</Alert.Heading>
                    </Alert>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ListadoFiltro;
