import React, { useEffect, useState } from "react";
import PowerBi from "../PowerBi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setFiltroCargado,
  LimpiaFiltros,
} from "../../appStatus/actions/actionPagina";
import SeccionVisor from "./seccionVisor";
import FiltroVisor from "./filtroVisor";
import HeaderFiltro from "../Header-Filtro";

const VisorSection = () => {
  const dispatch = useDispatch();

  // let id = "ffc7c325-9b3d-4906-8695-8bb444e5b9f6"
  let id = "1";

  const [isMenuShow, setIsMenuShow] = useState(false);

  //let id = process.env.ID_REPORTE_VISOR
  const sidebarOpened = useSelector((store) => store.pagina.sidebarOpened);
  const filtrosVisor = useSelector((store) => store.pagina.formularioFiltroReporte);
  const filtroCargado = useSelector((store) => store.pagina.filtroCargado);
  const valueCombobox = useSelector((store) => store.pagina.valueCombobox);

  const { Entidad, Variable, Estacion } = filtrosVisor || [];

  const { valueSubMenu } = useParams();

  useEffect(() => {
    if (valueSubMenu) {
      dispatch(setFiltroCargado(true));
    }
  }, [valueSubMenu]);

  useEffect(() => {
    if (sidebarOpened) {
      setIsMenuShow(true);
    } else {
      setIsMenuShow(false);
    }
  }, [sidebarOpened]);

  const filterCarga = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "carga_datos",
      column: "carga",
    },
    operator: "In",
    values: [1],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterRegion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_REGION",
      column: "Region_id",
    },
    operator: "In",
    values: [parseInt(valueCombobox)],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterSubMenu = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_DOMINIO_VARIABLE",
      column: "Dominio_Variable_Id",
    },
    operator: "In",
    values: [parseInt(valueSubMenu)],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterEntidad = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_ENTIDAD",
      column: "Entidad_Id",
    },
    operator: "In",
    values: [Entidad],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterVariable = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_VARIABLE",
      column: "Variable_Id",
    },
    operator: "In",
    values: [Variable],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterEstacion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_ESTACIONES_MONITOREO",
      column: "Estacion_Id",
    },
    operator: "In",
    values: [Estacion],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterCarga2 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Carga_Datos_v2",
      column: "Carga_Datos_v2",
    },
    operator: "In",
    values: [1],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filtrosReporteVisor = [
    filterCarga,
    filterRegion,
    filterSubMenu,
    filterEntidad,
    filterVariable,
    filterEstacion,
    filterCarga2,
  ];

  return (
    <div>
      {/*<SeccionVisor valorDominio={valueSubMenu} />*/}
      <div className={`wrap ${isMenuShow ? "menu-filtro" : ""}`}>
      
      <FiltroVisor valorDominio={valueSubMenu} />
      <main className="content">
        {filtroCargado && filtrosVisor && (
          <PowerBi
            reportId={id}
            requiereFiltros={true}
            filtrosReporte={filtrosReporteVisor}
            esTemporal={false}
          />
        )}
      </main>
    </div>
    </div>
  );
};

export default VisorSection;
