import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { activarCuenta, existActivacionCuenta } from '../../services/apiAuthService';
import '../LoginPage/./style.scss';
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect } from 'react';
import { useParams } from "react-router";

import { useHistory } from 'react-router-dom'

const SITE_KEY_CAPTCHA = process.env.REACT_APP_KEY_CAPTCHA;

const GeneracionPassword = () => {
    const recaptchaRef = React.useRef();
    const { solicitud } = useParams();
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const existeSolicitud = await existActivacionCuenta(solicitud)
                //console.log(existeSolicitud)
                if (!existeSolicitud) {
                    toast.error("El enlace para activación no es valido.")

                    const timer = setTimeout(() => {
                        history.push(`/`);
                    }, 3000);
                    return () => clearTimeout(timer)
                }
            }
            catch {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
                const timer = setTimeout(() => {
                    history.push(`/`);
                }, 3000);
                return () => clearTimeout(timer)
            }
        };
        fetchData();
    }, [solicitud]);


    const { control, handleSubmit, getValues, formState: { errors } } = useForm({
        defaultValues: {
            password: '',
            copiaPassword: '',
            solicitudId: 0,
            usuarioId: 0
        },
        reValidateMode: 'onBlur'
    });

    const submitForm = async (data) => {
        try {

            const captchaToken = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            const formData = {
                Password: data.password,
                Codigo: solicitud,
                Captcha: captchaToken
            };

            setLoading(true)

            const result = await activarCuenta(formData)

            if (result?.fueCorrecto) {
                toast.success(result.mensaje)
            }
            else {
                toast.error(result.mensaje)
            }
            history.push(`/`);
        }
        catch (exception) {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            console.log(exception)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Grid className="loginWrapper">
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={SITE_KEY_CAPTCHA}
            />
            <Grid className="loginForm">
                <h2>Generacion de contraseña</h2>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        className="inputOutline"
                                        fullWidth
                                        placeholder="Password"
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        name="password"
                                        label="Password"
                                        error={!!error}
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                rules={{
                                    required: 'Este campo es requerido.',
                                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?()\s_.:,;!@$%^&*-]).{8,}$/i//que tenga mayusculas y numers y caracteres
                                }}
                            />
                            {errors?.password && errors?.password.type === "required" && <span className="invalid">{errors?.password.message}</span>}
                            {errors?.password && errors?.password.type === "pattern" && <span className="invalid">La contraseña debe poseer un mímino de 8 carácteres,debe poseer números, mayúsculas y carácteres especiales.</span>}

                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="copiaPassword"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        className="inputOutline"
                                        fullWidth
                                        error={!!error}
                                        placeholder="repita password"
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        name="copiaPassword"
                                        type="password"
                                        label="Repita Password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                rules={{
                                    required: 'Este campo es requerido.',
                                    validate: value => getValues('password') === value

                                }}

                            />
                            {errors?.copiaPassword && errors?.copiaPassword.type === "required" && <span className="invalid">{errors?.password.message}</span>}
                            {errors?.copiaPassword && errors?.copiaPassword.type === "validate" && <span className="invalid">La contraseña no coincide.</span>}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth disabled={isLoading} className="cBtnTheme" type="submit">
                                    {isLoading ? <i className="fa fa-spinner fa-spin fa-2x"></i> : null}
                                    Aceptar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default withRouter(GeneracionPassword);