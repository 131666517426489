export class PerfilPlataformaEnum {

    static Administrador = new PerfilPlataformaEnum(1);
    static Super_Usuario = new PerfilPlataformaEnum(2);
    static Usuario_Autentificado = new PerfilPlataformaEnum(3);


    constructor(value) {
        this.value = value;
    }

    toString() {
        return `PerfilPlataforma.${this.value}`;
    }
}

export class TipoProductoEnum {

    static Privado = new TipoProductoEnum(1);
    static Publico = new TipoProductoEnum(2);

    constructor(value) {
        this.value = value;
    }

    toString() {
        if (this.value === 1)
            return "Privado";
        if (this.value === 2)
            return "Público"
    }
}

