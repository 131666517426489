import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Alert, Badge } from "react-bootstrap-v5";
import api from "../../services/apiMenuService";
import {
  setIsLoading,
  setResultado,
  random,
  isRandom,
} from "../../appStatus/actions/actionPagina";
import { format, subDays } from "date-fns";
import "./botones.css";
import "./display.css";
import EarthSvg from "../../helpers/Svg/EarthSvg";
import LocationSvg from "../../helpers/Svg/LocationSvg";
import CalendarSvg from "../../helpers/Svg/CalendarSvg";
import TemperatureSvg from "../../helpers/Svg/TemperatureSvg";
import PageLoaderModal from "../PageLoaderModal/PageLoaderModal";

const Calculadora = ({ message, time }) => {
  const { setValue } = useForm({ reValidateMode: "onBlur" });

  const dispatch = useDispatch();

  const region = useSelector((store) => store.pagina.region);
  const obtenerVariable = useSelector((store) => store.pagina.varEntidad);
  const valueCombobox = useSelector((store) => store.pagina.valueCombobox);
  const filtroReporte = useSelector(
    (store) => store.pagina.formularioFiltroReporte
  );
  //const isLoading = useSelector((store) => store.pagina.isLoading);

  const steps = [
    {
      id: "PRINCIPAL",
      title: "Filtros aplicados",
    },
    {
      id: "CALCULO",
      title: "Calculadora",
    },
  ];

  const {
    selVariable,
    categoria,
    dominio,
    FechaInicio,
    FechaFin,
    selEstacion,
  } = filtroReporte || [];

  const { dominioSeleccionado, dominioIdSeleccionado } = dominio || [];
  const { categoriaSeleccionada, categoriaIdSeleccionada } = categoria || [];
  const [hour, setHour] = useState(0);
  const [nombreRegionSeleccionada, setNombreRegionSeleccionada] = useState("");
  const [currentSumatoria, setCurrentSumatoria] = useState(null);
  const [currentComplejo, setCurrentComplejo] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    condition: "<>",
    valor: 0,
  });
  const [variableSeleccionada, setVariableSeleccionada] = useState(null);
  /* Calculadora Botones */
  const [estacionUno, setEstacionUno] = useState(null);
  const [estacionDos, setEstacionDos] = useState(null);
  const [estacionTres, setEstacionTres] = useState(null);
  const [funcionUno, setFuncionUno] = useState(null);
  const [funcionDos, setFuncionDos] = useState(null);
  const [aritmeticaUno, setAritmeticaUno] = useState(null);
  const [enter, setEnter] = useState(false);

  const [calculo, setCalculo] = useState(null);
  const condicion = [
    {
      id: 1,
      value: "<>",
      title: "<>",
    },
    {
      id: 2,
      value: "<",
      title: "<",
    },
    {
      id: 3,
      value: ">",
      title: ">",
    },
    {
      id: 4,
      value: "<=",
      title: "<=",
    },
    {
      id: 5,
      value: ">=",
      title: ">=",
    },
  ];

  const getButtonValues = (type = "calculo") => {
    const values = {
      sumatoria: [
        {
          id: "plus",
          value: "+",
        },
        {
          id: "minus",
          value: "-",
        },
        {
          id: "x",
          value: "x",
        },
        {
          id: "divide",
          value: "/",
        },
        {
          id: "percent",
          value: "%",
        },
      ],
      complejos: [
        { id: "log", value: "log" },
        { id: "ln", value: "ln" },
        { id: "abs", value: "abs" },
        { id: "exp", value: "exp" },
        { id: "sqrt", value: "sqrt" },
      ],
      calculo: [
        { id: "max", value: "Max" },
        { id: "ave", value: "x̄" },
        { id: "min", value: "Min" },
        { id: "acu", value: "Acum" },
      ],
      final: [
        { id: "=", value: "Enter" },
        { id: "c", value: "C" },
      ],
    };

    return values[type];
  };

  /*useEffect(() => {
    let c = "";
    if (NombreEstacion && currentComplejo && currentSumatoria) {
      for (let i = 0; i < NombreEstacion.length; i++) {
        c +=
          currentComplejo +
          "(" +
          NombreEstacion[i].trim() +
          ") " +
          currentSumatoria +
          " ";
      }
      setCalculoFinal(c.slice(0, -3));
    }
  }, [NombreEstacion, currentComplejo, currentSumatoria]);*/

  useEffect(() => {
    if (funcionUno === null) {
      setFuncionUno(currentComplejo);
      setCurrentComplejo(null);
    } else if (aritmeticaUno === null) {
      setAritmeticaUno(currentSumatoria);
    } else if (funcionDos === null) {
      setFuncionDos(currentComplejo);
      setCurrentComplejo(null);
    }
  }, [
    currentComplejo,
    currentSumatoria,
    funcionUno,
    funcionDos,
    aritmeticaUno,
  ]);

  /*useEffect(() => {
    if (NombreEstacion.length) {
      let arr = NombreEstacion.join(",");
      setEstacionesSeparadas(arr);
    }
  }, [NombreEstacion]);*/

  useEffect(() => {
    if (valueCombobox) {
      let obtenerRegionNombre = region.find(
        (r) => r.IdRegion === valueCombobox
      );
      setNombreRegionSeleccionada(obtenerRegionNombre);
    }
  }, [region, valueCombobox]);

  const handleButtonEstacionClick = (estacionesValue) => {
    if (estacionUno === null && estacionDos === null && estacionTres === null) {
      setEstacionUno(estacionesValue);
    } else if (estacionDos === null && estacionTres === null) {
      setEstacionDos(estacionesValue);
    } else if (estacionTres === null) {
      setEstacionTres(estacionesValue);
    }
  };

  const handleButtonClick = (buttonValue) => {
    switch (buttonValue) {
      case "+":
        setCurrentSumatoria(buttonValue);
        break;
      case "-":
        setCurrentSumatoria(buttonValue);
        break;
      case "x":
        setCurrentSumatoria(buttonValue);
        break;
      case "/":
        setCurrentSumatoria(buttonValue);
        break;
      case "%":
        setCurrentSumatoria(buttonValue);
        break;
      case "log":
        setCurrentComplejo(buttonValue);
        break;
      case "ln":
        setCurrentComplejo(buttonValue);
        break;
      case "abs":
        setCurrentComplejo(buttonValue);
        break;
      case "exp":
        setCurrentComplejo(buttonValue);
        break;
      case "sqrt":
        setCurrentComplejo(buttonValue);
        break;
      case "Max":
        setCurrentComplejo(buttonValue);
        break;
      case "x̄":
        setCurrentComplejo("Average");
        break;
      case "Min":
        setCurrentComplejo(buttonValue);
        break;
      case "Acum":
        setCurrentComplejo(buttonValue);
        break;
      default:
        break;
    }
  };

  const limpiarDisplayClick = () => {
    setEnter(false);
    setFuncionUno(null);
    setFuncionDos(null);
    setEstacionUno(null);
    setEstacionDos(null);
    setAritmeticaUno(null);
    setCurrentSumatoria(null);
    setCalculo(null);
  };

  const enterDisplayClick = () => {
    if (
      funcionUno !== null &&
      estacionUno !== null &&
      aritmeticaUno !== null &&
      funcionDos !== null &&
      estacionDos !== null
    ) {
      setCalculo(
        funcionUno +
          "(" +
          estacionUno +
          ") " +
          aritmeticaUno +
          " " +
          funcionDos +
          "(" +
          estacionDos +
          ")"
      );
      setEnter(true);
    } else {
      setCalculo("ERROR");
      setEnter(false);
    }
  };

  const handleNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (time) {
      var hour = Math.floor(time / 3600);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = Math.floor((time / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      var second = time % 60;
      second = second < 10 ? "0" + second : second;
      setHour(hour + ":" + minute + ":" + second);
    }
  }, [time]);

  /*useEffect(() => {
    if (selTemporalidad === "mes") {
      let month = format(new Date(), "yyyy-MM-ddT");
      let lastMonth = format(subDays(new Date(), 30), "yyyy-MM-dd");
      setFechaInicio(month);
      setFechaFin(lastMonth);
    } else if (selTemporalidad === "anio") {
      let year = format(new Date(), "yyyy-MM-dd");
      let lastYear = format(subDays(new Date(), 365), "yyyy-MM-dd");
      setFechaInicio(year);
      setFechaFin(lastYear);
    } else if (selTemporalidad === "calendario") {
      setFechaInicio(FechaInicio);
      setFechaFin(FechaFin);
    }
  }, [selTemporalidad, FechaInicio, FechaFin]);*/

  const handleSubmit = async (e) => {
    e.preventDefault();

    let aux = {
      formulario: {
        funcion: calculo,
        condicion: formValues.condition + formValues.valor,
        fechaInicio: FechaInicio,
        fechaFin: FechaFin,
      },
      estaciones: selEstacion,
      variables: [
        {
          IdVariable: selVariable,
        },
      ],
    };
    try {
      //setIsLoadingCal(true);
      dispatch(setIsLoading(true));
      const ingreso = await api.RegistroInformacionCalculadoraReporte(aux);

      const { fueCorrecto, mensaje, data } = ingreso;

      if (fueCorrecto) {
        dispatch(random(data));
        dispatch(isRandom(true));
      }

      dispatch(
        setResultado({
          titulo: "Registro Datos Calculadora",
          fueCorrecto,
          mensaje: mensaje,
        })
      );
    } catch (error) {
      dispatch(
        setResultado({
          titulo: "Registro Datos Calculadora",
          fueCorrecto: false,
          mensaje:
            "Ha ocurrido un error al registrar los datos de la calculadora",
        })
      );
    }

    //dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (obtenerVariable && selVariable) {
      console.log(selVariable[0]);
      const variable = obtenerVariable.find((v) => v.IdVariable === selVariable[0]);

      console.log(variable);

      setVariableSeleccionada(variable);
    }
  }, [obtenerVariable, selVariable]);

  //const condiciones = "<>0, >=0, <=0, <>BLANK, etc";
  return (
    <>
      <form className="steps-form" onSubmit={handleSubmit}>
        <div>
          {/*<p>{steps[currentStep].title}</p>*/}
          {steps[currentStep].id === "PRINCIPAL" && (
            <>
              <div className="mb-3">
                <Alert className="alert-success">
                  <div className="row g-1">
                    <div className="col-3">
                      <EarthSvg />
                    </div>
                    <div className="col-9">
                      <Badge pill bg="warning">
                        {valueCombobox}
                      </Badge>
                    </div>
                  </div>
                  <div className="row g-1">
                    <div className="col-3">Entidad</div>
                    <div className="col-9">
                      <Badge pill bg="warning">
                        {/*selEntidad*/}
                        Agroclima
                      </Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <LocationSvg />
                    </div>
                    <div className="col-md-9 ms-auto">
                      {selEstacion &&
                        selEstacion.map((item, key) => {
                          return (
                            <Badge pill bg="warning">
                              {item.est_Estacion_Nombre}
                            </Badge>
                          );
                        })}
                    </div>
                  </div>
                  <div className="row g-1">
                    <div className="col-3">
                      <CalendarSvg />
                    </div>
                    <div className="col-9">
                      <Badge pill bg="warning">
                        {FechaInicio}
                      </Badge>
                      <Badge pill bg="warning">
                        {FechaFin}
                      </Badge>
                    </div>
                  </div>
                  <div className="row g-1">
                    <div className="col-3">Dominio</div>
                    <div className="col-9">
                      <Badge pill bg="warning">
                        {dominioSeleccionado}
                      </Badge>
                    </div>
                  </div>
                  <div className="row g-1">
                    <div className="col-3">
                      <TemperatureSvg />
                    </div>
                    <div className="col-9">
                      <Badge pill bg="warning">
                        {categoriaSeleccionada}
                      </Badge>
                    </div>
                  </div>
                  <div className="row g-1">
                    <div className="col-3">Variable</div>
                    <div className="col-9">
                      <Badge pill bg="warning">
                        {variableSeleccionada?.NombreVariable}
                      </Badge>
                    </div>
                  </div>
                </Alert>
              </div>
            </>
          )}
          {steps[currentStep].id === "CALCULO" && (
            <div>
              <div className="mb-3">
                <div className="containerDisplay">
                  <p className="numeros">
                    {funcionUno}
                    {estacionUno}
                    {aritmeticaUno}
                    {funcionDos}
                    {estacionDos}
                  </p>
                  <p className="resultado">{calculo}</p>
                </div>
              </div>
              <div className="mb-3">
                <p>Operaciones</p>
              </div>
              <div className="mb-3  centrar-buttons">
                <div className="row g-1">
                  {getButtonValues("sumatoria").map((b) => (
                    <input
                      type="button"
                      name={b.id}
                      onClick={() => handleButtonClick(b.value)}
                      value={b.value}
                      className="button-calculadora-rounded"
                    />
                  ))}
                </div>
              </div>
              <div className="mb-3">
                <p>Funciones</p>
              </div>
              <div className="mb-3 centrar-buttons">
                {getButtonValues("complejos").map((b) => (
                  <input
                    type="button"
                    name={b.id}
                    onClick={() => handleButtonClick(b.value)}
                    value={b.value}
                    className="button-calculadora-rounded"
                  />
                ))}
              </div>
              <div className="mb-3  centrar-buttons">
                {getButtonValues("calculo").map((b) => (
                  <input
                    type="button"
                    name={b.id}
                    onClick={() => handleButtonClick(b.value)}
                    value={b.value}
                    className="button-calculadora-rounded"
                  />
                ))}
                <input
                  type="button"
                  name="c"
                  onClick={(e) => limpiarDisplayClick("C")}
                  value={"C"}
                  className="button-calculadora-rounded borrar"
                />
              </div>
              <div className="mb-3 centrar-buttons">
                <input
                  type="button"
                  name="enter"
                  value={"="}
                  onClick={() => enterDisplayClick("enter")}
                  className="button-calculadora-long"
                />
              </div>
              <div className="mb-3">
                {selEstacion.map((item, key) => {
                  return (
                    <input
                      type="button"
                      name={item.est_Estacion_Nombre}
                      onClick={() =>
                        handleButtonEstacionClick(item.est_Estacion_Nombre)
                      }
                      value={item.est_Estacion_Nombre}
                      className="btn btn-outline-info"
                    />
                  );
                })}
              </div>
              <div className="mb-3">
                <div className="row g-1">
                  <div className="col-4">
                    <label className="form-label">Condición debe ser</label>
                  </div>
                  <div className="col-4">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          condition: e.target.value,
                        })
                      }
                      value={formValues.condition}
                    >
                      <option value="<>">{"<>"}</option>
                      <option value="<">{"<"}</option>
                      <option value=">">{">"}</option>
                      <option value="<=">{"<="}</option>
                      <option value=">=">{">="}</option>
                      {/*condicion.map((item, key) => {
                            return (
                              <option value={item.id}>{item.title}</option> <=
                            );
                          })*/}
                    </select>
                  </div>
                  <div className="col-4">
                    <input
                      type="number"
                      value={formValues.valor}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          valor: e.target.value,
                        })
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*steps[currentStep].id === "FIN" && <div></div>*/}
          {currentStep > steps.length - 2 ? (
            <button
              className="btn btn-primary"
              type="button"
              onClick={handlePrev}
            >
              <i className="fa fa-angle-left"></i>
            </button>
          ) : (
            currentStep < steps.length + 1 && (
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleNext}
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            )
          )}

          {!enter ? (
            <div className="d-flex justify-content-center">
              <button disabled className="btn btn-success center" type="submit">
                Generar Reporte
              </button>
            </div>
          ) : (
            enter && (
              <div className="d-flex justify-content-center">
                <button className="btn btn-success" type="submit">
                  Generar Reporte
                </button>
              </div>
            )
          )}
        </div>
      </form>
    </>
  );
};

export default Calculadora;
