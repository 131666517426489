export const SET_FILTRO_REPORTE_SUCCESS = 'SET_FILTRO_REPORTE_SUCCESS'
export const ADD_FILTRO_REPORTE_SUCCESS = 'ADD_FILTRO_REPORTE_SUCCESS'
export const SET_RESULTADO_FORMULARIO = 'SET_RESULTADO_FORMULARIO'
export const SET_VALUE_COMBOBOX = 'SET_VALUE_COMBOBOX'
export const ADD_VALUE_COMBOBOX = 'ADD_VALUE_COMBOBOX'
export const SET_ROMANO_COMBOBOX = 'SET_ROMANO_COMBOBOX'
export const ADD_ROMANO_COMBOBOX = 'ADD_ROMANO_COMBOBOX'
export const SET_VALUE_SUBMENU = 'SET_VALUE_SUBMENU'
export const ADD_VALUE_SUBMENU = 'ADD_VALUE_SUBMENU'
export const SET_VALUE_BUTTON = 'SET_VALUE_BUTTON'
export const ADD_VALUE_BUTTON = 'ADD_VALUE_BUTTON'
export const ADD_VALUE_PAGE_REPORT = 'ADD_VALUE_PAGE_REPORT'
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SET_FILTRO_CARGADOS = 'SET_FILTRO_CARGADOS'
export const SET_EXPORTAR = 'SET_EXPORTAR'
export const SET_PAGE = 'SET_PAGE'
export const ADD_API_VALUE = 'ADD_API_VALUE'
export const ADD_REGION = 'ADD_REGION'
export const ADD_ENTIDAD = 'ADD_ENTIDAD'
export const ADD_ESTACION = 'ADD_ESTACION'
export const ADD_CATEGORIA = 'ADD_CATEGORIA'
export const ADD_DOMINIO = 'ADD_DOMINIO'
export const SET_ES_CALCULADORA = 'SET_ES_CALCULADORA'
export const RANDOM = 'RANDOM'
export const ADD_DATOS_REPORTE_SUCCESS = 'ADD_DATOS_REPORTE_SUCCESS'
export const SET_ES_JSON_API = 'SET_ES_JSON_API'
export const ADD_REPORTE_JSON_API = 'ADD_REPORTE_JSON_API'
export const IS_LOADING = 'IS_LOADING'
export const MENSAJE = 'MENSAJE'
export const IS_RANDOM = 'IS_RANDOM'
export const IS_SCREENSHOOT = 'IS_SCREENSHOOT'
export const SET_PRODUCTOS = 'SET_PRODUCTOS'
export const IS_DESCARGARJSON = 'IS_DESCARGARJSON'
export const SET_REPORTE_VARIABLE = 'SET_REPORTE_VARIABLE'

export const SET_VARIABLE_ENTIDAD = 'SET_VARIABLE_ENTIDAD'
export const SET_ENTIDADES = 'SET_ENTIDADES'

export const APP_STATUS = 'APP_STATUS'
export const APP_PARAMS = 'APP_PARAMS'
