import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Card,
  Badge,
  FormCheck,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap-v5";
import Multiselect from "multiselect-react-dropdown";
import api from "../../services/apiMenuService";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { es } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import {
  addFiltroReportes,
  setExportar,
  setEsJsonApi,
  LimpiaFiltros,
} from "../../appStatus/actions/actionPagina";
import { format, getDate, subDays } from "date-fns";
import "./sidebarLabSitio.css";

const SidebarLabSitio = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ reValidateMode: "onBlur" });

  const dispatch = useDispatch();
  const multiselectRef = useRef();
  const multiselectRef2 = useRef();

  const regionSeleccionada = useSelector((store) => store.pagina.valueCombobox);

  const onSubmit = async (data) => {
    if (data) {
      let [variable] = data.selVariable || [];

      let nombreSerie;
      [nombreSerie] = nombreSerieFinal || [];
      const informacionFiltro = data.selEstacion.map((item) => {
        return item.Estacion_Id;
      });
      Object.assign(data, { Estaciones: informacionFiltro });
      Object.assign(data, { Entidad: data.selEntidad[0].Entidad_Id });
      Object.assign(data, { Estaciones: informacionFiltro });
      // Object.assign(data, { NombreEstacion: nombreEstacion });
      /* Bien chamullado, arreglar */
      if (data.selCalculos === "5" && nombreSerie === "Dias") {
        Object.assign(data, {
          SelSerieClima: "ReportSection499372c740a9a7073aab",
        });
      } else if (data.selCalculos === "5" && nombreSerie === "Mes") {
        Object.assign(data, {
          SelSerieClima: "ReportSectionce9be802b512e57c3b34",
        });
      } else if (data.selCalculos === "5" && nombreSerie === "Año") {
        Object.assign(data, {
          SelSerieClima: "ReportSectionda5dee4cb290820707e1",
        });
      }
      /* Esto es para hacer la diferencia entre estadisticos Promedio y Acumulado */
      if (variable === 6) {
        Object.assign(data, { SelEstadisticasAcum: "Acumulado" });
      } else if (data.selCalculos === "5" || data.selCalculos === "6") {
        Object.assign(data, { SelEstadisticasAcum: "Promedio" });
      }
      /* Esto es para hacer la diferencia entre estadisticos Promedio y Acumulado */
      if (data.selTemporalidad === "mes") {
        let month = format(new Date(), "yyyy-MM-dd");
        let lastMonth = format(subDays(new Date(), 30), "yyyy-MM-dd");
        Object.assign(data, { FechaInicio: lastMonth });
        Object.assign(data, { FechaFin: month });
      } else if (data.selTemporalidad === "anio") {
        let year = format(new Date(), "yyyy-MM-dd");
        let lastYear = format(subDays(new Date(), 365), "yyyy-MM-dd");
        Object.assign(data, { FechaInicio: lastYear });
        Object.assign(data, { FechaFin: year });
      } else if (data.selTemporalidad === "calendario") {
        Object.assign(data, { FechaInicio: data.FechaInicio });
        Object.assign(data, { FechaFin: data.FechaFin });
      }
    }

    dispatch(addFiltroReportes(data));
    //multiselectRef.current.resetSelectedValues();
  };

  const [entidad, setEntidad] = useState([]);
  const [estaciones, setEstaciones] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [variable, setVariable] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const [reporte, setReporte] = useState([]);
  const [reportSectionSensibilidad, setReportSectionSensibilidad] =
    useState(null);
  const [dominioSeleccionado, setDominioSeleccionado] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState();
  const [dominioIdSeleccionado, setDominioIdSeleccionado] = useState();
  const [categoriaIdSeleccionada, setCategoriaIdSeleccionada] = useState();
  const [estacionesFiltrada, setEstacionesFiltrada] = useState([]);
  const [estacionesSeleccionadas, setEstacionesSeleccionadas] = useState();
  const [entidadObtenida, setEntidadObtenida] = useState(null);
  const [entidadPorVariable, setEntidadPorVariable] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const isMobile = window.innerWidth >= 590;
  const [estadisticaCheck, setEstadisticaCheck] =
    useState(true); /* para validar */
  const [calculosAvanzados, setCalculosAvanzados] =
    useState(false); /* para validar */
  const [estadistica, setEstadistica] = useState("Promedio");
  const [ultimoMes, setUltimoMes] = useState(false);
  const [ultimoAnio, setUltimoAnio] = useState(false);
  const [calendario, setCalendario] = useState(false);
  const [serieObtenida, setSerieObtenida] = useState(null);
  const [nombreSerieFinal, setNombreSerieFinal] = useState(null);
  const [serie, setSerie] = useState("ReportSection359540405770c29d970");
  const [calculoAvanzadoSeleccionado, setCalculoAvanzadoSeleccionado] =
    useState(null);
  const [isEstacionesValidas, setIsEstacionesValidas] = useState(true);
  const [nombreBoton, setNombreBoton] = useState(false);
  const [variablesSeleccionadas, setVariablesSeleccionadas] = useState(null);
  const [esCantidadVariable, setEsCantidadVariable] = useState(false);
  const [nombreVariable, setNombreVariable] = useState(null);
  const [checked, setChecked] = React.useState(true);
  const [esExportar, setEsExportar] = useState(false);
  const [sensibilidad, setSensibilidad] = useState(null);
  const [reportesSeleccionado, setReportesSeleccionados] = useState([]);
  const [esNombreSerieValido, setEsNombreSerieValido] = useState(false);

  /* Validar Filtros segun serie temporal */
  const [serieParaCalculosAvanzados, setSerieParaCalculosAvanzados] =
    useState(false);
  const [serieParaEstadisticas, setSerieParaEstadisticas] = useState(false);

  /* Validar Filtros segun serie temporal */

  const [reporteSensibilidad, setReporteSensibilidad] = useState(null);
  const [tokenSensibilidad, setTokenSensibilidad] = useState(null);
  const [tokenSensibilidadFinal, setTokenSensibilidadFinal] = useState(null);

  /* state nuevos para cambios de variable, entidad, estacion */
  const [variableSeleccionada, setVariableSeleccionada] = useState(null);
  const [estacionesNewSeleccionadas, setEstacionesNewSeleccionadas] =
    useState(null);
  const [entidadesObtenidas, setEntidadesObtenidas] = useState(null);
  const [entidadSeleccionada, setEntidadSeleccionada] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const entidades = await api.getAllEntidades();
      setEntidad(entidades);
      const estacion = await api.getAllEstaciones();
      setEstaciones(estacion);
      const vari = await api.getAllVariables();
      setVariable(vari);
      const cat = await api.getAllCategorias();
      setCategoria(cat);
      const sec = await api.getSeccionReportesById(2);
      setSeccion(sec);
      const repo = await api.getAllReportes();
      setReporte(repo);
      const seccionReporteSensibilidad = await api.getSeccionReportesById(6);
      setReportSectionSensibilidad(seccionReporteSensibilidad);
    };
    fetchData();
  }, []);

  /* Parte nueva  */
  /* Obtengo la o las entidades segun las variables seleccionadas ej: si en Variable 1: [Agroclima, DGA, MA] , Variable 2 [DGA] entonces solo muestra DGA */
  useEffect(() => {
    if (variablesSeleccionadas) {
      const fetchData = async () => {
        const obtenerEntidad = await api.getObtenerEntidadVariables({
          Variable:
            variablesSeleccionadas instanceof Array
              ? variablesSeleccionadas
              : [variablesSeleccionadas],
        });
        setEntidadesObtenidas(obtenerEntidad);
      };
      fetchData();
    }
  }, [variablesSeleccionadas]);

  /* Obtengo Estaciones segun la variable y la entidad seleccionada y región */
  useEffect(() => {
    if (
      variablesSeleccionadas?.length > 0 &&
      entidadSeleccionada?.length > 0 &&
      regionSeleccionada
    ) {
      const fetchData = async () => {
        const ObtenerEstacionesPorEntidad =
          await api.getObtenerEstacionesEntidadesVariables({
            Variable: variablesSeleccionadas,
            IdEntidad: Number(entidadSeleccionada[0].Entidad_Id),
            IdRegion: Number(regionSeleccionada),
          });

        setEstacionesNewSeleccionadas(ObtenerEstacionesPorEntidad);
      };
      fetchData();
    }
  }, [entidadSeleccionada, variablesSeleccionadas, regionSeleccionada]);

  /* selecciono la o las variables ademas obtengo categoria y dominio que viene del mismo json */
  const onSelectVariables = (selectedList) => {
    let ObtenerVariablesSeleccionadas = selectedList.map(
      (item) => item.IdVariable
    );
    setValue("selVariable", ObtenerVariablesSeleccionadas);
    setVariableSeleccionada(ObtenerVariablesSeleccionadas);
    setVariablesSeleccionadas(ObtenerVariablesSeleccionadas);
    setNombreVariable(selectedList);
    let ObtenerCategoria = selectedList.map((c) => c.NombreCategoria);
    let obtenerIdCategoria = selectedList.map((c) => c.IdCategoria);
    setCategoriaIdSeleccionada(obtenerIdCategoria);
    setValue("categoria", obtenerIdCategoria);
    let cad = "";
    for (let item of ObtenerCategoria) {
      if (cad !== "") cad += ", ";
      cad += item;
    }
    setCategoriaSeleccionada(cad);
    let ObtenerDominio = selectedList.map((c) => c.NombreDominio);
    let obtenerIdDominio = selectedList.map((c) => c.IdDominio);
    let dom = "";
    for (let item of ObtenerDominio) {
      if (dom !== "") dom += ", ";
      dom += item;
    }
    setDominioSeleccionado(dom);
    setDominioIdSeleccionado(obtenerIdDominio);
    setValue("dominio", obtenerIdDominio);
  };

  const resetSelectField = () => {
    multiselectRef.current.resetSelectedValues();
    multiselectRef2.current.resetSelectedValues();
  };
  /* Quito la o las variables ademas obtengo categoria y dominio que viene del mismo json */
  const onRemoveEntidad = (selectedList) => {
    let removerEntidad = selectedList.map((item) => item.Entidad_Id);
    setEntidadSeleccionada(removerEntidad);
    multiselectRef2.current.resetSelectedValues();
  };

  const onRemoveVariables = (selectedList) => {
    let ObtenerVariablesSeleccionadas = selectedList.map(
      (item) => item.IdVariable
    );

    setVariablesSeleccionadas(ObtenerVariablesSeleccionadas);
    setValue("selVariable", ObtenerVariablesSeleccionadas);
    setEsCantidadVariable(false);
    let ObtenerCategoria = selectedList.map((c) => c.NombreCategoria);
    let cad = "";
    for (let item of ObtenerCategoria) {
      if (cad !== "") cad += ", ";
      cad += item;
    }
    setCategoriaSeleccionada(cad);
    let ObtenerDominio = selectedList.map((c) => c.NombreDominio);
    let obtenerIdDominio = selectedList.map((c) => c.IdDominio);
    let dom = "";
    for (let item of ObtenerDominio) {
      if (dom !== "") dom += ", ";
      dom += item;
    }
    setDominioSeleccionado(dom);
    setDominioIdSeleccionado(obtenerIdDominio);
    setValue("dominio", obtenerIdDominio);
    resetSelectField();
  };
  /* Parte nueva  */

  useEffect(() => {
    if (variablesSeleccionadas) {
      const variables = variablesSeleccionadas.length;

      if (variables === 2) {
        setEsCantidadVariable(true);
      } else if (variables === 1) {
        setEsCantidadVariable(false);
      }
    }
  }, [variablesSeleccionadas, variable]);

  useEffect(() => {
    let estacionesSinNullRegion = estaciones.filter(
      (e) => e.R_Region_Id !== null
    );
    let estacionesSelected = estacionesSinNullRegion.filter(
      (e) => e.R_Region_Id.toString() === regionSeleccionada
    );

    let estacionSegunEntidad = estacionesSelected.filter(
      (e) => e.E_Entidad_Id === Number(entidadObtenida)
    );
    setEstacionesFiltrada(estacionSegunEntidad);
  }, [estaciones, regionSeleccionada, entidadObtenida]);

  useEffect(() => {
    if (
      entidadObtenida === null ||
      (entidadObtenida && estacionesFiltrada.length > 0)
    ) {
      setIsEstacionesValidas(true);
    } else {
      setIsEstacionesValidas(false);
    }
  }, [entidadObtenida, estacionesFiltrada]);

  /*useEffect(() => {
    if (regionSeleccionada !== null) {
      const mapEstaciones = estacionesFiltrada.map((item) => item);
      setEstacionesSeleccionadas(mapEstaciones);
    }
  }, [estacionesFiltrada, regionSeleccionada]);*/

  /*useEffect(() => {
    if (entidadObtenida === "Seleccione" || entidadObtenida === null) {
    } else {
      let entidadSelected = entidad.filter(
        (v) => v.IdEntidad === Number(entidadObtenida)
      );
      setEntidadPorVariable(entidadSelected[0].Variable);
    }
  }, [entidadObtenida, entidad]);*/

  useEffect(() => {
    if (isMobile) {
      setIsFilterOpen(true);
    } else {
      setIsFilterOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (reporte) {
      const reportesSeleccionados = reporte.filter(
        (e) => e.FlagReporte === false
      );
      setReportesSeleccionados(reportesSeleccionados);
    }
  }, [reporte]);

  useEffect(() => {
    if (serieObtenida) {
      let serieExtraida = serieObtenida.substring(13);
      let obtenernombreSerie = seccion.filter((s) => {
        return s.TokenSeccion === serieExtraida.toString();
      });
      const nombreSerieFinal = obtenernombreSerie.map(
        (item) => item.NombreSeccion
      );
      setNombreSerieFinal(nombreSerieFinal);
    }
  }, [serieObtenida, seccion]);

  useEffect(() => {
    let nombreSerie;
    [nombreSerie] = nombreSerieFinal || [];
    if (
      (estadisticaCheck && nombreSerie === undefined) ||
      (estadisticaCheck && nombreSerie === "Original") ||
      (estadisticaCheck && nombreSerie === "Hora")
    ) {
      setSerieParaEstadisticas(false);
    } else {
      setSerieParaEstadisticas(true);
    }
    if (
      (calculosAvanzados && nombreSerie === undefined) ||
      (calculosAvanzados && nombreSerie === "Original") ||
      (calculosAvanzados && nombreSerie === "Hora")
    ) {
      setSerieParaCalculosAvanzados(false);
    } else {
      setSerieParaCalculosAvanzados(true);
    }
  }, [nombreSerieFinal, calculosAvanzados, estadisticaCheck]);

  useEffect(() => {
    if (reportSectionSensibilidad) {
      let sensibilidad = reportSectionSensibilidad.filter(
        (v) => v.IdReporte === 6
      );
      setReporteSensibilidad(sensibilidad);
    }
  }, [reportSectionSensibilidad]);

  useEffect(() => {
    if (tokenSensibilidad) {
      let serieExtraida = tokenSensibilidad.substring(13);
      let obtenerTokenSensibilidad = reportSectionSensibilidad.filter((s) => {
        return s.TokenSeccion === serieExtraida.toString();
      });
      /*onst tokenSensibilidadFinal = obtenerTokenSensibilidad.map(
        (item) => item.NombreSeccion
      );*/
      setTokenSensibilidadFinal(obtenerTokenSensibilidad);
    }
  }, [tokenSensibilidad, reportSectionSensibilidad]);

  const changeEntidad = (field, value) => {
    field.onChange(value);
    setEntidadSeleccionada(value);
    setEntidadObtenida(value);
  };
  const changeSerie = (field, value) => {
    field.onChange(value);
    setSerieObtenida(value);
    setValue("nombreSerieTemporal", {
      nombreSerieFinal,
    });
  };
  const changeEstadisticas = (field, value) => {
    field.onChange(value);
  };

  const changeEstadisticas2 = (field, value) => {
    field.onChange(value);
  };

  const changeCalculos = (field, value) => {
    field.onChange(value);
    setCalculoAvanzadoSeleccionado(value);
  };

  /*const onChangeDatos = (value) => {
    if (value === "reporte") {
      setChecked(true);
      setEsExportar(false);
    } else if (value === "exportar") {
      setChecked(false);
      setEsExportar(true);
    }
  };*/

  const onChangeSensibilidad = (field, value) => {
    field.onChange(value);
    setTokenSensibilidad(value);
    /*if (value === "0") {
      dispatch(LimpiaFiltros());
      setSensibilidad(0);
    }*/
  };

  /*const ValidarFormulario = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };*/

  const ValidarFormulario = (e) => {
    e.preventDefault();
    //clearErrors();
    if (e.target.name === "grafico") {
      setNombreBoton(false);
      handleSubmit(onSubmit)();
      dispatch(setEsJsonApi(false));
      dispatch(setExportar(false));
      dispatch(LimpiaFiltros());
    } else if (e.target.name === "api") {
      setNombreBoton(true);
      handleSubmit(onSubmit)();
      dispatch(setExportar(true));
      dispatch(setEsJsonApi(true));
      dispatch(LimpiaFiltros());
    }
  };

  const Exportar = (e) => {
    e.preventDefault();
    dispatch(setExportar(true));
  };

  const handleOnChangeDatos = (value) => {
    if (value === "estadisticaSel") {
      //serieTemporalNormal();
      setEstadisticaCheck(true);
      setCalculosAvanzados(false);
      dispatch(LimpiaFiltros());
    } else if (value === "calculosAvanzadosSel") {
      //serieTemporalDias();
      setEstadisticaCheck(false);
      setCalculosAvanzados(true);
      dispatch(LimpiaFiltros());
    }
  };
  const onChangeFechaInicio = (value) => {
    setStartDate(value);
    setValue("FechaInicio", startDate ? format(startDate, "yyyy-MM-dd") : null);
  };

  const onChangeFechaFin = (value) => {
    setEndDate(value);
    setValue("FechaFin", endDate ? format(endDate, "yyyy-MM-dd") : null);
    setValue("selFecha", true);
  };

  const handleOnChangeTemporalidad = (value) => {
    if (value === "mes") {
      setUltimoMes(true);
      setUltimoAnio(false);
      setCalendario(false);
    } else if (value === "anio") {
      setUltimoAnio(true);
      setUltimoMes(false);
      setCalendario(false);
    } else if (value === "calendario") {
      setCalendario(true);
      setUltimoMes(false);
      setUltimoAnio(false);
    }
  };

  return (
    <nav className="sidebar-root">
      <header className="logo">
        <span className="title">Filtros</span>
      </header>
      <Accordion>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Filtro Variable */}
          <Card>
            {errors.selVariable && errors.selVariable.type === "required" ? (
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="valid-message"
              >
                Filtro Variable es Obligatorio
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle as={Card.Header} eventKey="3">
                Filtro Variable
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div className="mb-3">
                  {setValue("dominio", { dominioIdSeleccionado })}
                  <Badge pill bg="primary">
                    {dominioSeleccionado}
                  </Badge>{" "}
                  <Badge pill bg="success">
                    {categoriaSeleccionada}
                  </Badge>{" "}
                </div>
                <div className="mb-3">
                  <label className="form-label">Seleccione Variable</label>
                  <Controller
                    control={control}
                    name="selVariable"
                    render={({ field }) => (
                      <Multiselect
                        displayValue="NombreVariable"
                        hidePlaceholder
                        groupBy="NombreCategoria"
                        onSelect={onSelectVariables}
                        onRemove={onRemoveVariables}
                        selectionLimit="2"
                        onSearch={(selected, item) => {}}
                        placeholder="Seleccione las opciones"
                        options={variable ?? []}
                        isSearchable={true}
                        emptyRecordMsg={"No se encontraron opciones."}
                        //ref={multiselectRef}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  {errors.selVariable &&
                    errors.selVariable.type === "required" && (
                      <span className="valid-message">
                        Campo variable es obligatorio
                      </span>
                    )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* Filtro Estacion*/}
          <Card>
            {(errors.selEntidad && errors.selEntidad.type === "required") ||
            (errors.selEstacion && errors.selEstacion.type === "required") ? (
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="valid-message"
              >
                Filtro Estación es Obligatorio
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle as={Card.Header} eventKey="0">
                Filtro Estación
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="mb-3">
                  <label className="form-label">Seleccione Entidad</label>
                  <Controller
                    control={control}
                    name="selEntidad"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Multiselect
                          displayValue="Entidad_Nombre"
                          hidePlaceholder={true}
                          selectionLimit="1"
                          onSelect={(val) => {
                            setEntidadSeleccionada(val);
                            onChange(val);
                          }}
                          onRemove={onRemoveEntidad}
                          selectedValues={value}
                          placeholder="Seleccione una opción"
                          options={entidadesObtenidas ?? []}
                          loading={entidadesObtenidas ? false : true}
                          loadingMessage={"Cargando..."}
                          emptyRecordMsg={"No se encontraron opciones."}
                          ref={multiselectRef}
                        />
                      );
                    }}
                    rules={{ required: "Campo entidad es obligatorio." }}
                  />
                  {errors.selEntidad &&
                    errors.selEntidad.type === "required" && (
                      <span className="valid-message">
                        Campo entidad es obligatorio
                      </span>
                    )}
                </div>
                {/*isEstacionesValidas && (*/}
                <div className="mb-3">
                  <label className="form-label">Seleccione Estación</label>
                  <Controller
                    control={control}
                    name="selEstacion"
                    render={({ field }) => {
                      return (
                        <Multiselect
                          displayValue="e_Estacion_Nombre"
                          hidePlaceholder={true}
                          selectionLimit="1"
                          onSelect={(selected, item) => {
                            setValue("selEstacion", selected);
                          }}
                          onRemove={(selected, item) => {
                            setValue("selEstacion", selected);
                          }}
                          placeholder="Seleccione una opción"
                          options={estacionesNewSeleccionadas ?? []}
                          loading={estacionesNewSeleccionadas ? false : true}
                          loadingMessage={"Cargando..."}
                          emptyRecordMsg={"No se encontraron opciones."}
                          ref={multiselectRef2}
                        />
                      );
                    }}
                    rules={{ required: "Campo estación es obligatorio" }}
                  />
                  {errors.selEstacion &&
                    errors.selEstacion.type === "required" && (
                      <span className="valid-message">
                        Campo estación es obligatorio
                      </span>
                    )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* Filtro Temporalidad*/}
          <Card>
            {(errors.selFecha && errors.selFecha.type === "required") ||
            (errors.selTemporalidad &&
              errors.selTemporalidad.type === "required") ? (
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="valid-message"
              >
                Filtro Temporalidad es Obligatorio
              </Accordion.Toggle>
            ) : (
              <Accordion.Toggle as={Card.Header} eventKey="1">
                Filtro Temporalidad
              </Accordion.Toggle>
            )}
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="mb-3">
                  <label className="form-label">Seleccione una opción</label>
                  <FormCheck
                    {...register("selTemporalidad", { required: true })}
                    type="radio"
                    label="Último Mes"
                    value="mes"
                    name="selTemporalidad"
                    onChange={(e) => handleOnChangeTemporalidad(e.target.value)}
                  />
                  <FormCheck
                    {...register("selTemporalidad", { required: true })}
                    type="radio"
                    label="Último Año"
                    value="anio"
                    name="selTemporalidad"
                    onChange={(e) => handleOnChangeTemporalidad(e.target.value)}
                  />
                  <FormCheck
                    {...register("selTemporalidad", { required: true })}
                    type="radio"
                    label="Calendario"
                    value="calendario"
                    name="selTemporalidad"
                    onChange={(e) => handleOnChangeTemporalidad(e.target.value)}
                  />
                </div>
                {errors.selTemporalidad &&
                  errors.selTemporalidad.type === "required" && (
                    <span className="valid-message">
                      Campo selección es obligatorio
                    </span>
                  )}
                <div className="mb-3">
                  {calendario && (
                    <>
                      {setValue(
                        "FechaInicio",
                        startDate ? format(startDate, "yyyy-MM-dd") : null
                      )}
                      {setValue(
                        "FechaFin",
                        endDate ? format(endDate, "yyyy-MM-dd") : null
                      )}
                      <label className="form-label">Seleccione Fecha</label>
                      <Controller
                        control={control}
                        name="selFecha"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onStartDateChange={(value) =>
                              onChangeFechaInicio(value)
                            }
                            onEndDateChange={(value) => onChangeFechaFin(value)}
                            format="dd/MM/yyyy"
                            locale={es}
                          >
                            {({
                              startDateInputProps,
                              endDateInputProps,
                              focus,
                            }) => (
                              <div className="date-range">
                                <input
                                  id="StartDate"
                                  autoComplete="off"
                                  required
                                  className={
                                    "inputs" +
                                    (focus === START_DATE ? " -focused" : "")
                                  }
                                  {...startDateInputProps}
                                  placeholder="Inicio"
                                />
                                <span className="date-range_arrow" />
                                <input
                                  id="EndDate"
                                  autoComplete="off"
                                  required
                                  className={
                                    "inputs" +
                                    (focus === END_DATE ? " -focused" : "")
                                  }
                                  {...endDateInputProps}
                                  placeholder="Fin"
                                />
                              </div>
                            )}
                          </DateRangePicker>
                        )}
                      />
                    </>
                  )}
                  {errors.selFecha && errors.selFecha.type === "required" && (
                    <span className="valid-message">
                      Campo fecha inicio y fecha fin es obligatorio
                    </span>
                  )}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Filtro Serie Temporal
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className="mb-3">
                  <label className="form-label">
                    Seleccione Serie Temporal
                  </label>
                  <Controller
                    control={control}
                    defaultValue={serie}
                    name="selSerie"
                    render={({ field }) => (
                      <select
                        className="form-control"
                        onChange={(e) => changeSerie(field, e.target.value)}
                        onBlur={field.onBlur}
                      >
                        {/*<option value="0">Seleccione una opción </option>*/}
                        {seccion.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={"ReportSection" + item.TokenSeccion}
                            >
                              {item.NombreSeccion}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                  {errors.selSerie && errors.selSerie.type === "required" && (
                    <span className="valid-message">
                      Campo serie temporal es obligatorio
                    </span>
                  )}
                  {setValue("nombreSerieTemporal", {
                    nombreSerieFinal,
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* Filtro Tratamiento de Datos*/}
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              Filtro Tratamiento de Datos
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="4">
              <Card.Body>
                {variablesSeleccionadas && variablesSeleccionadas.length < 2 ? (
                  <div className="mb-3">
                    <FormCheck
                      {...register("selCalculo", { required: true })}
                      type="radio"
                      label="Estadísticas"
                      defaultChecked
                      value="estadisticaSel"
                      name="selCalculo"
                      onChange={(e) => handleOnChangeDatos(e.target.value)}
                    />
                    <FormCheck
                      {...register("selCalculo", { required: true })}
                      type="radio"
                      label="Cálculos Avanzados"
                      value="calculosAvanzadosSel"
                      name="selCalculo"
                      onChange={(e) => handleOnChangeDatos(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <FormCheck
                      {...register("selCalculo", { required: true })}
                      type="radio"
                      label="Estadísticas"
                      defaultChecked
                      value="estadisticaSel"
                      name="selCalculo"
                      onChange={(e) => handleOnChangeDatos(e.target.value)}
                    />
                    <div className="row g-1">
                      <div className="col-md-8">
                        <FormCheck
                          {...register("selCalculo", { required: true })}
                          type="radio"
                          label="Cálculos Avanzados"
                          value="calculosAvanzadosSel"
                          name="selCalculo"
                          disabled
                          onChange={(e) => handleOnChangeDatos(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              {" "}
                              Recuerda que para visualizar los reportes de{" "}
                              <strong>Cálculos Avanzados</strong>, es necesario
                              solo una <strong>Variable</strong> o serie
                              temporalidad distinta a <strong>Original</strong>{" "}
                              y <strong>Hora</strong>
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <i
                              class="fa fa-question-circle fa-2x"
                              style={{ pointerEvents: "none", color: "red" }}
                            ></i>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                )}
                {estadisticaCheck ? (
                  <Alert variant="warning">
                    Recuerda que para visualizar los reportes de{" "}
                    <strong>Estadísticas</strong>, es necesario una serie
                    temporalidad distinta a <strong>Original</strong> y{" "}
                    <strong>Hora</strong>
                  </Alert>
                ) : (
                  <Alert variant="warning">
                    Recuerda que para visualizar los reportes de{" "}
                    <strong>Calculos Avanzados</strong>, es necesario solo una{" "}
                    <strong>Variable</strong> o serie temporalidad distinta a{" "}
                    <strong>Original</strong>y <strong>Hora</strong>{" "}
                  </Alert>
                )}
                {estadisticaCheck && (
                  <>
                    {esCantidadVariable ? (
                      <div className="mb-3">
                        <div className="row g-1">
                          <div className="col-md-6">
                            <label className="form-label">Variables</label>

                            {nombreVariable.map((item, key) => {
                              return (
                                <span
                                  className="span-variables"
                                  key={item.IdVariable}
                                  value={item.IdVariable}
                                >
                                  {item.NombreVariable}
                                </span>
                              );
                            })}
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">
                              Estad&iacute;sticas
                            </label>
                            <Controller
                              control={control}
                              defaultValue={estadistica}
                              name="selEstadisticas"
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    changeEstadisticas(field, e.target.value)
                                  }
                                >
                                  <option value="Promedio">Promedio</option>
                                  <option value="Max">Max</option>
                                  <option value="Min">Min</option>
                                  <option value="Acumulado">Acumulado</option>
                                </select>
                              )}
                            />
                            <Controller
                              control={control}
                              defaultValue={estadistica}
                              name="selEstadisticas2"
                              render={({ field }) => (
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    changeEstadisticas2(field, e.target.value)
                                  }
                                >
                                  <option value="Promedio">Promedio</option>
                                  <option value="Max">Max</option>
                                  <option value="Min">Min</option>
                                  <option value="Acumulado">Acumulado</option>
                                </select>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-3">
                        <label className="form-label">
                          Seleccione Estad&iacute;sticas
                        </label>
                        <Controller
                          control={control}
                          defaultValue={estadistica}
                          name="selEstadisticas"
                          render={({ field }) => (
                            <select
                              className="form-control"
                              onChange={(e) =>
                                changeEstadisticas(field, e.target.value)
                              }
                            >
                              <option value="Promedio">Promedio</option>
                              <option value="Max">Max</option>
                              <option value="Min">Min</option>
                              <option value="Acumulado">Acumulado</option>
                            </select>
                          )}
                        />
                      </div>
                    )}
                  </>
                )}
                {calculosAvanzados && variablesSeleccionadas.length < 2 && (
                  <>
                    {/*!esCantidadVariable ? (
                      //&& !esNombreSerieValido*/}
                    <div className="mb-3">
                      <label className="form-label">
                        C&aacute;lculos Avanzados
                      </label>
                      <Controller
                        control={control}
                        name="selCalculos"
                        render={({ field }) => (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              changeCalculos(field, e.target.value)
                            }
                          >
                            <option value="0">
                              Seleccione una opci&oacute;n
                            </option>
                            {reportesSeleccionado.map((item, key) => {
                              return (
                                <option key={key} value={item.IdReporte}>
                                  {item.NombreReporte}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        rules={{ required: true }}
                      />
                      {errors.selCalculos &&
                        errors.selCalculos === "0" &&
                        errors.selCalculos.type === "required" && (
                          <span className="valid-message">
                            Campo cálculos avanzados es obligatorio
                          </span>
                        )}
                    </div>
                  </>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {calculosAvanzados &&
            variablesSeleccionadas.length < 2 &&
            //!esCantidadVariable &&
            //!esNombreSerieValido &&
            calculoAvanzadoSeleccionado === "6" && (
              <Card>
                {errors?.selSensibilidad ? (
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="5"
                    className="valid-message"
                  >
                    Filtro Sensibilidad Anomalías es Obligatorio
                    {errors.selSensibilidad.message}
                  </Accordion.Toggle>
                ) : (
                  <Accordion.Toggle as={Card.Header} eventKey="5">
                    Filtro Sensibilidad Anomalías
                  </Accordion.Toggle>
                )}
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className="mb-3">
                      <label className="form-label">
                        Seleccione Sensibilidad
                      </label>
                      <Controller
                        control={control}
                        name="selSensibilidad"
                        render={({ field }) => (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              onChangeSensibilidad(field, e.target.value)
                            }
                          >
                            <option value="">Seleccione una opción</option>
                            {reporteSensibilidad.map((item, key) => {
                              return (
                                <>
                                  <option
                                    value={"ReportSection" + item.TokenSeccion}
                                  >
                                    {item.NombreSeccion}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        )}
                        rules={{ required: true }}
                      />
                      {errors?.selSensibilidad &&
                        errors.selSensibilidad.type === "required" && (
                          <span className="valid-message">
                            Filtro Sensibilidad Anomalías es Obligatorio
                          </span>
                        )}
                      {setValue("reportSensibilidad", {
                        tokenSensibilidadFinal,
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          <Card>
            <div className="btn-group" role="group">
              {!serieParaEstadisticas || !serieParaCalculosAvanzados ? (
                <button
                  disabled
                  name="grafico"
                  onClick={(e) => ValidarFormulario(e)}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  Reportes
                </button>
              ) : (
                <button
                  name="grafico"
                  onClick={(e) => ValidarFormulario(e)}
                  type="button"
                  className="btn btn-outline-primary"
                >
                  Reportes
                </button>
              )}

              <button
                name="api"
                onClick={(e) => ValidarFormulario(e)}
                type="button"
                className="btn btn-outline-primary"
              >
                Exportar
              </button>
            </div>
          </Card>
        </form>
      </Accordion>
    </nav>
  );
};

export default SidebarLabSitio;
