import * as actionTypes from "../constants/modalInformacionActionTypes";

const initialState = {
  mensaje: null
};


const setMensaje = (state, { payload }) => {
  return {
    mensaje: payload
  }
}

const modalInformacionReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SET_MENSAJE:
      return setMensaje(state, action);
    default:
      return state;
  }
};

export default modalInformacionReducer;
