import React, { useState, useEffect, createRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PowerBi from "../PowerBi";
import SidebarLabMultiSitio from "./sidebarLabMultiSitio";
import HeaderFiltro from "../Header-Filtro";
import {
  LimpiaFiltros,
  setIsLoading,
} from "../../appStatus/actions/actionPagina";
import { models } from "powerbi-client";
import "../../sass/test.css";

import ListadoEstadisticas from "../TablaExportar/listadoEstadisticas";
import ListadoCalculoAvanzadoClima from "../TablaExportar/listadoCalculoAvanzadoClima";
import ListadoCalculoAvanzadoAnomalias from "../TablaExportar/listadoCalculoAvanzadoAnomalias";

const LabMultiSitioSection = () => {
  const sidebarOpened = useSelector((store) => store.pagina.sidebarOpened);
  const filtroCargado = useSelector((store) => store.pagina.filtroCargado);
  const exportarArchivo = useSelector((store) => store.pagina.exportarArchivo);
  const esCalculadora = useSelector((store) => store.pagina.esCalculadora);
  const formFiltroReporte = useSelector((store) => store.pagina.formularioFiltroReporte);
  const reporteCalculadora = useSelector((store) => store.pagina.reporte);
  const esJsonApi = useSelector((store) => store.pagina.esJsonApi);
  const addReporteJsonApi = useSelector(
    (store) => store.pagina.addReporteJsonApi
  );
  const screenshot = useSelector((store) => store.pagina.isScreenShoot);
  //const isLoading = useSelector((store) => store.pagina.isLoading);

  const dispatch = useDispatch();
  const {
    selEntidad,
    selVariable,
    selEstadisticas,
    categoria,
    dominio,
    FechaInicio,
    FechaFin,
    selCalculos,
    selTemporalidad,
    Estaciones,
    selCalculo,
    nombreSerieTemporal,
    SelSerieClima,
    SelEstadisticasAcum,
  } = formFiltroReporte || [];

  const valueCombobox = useSelector((store) => store.pagina.valueCombobox);

  const { dominioIdSeleccionado } = dominio || [];
  const { categoriaIdSeleccionada } = categoria || [];

  const { nombreSerieFinal } = nombreSerieTemporal || [];

  let nombreSerie;
  [nombreSerie] = nombreSerieFinal || [];
  if (nombreSerie === undefined || nombreSerie === null) {
    nombreSerie = "Original";
  }

  const [isMenuShow, setIsMenuShow] = useState(false);
  const [calculadoraRep, setCalculadoraRep] = useState(null);
  const [tokenReporte, setTokenReporte] = useState(null);
  const [valorTablaCalendario, setvalorTablaCalendario] = useState(null);
  const [valorColumnaCalendario, setValorColumnaCalendario] = useState(null);
  const [seleccionTabla, setSeleccionTabla] = useState(null);

  const [valorEstadisticaCalculosAvanzados, setValorEstadisticaCalculosAvanzados] = useState(null)

  /*useEffect(() => {
    if (reporteCalculadora) {
      console.log(reporteCalculadora);
      //dispatch(setIsLoading(false));
      /* Solo para prueba, luego tengo que hacer el cambio */
      /*let reportCalculadora = reporteCalculadora[0].Reporte_Calculadora_Id;
      setTokenReporte(reportCalculadora);
    }
    }, [reporteCalculadora])*/
  
  useEffect(() => {
    if (selCalculos && selCalculo === "calculosAvanzadosSel") {
      setTokenReporte(selCalculos);
    } else if (selCalculo === "estadisticaSel") {
      if (
        nombreSerie === "Dias" ||
        nombreSerie === "Mes" ||
        nombreSerie === "Año"
      ) {
        setTokenReporte("3");
      } else if (nombreSerie === "Original" || nombreSerie === "Hora") {
        setTokenReporte("20");
      } 
    }
  }, [selCalculos, selCalculo, nombreSerie]);

  useEffect(() => {
    if (sidebarOpened) {
      setIsMenuShow(true);
    } else {
      setIsMenuShow(false);
    }
  }, [sidebarOpened]);

  useEffect(() => {
    dispatch(LimpiaFiltros());
  }, []);

  useEffect(() => {
    let [ variable ] = selVariable || []
    if (selCalculo === "calculosAvanzadosSel") {

      if (selCalculos === "5") {
        setSeleccionTabla("Clima");
        if(variable === 6){
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum)
        }else{
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum)
        }
      } else if (selCalculos === "6") {
        setSeleccionTabla("Anomalias");
        if(variable === 6){
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum)
        }else {
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum)
        }

      } /*else {
        setvalorTablaCalendario("CALENDARIO");
        setValorColumnaCalendario("FechaHora");
      }*/
    } if (selCalculo === "estadisticaSel") {
      setSeleccionTabla("Estadistica");
      setValorEstadisticaCalculosAvanzados(selEstadisticas)
    }

  }, [selCalculos, selCalculo, SelEstadisticasAcum, selEstadisticas, selVariable]);

  useEffect(() => {
    if (selCalculos && selCalculo === "calculosAvanzadosSel") {
      setTokenReporte(selCalculos);
    } else if (selCalculo === "estadisticaSel") {
      if (
        nombreSerie === "Dias" ||
        nombreSerie === "Mes" ||
        nombreSerie === "Año"
      ) {
        setTokenReporte("3");
      } else if (nombreSerie === "Original" || nombreSerie === "Hora") {
        setTokenReporte("20");
      }
      // setTokenReporte("d6afea1c-9153-43aa-b2fd-f47f02553a9f");
    }else if(esCalculadora){
      console.log(tokenReporte);
      setTokenReporte(tokenReporte)
    }
  }, [selCalculos, selCalculo, nombreSerie, esCalculadora, tokenReporte]);
  //let id = "d6afea1c-9153-43aa-b2fd-f47f02553a9f";

  const filterEstacion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_ESTACIONES_MONITOREO",
      column: "Estacion_Id",
    },
    operator: "In",
    values: Estaciones,
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterRegion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_REGION",
      column: "Region_id",
    },
    operator: "In",
    values: [parseInt(valueCombobox)],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterCarga = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "carga_datos",
      column: "carga",
    },
    operator: "In",
    values: [1],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterVariable = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_VARIABLE",
      column: "Variable_Id",
    },
    operator: "In",
    values: [parseInt(selVariable)],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterCategoria = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_CATEGORIA_VARIABLE",
      column: "Categoria_Variable_Id",
    },
    operator: "In",
    values: [categoriaIdSeleccionada],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterDominio = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_DOMINIO_VARIABLE",
      column: "Dominio_Variable_Id",
    },
    operator: "In",
    values: [dominioIdSeleccionado],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterEntidad = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_ENTIDAD",
      column: "Entidad_Id",
    },
    operator: "In",
    values: selEntidad,
    filterType: 1,
    requireSingleSelection: false,
  };
  const filterEstadistica = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CTR_PLATAFORMA ACCIONES_OBJETO",
      column: "Accion_Objeto_Nombre",
    },
    operator: "In",
    values: [valorEstadisticaCalculosAvanzados],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filtroCalendar = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "CTR_PLATAFORMA DT_FECHA_HORAS",
      column: "Fecha_Calendario_Dt",
    },
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: FechaInicio + "T04:00:00Z",
      },
      {
        operator: "LessThanOrEqual",
        value: FechaFin + "T04:00:00Z",
      },
    ],
  };
  const filtroUltimoMes = {
    $schema: "http://powerbi.com/product/schema#relativeDate",
    target: {
      table: "CTR_PLATAFORMA DT_FECHA_HORAS",
      column: "Fecha_Calendario_Dt",
    },
    operator: models.RelativeDateOperators.InLast,
    timeUnitsCount: 30,
    timeUnitType: models.RelativeDateFilterTimeUnit.Days,
    includeToday: false,
    filterType: models.FilterType.RelativeDate,
  };
  const filtroUltimoAnio = {
    $schema: "http://powerbi.com/product/schema#relativeDate",
    target: {
      table: "CTR_PLATAFORMA DT_FECHA_HORAS",
      column: "Fecha_Calendario_Dt",
    },
    operator: models.RelativeDateOperators.InLast,
    timeUnitsCount: 360,
    timeUnitType: models.RelativeDateFilterTimeUnit.Days,
    includeToday: false,
    filterType: models.FilterType.RelativeDate,
  };

  useEffect(() => {
    if (selTemporalidad === "mes") {
      filtrosReporteMultiSitio.push(filtroUltimoMes);
    } else if (selTemporalidad === "anio") {
      filtrosReporteMultiSitio.push(filtroUltimoAnio);
    } else if (selTemporalidad === "calendario") {
      filtrosReporteMultiSitio.push(filtroCalendar);
    }
  }, [selTemporalidad, filtroUltimoMes, filtroUltimoAnio, filtroCalendar]);

  const filtrosReporteMultiSitio = [
    filterRegion,
    filterCarga,
    filterCategoria,
    filterEntidad,
    filterVariable,
    filterDominio,
    filterEstadistica,
    filterEstacion,
  ];



  return (
    <div className="labSitio">
      <div className={`wrap ${isMenuShow ? "menu-filtro" : ""}`}>
        <HeaderFiltro />
        <SidebarLabMultiSitio />
        <main className="content">
          {!filtroCargado &&
            //!isLoading &&
            !exportarArchivo && //valida si no se esta en la seccion de exportar
            !reporteCalculadora && ( // no se esta cargando una calculadora
              <div className="message-lab">
                <i className="fa fa-hand-o-left fa-3x"></i>&nbsp;&nbsp;
                <p className="mensaje">
                  Bienvenidos a la página de reportes Multisitio, para ver los
                  reportes es necesario seleccionar filtros
                </p>
              </div>
            )}

          { esCalculadora && reporteCalculadora && (
            <PowerBi
              reportId={reporteCalculadora[0]?.Reporte_Calculadora_Id}
              requiereFiltros={false}
              calculadora={true}
              esTemporal = {true}
            />
          )}
          {filtroCargado && !esCalculadora && !esJsonApi && (
            <PowerBi
              reportId={tokenReporte}
              requiereFiltros={true}
              filtrosReporte={filtrosReporteMultiSitio}
              calculadora={false}
            />
          )}
          {filtroCargado && esJsonApi && seleccionTabla === "Estadistica" && (
            <ListadoEstadisticas />
            // <ListadoFiltroTest />
          )}
          {filtroCargado && esJsonApi && seleccionTabla === "Clima" && (
            <ListadoCalculoAvanzadoClima />
          )}
          {filtroCargado && esJsonApi && seleccionTabla === "Anomalias" && (
            <ListadoCalculoAvanzadoAnomalias />
          )}
        </main>
      </div>
    </div>
  );
};

export default LabMultiSitioSection;
