import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import PowerBi from "../../PowerBi";
import './index.scss';

const ModalReportes = ({ mostrarModal, reportesModal, ocultarModal }) => {

  const [valorReporte, setValorReporte] = useState(null);
  const [isValorReporte, setIsValorReporte] = useState(false);

  useEffect(() => {
    if (valorReporte === null) {
      setIsValorReporte(false);
    }else{
        setIsValorReporte(true)
    }
  }, [valorReporte]);

  const handleClick = (IdReporte) => {
    setValorReporte(IdReporte.toString());
  };

  return (
    <div>
      <Modal className="modalFullScreen" show={mostrarModal} fullscreen onHide={ocultarModal}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Listado de Reportes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportesModal.map((item, key) => {
            return (
              <input
                type="button"
                className="btn btn-outline-info"
                key={item.IdReporte}
                value={item.NombreReporte}
                onClick={() => handleClick(item.IdReporte)}
              />
            );
          })}
          {isValorReporte ? (
            <PowerBi reportId={valorReporte} requiereFiltros={false} />
          ) : (
            <p>
              Para ver un reporte es necesario dar click a los botones que estan
              arriba
            </p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalReportes;
