import React, { useState } from 'react';

import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Card, CardBody, CardTitle, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { selectFilter, Comparator, textFilter } from 'react-bootstrap-table2-filter';
import { getUsuarios } from '../../services/adminUsuarioService';
import { getPerfiles } from "../../services/privateService";
import { getClientes } from "../../services/adminClienteService";
import { toast } from 'react-toastify';
import ModalAgregarUsuario from './ModalAgregarUsuario';
import { setIsLoading } from '../../appStatus/actions/actionPagina';
import ModalEditarUsuario from './ModalEditarUsuario';
import ModalEliminarUsuario from './ModalEliminarUsuario';

const ListadoUsuarios = () => {

    const dispatch = useDispatch()

    const [usuarios, setUsuarios] = useState([]);
    const [usuarioEditar, setUsuarioEditar] = useState(null);
    const [usuarioEliminar, setUsuarioEliminar] = useState(null);
    const [showModalAddUser, setShowModalAddUser] = useState(false);
    const [updateUsersList, setUpdateUsersList] = useState(false)


    const [clientes, setClientes] = useState([]);
    const [perfiles, setPerfiles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true))
            try {
                const usuariosResult = await getUsuarios();
                if (usuariosResult && usuariosResult.fueCorrecto) {
                    setUsuarios(usuariosResult.data);
                } else {
                    toast.error(usuariosResult.mensaje)
                }

            } catch (error) {

            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const resClientes = await getClientes();
            setClientes(resClientes);

            const resPerfiles = await getPerfiles()
            setPerfiles(resPerfiles);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (updateUsersList) {
            const fetchData = async () => {
                dispatch(setIsLoading(true))
                try {
                    const usuariosResult = await getUsuarios();
                    if (usuariosResult && usuariosResult.fueCorrecto) {
                        setUsuarios(usuariosResult.data);
                    } else {
                        toast.error(usuariosResult.mensaje)
                    }

                } catch (error) {
                    toast.error("Ocurrio un error inesperado: " + error.message)

                } finally {
                    dispatch(setIsLoading(false))
                    setUpdateUsersList(false);
                }
            }
            fetchData();
        }

    }, [updateUsersList])

    const authenticated = useSelector(store => store.session)

    // const filtrarPorPerfil = (f) => {
    //     const filtrado = usuarios.filter(usu => usu.PerfilId === Number(f))
    //     return filtrado;
    // }

    const clientColumnFilter = selectFilter({
        options: clientes?.map(item => { return { value: item.ClienteId, label: item.ClienteNombre } }),
        placeholder: 'Buscar por cliente',  // custom the input placeholder
        defaultValue: null, // default filtering value
        comparator: Comparator.EQ, // default is Comparator.EQ
        caseSensitive: false, // default is true
        withoutEmptyOption: false,  // hide the default select option
        id: 'clientColumnFilter', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
        // className: 'my-custom-text-filter', // custom classname on input
        // style: { ... }, // your custom styles on input
        //getFilter: (f) => { console.log(f) }, // accept callback function and you can call it for filter programmtically

    });


    const perfilColumnFilter = selectFilter({
        options: perfiles?.map(item => { return { value: item.PerfilId, label: item.Descripcion } }),
        placeholder: 'Buscar por Perfil',  // custom the input placeholder
        defaultValue: null, // default filtering value
        comparator: Comparator.EQ, // default is Comparator.EQ
        caseSensitive: false, // default is true
        withoutEmptyOption: false,  // hide the default select option
        id: 'perfilColumnFilter', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
        // className: 'my-custom-text-filter', // custom classname on input
        // style: { ... }, // your custom styles on input
        //getFilter: (f) => filtrarPorPerfil(f), // accept callback function and you can call it for filter programmtically
        //onFilter: (filterValue) => filtrarPorPerfil(filterValue)

    });

    const columns = [{
        dataField: 'Nombre',
        text: 'Nombre',
        filter: textFilter({ placeholder: "Ingrese un nombre..." })
    },
    {
        dataField: 'Representante',
        text: 'Representante',
        filter: textFilter({ placeholder: "Ingrese un representante..." })
    },
    {
        dataField: 'ClienteNombre',
        text: 'Cliente',
        filter: clientColumnFilter,
        filterValue: (cell, row) => row.ClienteId

    },
    {
        dataField: 'Contacto',
        text: 'Contacto',
        filter: textFilter({ placeholder: "Ingrese contacto..." })
    },
    {
        dataField: 'PerfilNombre',
        text: 'Perfil',
        filter: perfilColumnFilter,
        filterValue: (cell, row) => row.PerfilId
    },
    {
        text: 'Acciones',
        dataField: 'UsuarioId',
        formatter: (cell, row) =>
            <>
                <Row>
                    <Col><Button onClick={() => setUsuarioEditar(row)}> Editar</Button ></Col>
                    <Col><Button color="danger" onClick={() => setUsuarioEliminar(row)}> Eliminar</Button ></Col>
                </Row>
            </>
    }
    ];

    return (
        <>
            <ModalAgregarUsuario
                show={showModalAddUser}
                handleClose={() => setShowModalAddUser(false)}
                updateParent={() => setUpdateUsersList(true)}
                clientes={clientes}
                perfiles={perfiles}
            ></ModalAgregarUsuario>
            <ModalEditarUsuario
                show={usuarioEditar ? true : false}
                handleClose={() => setUsuarioEditar(null)}
                updateParent={() => setUpdateUsersList(true)}
                user={usuarioEditar}
                clientes={clientes}
                perfiles={perfiles}
            >
            </ModalEditarUsuario>
            <ModalEliminarUsuario
                show={usuarioEliminar ? true : false}
                handleClose={() => setUsuarioEliminar(null)}
                updateParent={() => setUpdateUsersList(true)}
                user={usuarioEliminar}
            />

            <Container className='mt-5 mb-5'>

                <Card>
                    <CardBody>
                        <CardTitle className='mt-2 mb-5' tag="h4">
                            Usuarios
                        </CardTitle>
                        <Row className='my-4'>
                            <Col>
                                <Button color='primary' onClick={() => setShowModalAddUser(true)}>Agregar Usuario</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {usuarios && perfiles && clientes && usuarios.length > 0 && (
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='UsuarioId'
                                        data={usuarios}
                                        columns={columns}
                                        pagination={paginationFactory()}
                                        filter={filterFactory()}
                                    />
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>


            </Container>
        </>
    )
};
export default ListadoUsuarios;