import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import VisorSection from '../../components/VisorSection'
import "../../sass/test.css";
import UsuariosConcurrentes from '../../components/UsuariosConcurrentes/UsuariosConcurrentes';

const VisorPage = () => {
    return (
        <Fragment>
            <Navbar2 Logo={Logo} />
            <PageTitle pagesub={'Visor de datos'} />
            <UsuariosConcurrentes componente={<VisorSection />} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default VisorPage;

