import axios from 'axios'
import { getToken } from '../appStatus/actions/sessionActions'
import { sessionService } from 'redux-react-session'

let iAxios = axios.create({
    defaultInterceptors: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
})

iAxios.interceptors.request.use(
    async (config) => {
        try {
            let tokenJWT = await getToken()
            config.headers.common['Authorization'] = `Bearer ${tokenJWT}`
        }
        catch (error) {
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

iAxios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        //validar los no autorizados para re.dirigir al login
        if (error.request.status === 401) {
            sessionService.deleteSession()
            sessionService.deleteUser()
            return Promise.reject('Su sesion a caducado')
        }
        else {
            //otros errores se retornan a vista de usuario
            return Promise.reject(error)
        }
    }
)

const config = { iAxios }

export default config