import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer'
import Logo from '../../images/logo2.png'
import { useParams } from "react-router-dom";
import PageTitle from '../../components/pagetitle';
import ListadoUsuarios from '../../components/AdministracionUsuarios/ListadoUsuarios';
import VisorUsuario from '../../components/AdministracionUsuarios/VisorUsuario';
import PageLoader from '../../components/PageLoader/PageLoader';
import { useSelector } from 'react-redux';

const AdministracionUsuariosPage = () => {

    const isLoading = useSelector(store => store.pagina.isLoading)
    return (
        <Fragment>

            <Navbar2 Logo={Logo} />
            {isLoading && (<PageLoader />)}
            <PageTitle pagesub={'Administración / Usuarios'} />
            <ListadoUsuarios />
            <Footer />
        </Fragment>
    )
};
export default AdministracionUsuariosPage;