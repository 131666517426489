import React, { useState, useEffect } from "react";
import PowerBi from "../PowerBi";
import { useSelector } from "react-redux";
import HeaderFiltro from "../Header-Filtro";
import { useDispatch } from "react-redux";
import SidebarLabSitio from "./sidebarLabSitio";
import {
  LimpiaFiltros,
  setIsLoading,
} from "../../appStatus/actions/actionPagina";
import { models } from "powerbi-client";
import ListadoEstadisticas from "../TablaExportar/listadoEstadisticas";
import ListadoCalculoAvanzadoClima from "../TablaExportar/listadoCalculoAvanzadoClima";
import ListadoCalculoAvanzadoAnomalias from "../TablaExportar/listadoCalculoAvanzadoAnomalias";

const LabSitioSection = () => {
  const sidebarOpened = useSelector((store) => store.pagina.sidebarOpened);
  const filtroCargado = useSelector((store) => store.pagina.filtroCargado);
  const exportarArchivo = useSelector((store) => store.pagina.exportarArchivo);
  const esJsonApi = useSelector((store) => store.pagina.esJsonApi);

  const formFiltroReporte = useSelector((store) => store.pagina.formularioFiltroReporte);
  const dispatch = useDispatch();

  const valueCombobox = useSelector((store) => store.pagina.valueCombobox);
  const reporte = useSelector((store) => store.pagina.reporte);
  const isLoading = useSelector((store) => store.pagina.isLoading);

  const {
    selEntidad,
    selVariable,
    selEstadisticas,
    categoria,
    dominio,
    FechaInicio,
    FechaFin,
    selCalculos,
    selTemporalidad,
    Estaciones,
    selCalculo,
    nombreSerieTemporal,
    selEstadisticas2,
    Entidad,
    SelEstadisticasAcum,
  } = formFiltroReporte || [];

  const { dominioIdSeleccionado } = dominio || [];

  const { nombreSerieFinal } = nombreSerieTemporal || [];

  let nombreSerie;
  [nombreSerie] = nombreSerieFinal || [];
  if (nombreSerie === undefined || nombreSerie === null) {
    nombreSerie = "Original";
  }

  const [isMenuShow, setIsMenuShow] = useState(false);
  const [tokenReporte, setTokenReporte] = useState(null);
  const [seleccionTabla, setSeleccionTabla] = useState(null);

  const [
    valorEstadisticaCalculosAvanzados,
    setValorEstadisticaCalculosAvanzados,
  ] = useState(null);

  useEffect(() => {
    if (sidebarOpened) {
      setIsMenuShow(true);
    } else {
      setIsMenuShow(false);
    }
  }, [sidebarOpened]);

  useEffect(() => {
    dispatch(LimpiaFiltros());
  }, []);

  useEffect(() => {
    if (selCalculos && selCalculo === "calculosAvanzadosSel") {
      setTokenReporte(selCalculos);
    } else if (selCalculo === "estadisticaSel") {
      if (
        nombreSerie === "Dias" ||
        nombreSerie === "Mes" ||
        nombreSerie === "Año"
      ) {
        setTokenReporte("2");
      } else if (nombreSerie === "Original" || nombreSerie === "Hora") {
        setTokenReporte("19");
      }
    }
  }, [selCalculos, selCalculo, nombreSerie]);

  useEffect(() => {
    let [variable] = selVariable || [];
    if (selCalculo === "calculosAvanzadosSel") {
      if (selCalculos === "5") {
        setSeleccionTabla("Clima");
        if (variable === 6) {
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum);
        } else {
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum);
        }
      } else if (selCalculos === "6") {
        setSeleccionTabla("Anomalias");
        if (variable === 6) {
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum);
        } else {
          setValorEstadisticaCalculosAvanzados(SelEstadisticasAcum);
        }
      } /*else if (selCalculo === "estadisticaSel") {
        setvalorTablaCalendario("CALENDARIO");
        setValorColumnaCalendario("FechaHora");
      }*/
    } if (selCalculo === "estadisticaSel") {
      setSeleccionTabla("Estadistica");
      setValorEstadisticaCalculosAvanzados(selEstadisticas);
    }
  }, [
    selCalculos,
    selCalculo,
    SelEstadisticasAcum,
    selEstadisticas,
    selVariable,
  ]);

  // let id = "8926d5eb-7bfb-461d-92a1-507d6e4e2b9f";

  const filterEstacion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_ESTACIONES_MONITOREO",
      column: "Estacion_Id",
    },
    operator: "In",
    values: Estaciones,
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterRegion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_REGION",
      column: "Region_id",
    },
    operator: "In",
    values: [parseInt(valueCombobox)],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterCarga = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "carga_datos",
      column: "carga",
    },
    operator: "In",
    values: [1],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterVariable = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_VARIABLE",
      column: "Variable_Id",
    },
    operator: "In",
    values: selVariable,
    filterType: 1,
    requireSingleSelection: false,
  };
  const filterCategoria = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_CATEGORIA_VARIABLE",
      column: "Categoria_Variable_Id",
    },
    operator: "In",
    values: categoria,
    filterType: 1,
    requireSingleSelection: false,
  };
  const filterDominio = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_DOMINIO_VARIABLE",
      column: "Dominio_Variable_Id",
    },
    operator: "In",
    values: dominioIdSeleccionado,
    filterType: 1,
    requireSingleSelection: false,
  };
  const filterEntidad = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_ENTIDAD",
      column: "Entidad_Id",
    },
    operator: "In",
    values: [Entidad],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filterEstadistica = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CTR_PLATAFORMA ACCIONES_OBJETO",
      column: "Accion_Objeto_Nombre",
    },
    operator: "In",
    values: [valorEstadisticaCalculosAvanzados],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filterEstadistica2 = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CTR_PLATAFORMA ACCIONES_OBJETO_2",
      column: "Accion_Objeto_Nombre_2",
    },
    operator: "In",
    values: [selEstadisticas2],
    filterType: 1,
    requireSingleSelection: true,
  };
  const filtroCalendar = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "CTR_PLATAFORMA DT_FECHA_HORAS",
      column: "Fecha_Calendario_Dt",
    },
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: FechaInicio + "T03:00:00Z",
      },
      {
        operator: "LessThanOrEqual",
        value: FechaFin + "T03:00:00Z",
      },
    ],
  };
  const filtroUltimoMes = {
    $schema: "http://powerbi.com/product/schema#relativeDate",
    target: {
      table: "CTR_PLATAFORMA DT_FECHA_HORAS",
      column: "Fecha_Calendario_Dt",
    },
    operator: models.RelativeDateOperators.InLast,
    timeUnitsCount: 30,
    timeUnitType: models.RelativeDateFilterTimeUnit.Days,
    includeToday: false,
    filterType: models.FilterType.RelativeDate,
  };
  const filtroUltimoAnio = {
    $schema: "http://powerbi.com/product/schema#relativeDate",
    target: {
      table: "CTR_PLATAFORMA DT_FECHA_HORAS",
      column: "Fecha_Calendario_Dt",
    },
    operator: models.RelativeDateOperators.InLast,
    timeUnitsCount: 360,
    timeUnitType: models.RelativeDateFilterTimeUnit.Days,
    includeToday: false,
    filterType: models.FilterType.RelativeDate,
  };

  useEffect(() => {
    if (selTemporalidad === "mes") {
      filtrosReporteSitio.push(filtroUltimoMes);
    } else if (selTemporalidad === "anio") {
      filtrosReporteSitio.push(filtroUltimoAnio);
    } else if (selTemporalidad === "calendario") {
      filtrosReporteSitio.push(filtroCalendar);
    }
  }, [selTemporalidad, filtroUltimoMes, filtroUltimoAnio, filtroCalendar]);

  useEffect(() => {
    if (selEstadisticas2) {
      filtrosReporteSitio.push(filterEstadistica2);
    }
  }, [selEstadisticas2, filterEstadistica2]);

  const filtrosReporteSitio = [
    filterRegion,
    filterCarga,
    filterCategoria,
    filterEntidad,
    filterVariable,
    filterDominio,
    filterEstadistica,
    filterEstacion,
    //filterEstadistica2,
  ];

  return (
    <div className="labSitio">
      <div className={`wrap ${isMenuShow ? "menu-filtro" : ""}`}>
        <HeaderFiltro />
        <SidebarLabSitio />
        <main className="content">
          {!filtroCargado &&
            !isLoading &&
            !exportarArchivo && //valida si no se esta en la seccion de exportar
            !reporte && ( // no se esta cargando una calculadora
              <div className="message-lab">
                <i className="fa fa-hand-o-left fa-3x"></i>&nbsp;&nbsp;
                <p className="mensaje">
                  Bienvenidos a la página de reportes Sitio, para ver los
                  reportes es necesario seleccionar filtros
                </p>
              </div>
            )}
          {filtroCargado && !esJsonApi && (
            <PowerBi
              reportId={tokenReporte}
              requiereFiltros={true}
              filtrosReporte={filtrosReporteSitio}
              calculadora={false}
            />
          )}
          {filtroCargado && esJsonApi && seleccionTabla === "Estadistica" && (
            <ListadoEstadisticas />
          )}
          {filtroCargado && esJsonApi && seleccionTabla === "Clima" && (
            <ListadoCalculoAvanzadoClima />
          )}
          {filtroCargado && esJsonApi && seleccionTabla === "Anomalias" && (
            <ListadoCalculoAvanzadoAnomalias />
          )}
        </main>
      </div>
    </div>
  );
};

export default LabSitioSection;
