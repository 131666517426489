import React from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap-v5";
import { ModalHeader } from "reactstrap";

const ModalError = ({mostrarModal, ocultarModal,}) => {
  return (
    <Modal show={mostrarModal} centered backdrop="static" keyboard={false} onHide={ocultarModal}>
      <ModalHeader>
        <ModalTitle id="contained-modal-title-lg">Recargar Página</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>
              Por medida de seguridad ante la inactividad en el sitio, es necesario realizar un refresco de la página.
          </p>
          <button className="btn btn-success" onClick={() => window.location.reload()}>Recargar página</button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalError;
