import { sessionService } from 'redux-react-session'
import { loginUser, updateToken, logoutUser } from '../../services/apiAuthService'
import jwt_decode from "jwt-decode"
import moment from "moment";

export const login = (user, tokenCaptcha) => {
    return () => {
        return loginUser(user, tokenCaptcha).then(response => {
            if (!response.error) {
                const { access_token, userName, deviceId, refresh_token, perfilId, clienteId } = response
                // console.log(response)

                sessionService.saveSession({ access_token, deviceId, refresh_token })
                sessionService.saveUser({ userName, recordoCuenta: user.recordarCuenta, perfilId, clienteId })

                return true
            }
            else {
                return false
            }
        }).catch(function (reason) {
            console.log(reason)
            return false
        })
    }
}

export const logout = () => {
    return async (dispatch, getState) => {
        const user = await sessionService.loadUser()
        const session = await sessionService.loadSession()

        if (user.recordoCuenta) {
            logoutUser(session.deviceId)
        }

        sessionService.deleteSession();
        sessionService.deleteUser();
    };
};

export const userLoggedOut = () => {
    return {
        type: 'RESET_STORE',
    }
}

export const validateSession = async (session) => {
    const diferencia = validateTokenLifetime(session);

    if (diferencia < 0) {
        return await getNewToken(session) != null
    }
    else {
        return true
    }
}

const validateTokenLifetime = (session) => {
    try {
        const { access_token } = session
        const decoded = jwt_decode(access_token)
        const fechaExpiracion = new Date(0)
        fechaExpiracion.setUTCSeconds(decoded.exp)
        const fechaExpMoment = moment(fechaExpiracion)
        const fechaActual = moment()
        const diferencia = fechaExpMoment.diff(fechaActual, 'seconds')

        return diferencia
    }
    catch (error) {
        console.log(error)
        return true
    }
}

const getNewToken = async (session) => {
    const { refresh_token, deviceId } = session

    if (refresh_token && deviceId) {

        return updateToken({ refreshToken: refresh_token, deviceId: deviceId }).then(response => {
            sessionService.saveSession({ access_token: response.access_token, deviceId, refresh_token })

            return response.access_token
        }).catch(function (reason) {
            return null
        })
    }
    else {
        return null
    }
}

export const getToken = async () => {
    const session = await sessionService.loadSession()
    const diferencia = validateTokenLifetime(session)

    if (diferencia < 0) {
        return getNewToken(session)
    }
    else {
        return session.access_token
    }
}