import React, { useState } from "react";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
import { ModalFooter, Modal, ModalBody, Button, Card, Row, Col, Tabs, Tab, Image } from "react-bootstrap-v5";
import obtencionApiKeyImg from './obtencion-api-key.PNG'

const ModalGuiaConsumoApi = ({ show, handleClose, queryData, apiData }) => {

    const generaUrlConParametros = () => {
        return `${apiData.apiUrl}?${encodeQueryData(queryData)}`
    }
    function encodeQueryData(data) {
        const ret = [];
        for (let d in data) {
            if (Array.isArray(data[d])) {
                for (let val in data[d]) {
                    ret.push(encodeURIComponent(d) + '[]=' + encodeURIComponent(data[d][val]));
                }

            } else {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
        }
        return ret.join('&');
    }


    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader> {apiData.titulo}</ModalHeader>
                <ModalBody>
                    <Tabs defaultActiveKey="invocacion" id="tab-guia-consumo">
                        <Tab eventKey="invocacion" title="Invocación API" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <p>
                                                <code>curl -X 'GET' \</code>
                                                <br></br>
                                                <code>{generaUrlConParametros()}</code>
                                                <br></br>
                                                <code>-H 'accept: application/json, text/plain, */*'\</code>
                                                <br></br>
                                                <code>-H 'xxx-api-key:XXXXX.XXXXXXXXXXXXXXX'\</code>
                                            </p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Tab>
                        <Tab eventKey="apiKey" title="Obtención ApiKey" className="mb-3">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} className="my-3">
                                            <p>Para el consumo de los servicios se requiere del uso de una ApiKey. Esta se obtiene desde la opción de menú Administración -> Mi Cliente</p>
                                            <small>*Esta opción solo está disponible para usuarios autenticados, si no posees una cuenta puedes ponerte en contacto con el administrador de la plataforma.</small>
                                        </Col>
                                        <Col xs={12}>
                                            <Image fluid={true} src={obtencionApiKeyImg} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Tab>
                    </Tabs>


                </ModalBody>
                <ModalFooter>

                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalGuiaConsumoApi;