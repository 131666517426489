import React from "react";
import { Link } from "react-router-dom";
import shape from "../../images/cta-shape.png";

const CtaSection = (props) => {
  return (
    <div className={`wpo-cta-area ${props.ctClass}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="wpo-cta-section">
              <div className="col-md-12 glosaHomePage">
                <h2>{props.cTitle}</h2>
              </div>
              <div className="col-md-12 glosaHomePage">
                <Link className="btn btn-success" to="/glosa">Ir a glosario</Link>
              </div>
              <div className="shape">
                <img src={shape} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
