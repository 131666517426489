import React from "react";
import PowerBi from "../PowerBi";

const TendenciasSection = () => {
  

  // let id = "90862ab8-3dc6-4b78-a0cf-d5087e59be6e"
  let id = '18'
  //let page = "tendencias";
  //let id = process.env.ID_REPORTE_VISOR
  return (
    <div>
      <PowerBi
        reportId={id} requiereFiltros={false}
      />
    </div>
  );
};

export default TendenciasSection;