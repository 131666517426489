import React, { useEffect } from "react";
import { Button, FormGroup, FormText, Row, Col, Card } from "react-bootstrap-v5";
import { useForm, Controller } from "react-hook-form";
import { saveSuscripcion, updateCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator'
import { DatePicker } from "react-nice-dates";
import { es } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../appStatus/actions/actionPagina";
import { PerfilPlataformaEnum } from "../../helpers/Enums";
const dayjs = require('dayjs')

const TabEditarMiCliente = ({ clienteEditar, updateParent, instituciones }) => {

    const user = useSelector(store => store.session.user)

    const dispatch = useDispatch()
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        reset,
        setValue

    } = useForm({ reValidateMode: "onBlur" });

    useEffect(() => {
        reset()
        if (clienteEditar) {
            setValue("NombreCliente", clienteEditar.Nombre)
            setValue("ContactoCliente", clienteEditar.Contacto);
            setValue("InstitucionId", clienteEditar.InstitucionId);
        }
    }, [clienteEditar])


    const onSubmit = async (data) => {
        if (user.perfilId !== PerfilPlataformaEnum.Super_Usuario.value) {
            toast.warning("Usuario no tiene los privilegios para editar el registro de cliente.")
            return
        }
        let formData = {
            "ClienteId": clienteEditar.ClienteId,
            "NombreCliente": data.NombreCliente,
            "ContactoCliente": data.ContactoCliente,
            "InstitucionId": Number(clienteEditar.InstitucionId),
        }

        dispatch(setIsLoading(true))
        try {

            const res = await updateCliente(formData);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
                updateParent();
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };
    return (

        <Card>
            <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>

                        <Col xs={12} md={6}>
                            <FormGroup className="my-3">

                                <FormText>Nombre</FormText>
                                <div className="input-group">
                                    <input
                                        readOnly={user.perfilId !== PerfilPlataformaEnum.Super_Usuario.value ? true : false}
                                        className="input-transparent pl-3 form-control"
                                        {
                                        ...register("NombreCliente", {
                                            required: true,
                                            maxLength: 100
                                        }
                                        )}
                                        name="NombreCliente"
                                    />
                                </div>
                                {errors.NombreCliente && errors.NombreCliente.type === "required" && (
                                    <span className="invalid">Campo nombre es obligatorio</span>
                                )}
                                {errors.NombreCliente && errors.NombreCliente.type === "maxLength" && (
                                    <span className="invalid">Campo nombre puede tener como máximo 100 caracteres</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className="my-3">
                                <FormText>Contacto</FormText>
                                <div className="input-group">
                                    <input
                                        className="input-transparent pl-3 form-control"
                                        readOnly={user.perfilId !== PerfilPlataformaEnum.Super_Usuario.value ? true : false}
                                        {
                                        ...register("ContactoCliente",
                                            {
                                                required: true,
                                                maxLength: 50
                                            }
                                        )}
                                        name="ContactoCliente"
                                    />
                                </div>
                                {errors.ContactoCliente && errors.ContactoCliente.type === "required" && (
                                    <span className="invalid">Campo contacto es obligatorio</span>
                                )}
                                {errors.ContactoCliente && errors.ContactoCliente.type === "maxLength" && (
                                    <span className="invalid">Campo contacto puede tener como máximo 50 caracteres </span>
                                )}
                            </FormGroup>
                        </Col>

                        <Col xs={12}>
                            <FormGroup className="my-3">
                                <FormText>Institución</FormText>
                                <div className="input-group">
                                    <input
                                        className="input-transparent pl-3 form-control"
                                        readOnly={user.perfilId !== PerfilPlataformaEnum.Super_Usuario.value ? true : false}
                                        name="InstitucionId"
                                        value={clienteEditar?.Institucion.Descripcion}
                                    />


                                </div>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                className="btn btn-primary"
                                onClick={(e) => handleSubmit(onSubmit)()}
                            >
                                Actualizar cliente
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>

    );
};

export default TabEditarMiCliente;