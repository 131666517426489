import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Card, CardBody, CardTitle, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import { toast } from 'react-toastify';
import { setIsLoading } from '../../appStatus/actions/actionPagina';
import ModalAgregarCliente from './ModalAgregarCliente';
import { getClientes } from '../../services/adminClienteService';
import ModalEditarCliente from './ModalEditarCliente';
import { getInstituciones } from '../../services/privateService';
import { getProductos } from '../../services/adminProductoService';
import ModalEliminarCliente from './ModalEliminarCliente';
const dayjs = require('dayjs')

const ListadoClientes = () => {

    const dispatch = useDispatch()

    const [instituciones, setInstituciones] = useState([])
    const [productos, setProductos] = useState([])
    const [clientes, setClientes] = useState([]);
    const [updateClientList, setUpdateClientList] = useState(false)
    const [showModalAddClient, setShowModalAddClient] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientToDelete, setClientToDelete] = useState(null);



    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true))
            try {

                const clientesResult = await getClientes();
                setClientes(clientesResult);

            } catch (error) {
                toast.error("Ocurrio un error inesperado: " + error.message)

            } finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (updateClientList) {
            const fetchData = async () => {
                dispatch(setIsLoading(true))
                try {

                    const clientesResult = await getClientes();
                    setClientes(clientesResult);

                } catch (error) {
                    toast.error("Ocurrio un error inesperado: " + error.message)

                } finally {
                    dispatch(setIsLoading(false))
                    setUpdateClientList(false);
                }
            }
            fetchData();
        }

    }, [updateClientList])

    useEffect(() => {
        const fetchData = async () => {

            const institucionesRes = await getInstituciones();
            setInstituciones(institucionesRes);



            const productosResult = await getProductos();
            setProductos(productosResult);

        }
        fetchData();

    }, [])

    const authenticated = useSelector(store => store.session)

    const columns = [{
        dataField: 'ClienteNombre',
        text: 'Cliente'
    },
    {
        dataField: 'ContactoCliente',
        text: 'Contacto'
    },
    {
        dataField: 'InstitucionNombre',
        text: 'Institución',

    }, {
        dataField: 'FechaVencimientoUltimaSuscripcion',
        text: 'Vencimiento Suscripcion',
        sort: true,
        formatter: (cell, row) => { return renderFechaVencimientoSuscripcion(row.FechaVencimientoUltimaSuscripcion) }
    },
    {
        text: 'Ver',
        dataField: 'ClienteId',
        formatter: (cell, row) =>
        (
            <>
                <Row>
                    <Col><Button onClick={() => setSelectedClient(row)}>Editar</Button></Col>
                    <Col><Button color='danger' onClick={() => setClientToDelete(row)}>Eliminar</Button></Col>
                </Row>
            </>

        )
    }
    ];

    const renderFechaVencimientoSuscripcion = (fechavencimientoUltimaSuscripcion) => {
        if (fechavencimientoUltimaSuscripcion) {

            const textColor = dayjs(fechavencimientoUltimaSuscripcion).diff(dayjs(), 'days') <= 10 ? 'red' : "";
            return (<p style={{ color: textColor }}>{dayjs(fechavencimientoUltimaSuscripcion).format('DD/MM/YYYY')}</p>)
        } else {
            return (<p style={{ color: 'red' }}>Sin suscripción</p>)
        }

    }

    return (
        <>

            <ModalAgregarCliente
                show={showModalAddClient}
                handleClose={() => setShowModalAddClient(false)}
                updateParent={() => setUpdateClientList(true)}
                instituciones={instituciones}
            ></ModalAgregarCliente>
            <ModalEditarCliente
                show={selectedClient ? true : false}
                handleClose={() => setSelectedClient(null)}
                updateParent={() => setUpdateClientList(true)}
                instituciones={instituciones}
                productos={productos}
                client={selectedClient}
            />
            <ModalEliminarCliente
                show={clientToDelete ? true : false}
                handleClose={() => setClientToDelete(null)}
                updateParent={() => setUpdateClientList(true)}
                client={clientToDelete}
            />
            <Container className='mt-5 mb-5'>

                <Card>
                    <CardBody>
                        <CardTitle className='mt-2 mb-5' tag="h4">
                            Clientes
                        </CardTitle>
                        <Row className='my-4'>
                            <Col>
                                <Button color='primary' onClick={() => setShowModalAddClient(true)}>Agregar Cliente</Button>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                {clientes && clientes.length > 0 && (
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='ClienteId'
                                        data={clientes}
                                        columns={columns} />
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>


            </Container>
        </>
    )
};
export default ListadoClientes;