import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer'
import Logo from '../../images/logo2.png'
import { useSelector } from "react-redux";
import PageTitle from '../../components/pagetitle';
import ListadoProductos from '../../components/AdministracionProductos/ListadoProductos';
import PageLoader from '../../components/PageLoader/PageLoader';

const AdministracionClientes = () => {

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const productos = await getProductos()
    //         console.log(productos)
    //     }

    //     fetchData();
    // }, []);
    const authenticated = useSelector(store => store.session)
    const isLoading = useSelector(store => store.pagina.isLoading)
    return (
        <Fragment>
            {isLoading && (<PageLoader />)}
            <Navbar2 Logo={Logo} />
            <PageTitle pagesub={'Administración / Productos'} />
            <ListadoProductos />
            <Footer />
        </Fragment>
    )
};
export default AdministracionClientes;