import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2';
import Hero7 from '../../components/hero7';
import Footer from '../../components/footer'
import AboutS4 from '../../components/About';
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import abimg from '../../images/bird.png'
import PartnerSection from '../../components/PartnerSection';
import CtaSection from '../../components/ctaSection';

const HomePage6 =() => {
    return(
        <Fragment>
            <Navbar2 Logo={Logo}/>
            <Hero7 heroClass={'wpo-hero-section-6'}/>
            <AboutS4 abImg={abimg} Atitle={'Acerca de BIRH'}/>
            <CtaSection cTitle={'Conceptos y definiciones utilizados en los reportes.'}/>
            <PartnerSection/>
            <Scrollbar/>
            <Footer/>
        </Fragment>
    )
};
export default HomePage6;