import React, { useEffect, useState } from "react";
import { ModalFooter, Modal, ModalBody, Button, FormGroup, FormText, Row, Col } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
// import { ModalHeader, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "react-nice-dates";
import { es } from "date-fns/locale";
import { useDispatch } from "react-redux";
import { getInstituciones } from "../../services/privateService";
import { getClientes, saveCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import { setIsLoading } from "../../appStatus/actions/actionPagina";


const ModalAgregarCliente = ({ show, handleClose, updateParent, instituciones }) => {
    const dispatch = useDispatch();
    const [date, setDate] = useState()
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        reset
    } = useForm({ reValidateMode: "onBlur" });




    const onSubmit = async (data) => {
        let formData = {
            "NombreCliente": data.NombreCliente,
            "ContactoCliente": data.ContactoCliente,
            "CuotaSuscripcion": Number(data.CuotaSuscripcion),
            "CuotaUsuariosConcurrentes": Number(data.CuotaUsuariosConcurrentes),
            "InstitucionId": Number(data.InstitucionId),
            "FechaInicioSuscripcion": data.FechaInicioSuscripcion,
            "FechaFinSuscripcion": data.FechaFinSuscripcion
        }

        //console.log(data)
        dispatch(setIsLoading(true))
        try {
            const res = await saveCliente(formData);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
                updateParent();
                handleClose();
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };


    useEffect(() => {
        if (show) {
            reset()
        }

    }, [show])


    const ValidarFormulario = (e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
    };


    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader>Nuevo Cliente</ModalHeader>
                <ModalBody>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12}>
                                <h5>Datos Cliente</h5>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className="my-3">

                                    <FormText>Nombre</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("NombreCliente", {
                                                required: true,
                                                maxLength: 100
                                            }
                                            )}
                                            name="NombreCliente"
                                        />
                                    </div>
                                    {errors.NombreCliente && errors.NombreCliente.type === "required" && (
                                        <span className="invalid">Campo nombre es obligatorio</span>
                                    )}
                                    {errors.NombreCliente && errors.NombreCliente.type === "maxLength" && (
                                        <span className="invalid">Campo nombre puede tener como máximo 100 caracteres</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className="my-3">
                                    <FormText>Contacto</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("ContactoCliente",
                                                {
                                                    required: true,
                                                    maxLength: 50
                                                }
                                            )}
                                            name="ContactoCliente"
                                        />
                                    </div>
                                    {errors.ContactoCliente && errors.ContactoCliente.type === "required" && (
                                        <span className="invalid">Campo contacto es obligatorio</span>
                                    )}
                                    {errors.ContactoCliente && errors.ContactoCliente.type === "maxLength" && (
                                        <span className="invalid">Campo contacto puede tener como máximo 50 caracteres </span>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col xs={12}>
                                <FormGroup className="my-3">
                                    <FormText>Institución</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="InstitucionId"
                                            render={({ field: { onChange, onBlur } }) => (
                                                <select
                                                    className="form-control"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                // onChange={(e) => changeRegion(field, e.target.value)}
                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    {instituciones.map((r, e) => (
                                                        <option key={e} value={r.InstitucionId}>
                                                            {r.InstitucionNombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            rules={{ required: true }}
                                        />

                                    </div>
                                    {errors.InstitucionId && errors.InstitucionId.type === "required" && (
                                        <span className="invalid">Campo Institución es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col xs={12}>
                                <h5>Suscripción</h5>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className="my-3">
                                    <FormText>Fecha inicio</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="FechaInicioSuscripcion"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    date={field.value}
                                                    onDateChange={field.onChange}
                                                    locale={es}
                                                    format="dd/MM/yyyy"
                                                >
                                                    {({ inputProps, focused }) => (
                                                        <input
                                                            className={'input-transparent pl-3 form-control input'}
                                                            {...inputProps}
                                                        />
                                                    )}
                                                </DatePicker>
                                            )}
                                        />
                                    </div>
                                    {errors.FechaInicioSuscripcion && errors.FechaInicioSuscripcion.type === "required" && (
                                        <span className="invalid">Campo Fecha inicio es obligatorio</span>
                                    )}
                                </FormGroup>

                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className="my-3">
                                    <FormText>Fecha fin</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="FechaFinSuscripcion"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <DatePicker
                                                    // date={date}
                                                    // onDateChange={setDate}
                                                    date={field.value}
                                                    onDateChange={field.onChange}
                                                    locale={es}
                                                    format="dd/MM/yyyy"
                                                >
                                                    {({ inputProps, focused }) => (
                                                        <input
                                                            // className={'input' + (focused ? '-focused' : '')}
                                                            className={'input-transparent pl-3 form-control input'}
                                                            {...inputProps}
                                                        />
                                                    )}
                                                </DatePicker>
                                            )}
                                        />
                                    </div>
                                    {errors.FechaFinSuscripcion && errors.FechaFinSuscripcion.type === "required" && (
                                        <span className="invalid">Campo Fecha fin es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className="my-3">
                                    <FormText>Cuota mensual (MB)</FormText>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("CuotaSuscripcion",
                                                {
                                                    required: true,

                                                }
                                            )}
                                            name="CuotaSuscripcion"
                                        />
                                    </div>
                                    {errors.CuotaSuscripcion && errors.CuotaSuscripcion.type === "required" && (
                                        <span className="invalid">Campo cuota es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className="my-3">
                                    <FormText>Cuota Usuarios</FormText>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("CuotaUsuariosConcurrentes",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            name="CuotaUsuariosConcurrentes"
                                        />
                                    </div>
                                    {errors.CuotaUsuariosConcurrentes && errors.CuotaUsuariosConcurrentes.type === "required" && (
                                        <span className="invalid">Campo cuota es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn btn-primary"
                        onClick={(e) => ValidarFormulario(e)}
                    >
                        Ingresar
                    </Button>
                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAgregarCliente;