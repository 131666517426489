import React from 'react';
import DespliegueReporte from '../powerBi/DespliegueReporte';

const GraficoPrediccionTabs = () => {
    const id = '15'

    return (
        <>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <DespliegueReporte
                    reportId={id}
                ></DespliegueReporte>
            </div>
        </>
    )
};
export default GraficoPrediccionTabs;