import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import pimg1 from "../../images/partners/corfo.png";
import pimg2 from "../../images/partners/fdf.png";
import pimg3 from "../../images/partners/inia.png";
import pimg4 from "../../images/partners/junta.png";
import pimg5 from "../../images/partners/lem.png";
import pimg6 from "../../images/partners/DGA_n.png";
import pimg7 from "../../images/partners/entel.png";
import pimg8 from "../../images/partners/puc.png";
import pimg9 from "../../images/partners/sma.png";
import pimg10 from "../../images/partners/udec.png";
import pimg11 from "../../images/partners/wiseconn.png";

const partners = [
  {
    pImg: pimg1,
  },
  {
    pImg: pimg2,
  },
  {
    pImg: pimg3,
  },
  {
    pImg: pimg4,
  },
  {
    pImg: pimg5,
  },
  {
    pImg: pimg6,
  },
  {
    pImg: pimg7,
  },
  {
    pImg: pimg8,
  },
  {
    pImg: pimg9,
  },
  {
    pImg: pimg10,
  },
  {
    pImg: pimg11,
  }
];

class PartnerSection extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      speed: 1000,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="partners-section section-padding section-colors">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="wpo-section-title">
                <h2>Instituciones Participantes</h2>
                <p>
                  Listado de instituciones que participan en el proyecto, para
                  mas información puede ingresar al siguiente &nbsp;
                  <a
                    href="https://www.coth2o.cl"
                    target="_blank"
                  >
                    link.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <div className="partner-grids partners-slider owl-carousel">
                <Slider {...settings}>
                  {partners.map((partner, pitem) => (
                    <div className="grid" key={pitem}>
                      <img src={partner.pImg} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
//23170
export default PartnerSection;
