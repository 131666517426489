import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
import { format, subDays } from "date-fns";
import { saveAs } from "file-saver";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { setIsLoading as setGlobalIsLoading } from "../../appStatus/actions/actionPagina";
import { toast } from "react-toastify";

import { setExportar } from "../../appStatus/actions/actionPagina";
import {
  getCalculoAvanzadoClima,
  getCalculoAvanzadoClimaCsv,
} from "../../services/apisService";
import ModalGuiaConsumoApi from "./ModalGuiaConsumoApi";
import { Alert } from "react-bootstrap-v5";
import { setMensajeInformacion } from "../../appStatus/actions/actionMensajeInformacion";
const urlApisBirh = process.env.REACT_APP_API_HELADAS;

const ListadoFiltroAvanzado = () => {
  const dispatch = useDispatch();

  const componentMounted = useRef(true);

  const formFiltroReporte = useSelector(
    (store) => store.pagina.formularioFiltroReporte
  );
  const botonExportar = useSelector((store) => store.pagina.exportarArchivo);

  const [nombreColumnas, setNombreColumnas] = useState(null);
  const [resultadoFiltro, setResultadoFiltro] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nombreVariable, setNombreVariable] = useState(null);

  const [dataModalGuiaConsumo, setDataModalGuiaConsumo] = useState(null);
  const [dataModalGuiaConsumoCsv, setDataModalGuiaConsumoCsv] = useState(null);

  const climaUrl = `${urlApisBirh}Clima`;
  const climaCsvUrl = `${urlApisBirh}ClimaCsv`;

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const {
    selVariable,
    selEstadisticas,
    FechaInicio,
    FechaFin,
    nombreSerieTemporal,
    Estaciones,
  } = formFiltroReporte || [];
  const { nombreSerieFinal } = nombreSerieTemporal || [];
  let nombreSerie;
  [nombreSerie] = nombreSerieFinal || [];

  if (nombreSerie === undefined || nombreSerie === null) {
    nombreSerie = "Original";
  }

  useEffect(() => {
    if (selVariable[0] === 6) {
      setNombreVariable("Acumulado");
    } else {
      setNombreVariable("Promedio");
    }
  }, [selVariable]);

  useEffect(
    (page, sizePerPage) => {
      if (botonExportar) {
        const fetchTable = async () => {
          dispatch(setGlobalIsLoading(true));
          console.log(nombreVariable);
          try {
            if (nombreVariable) {
              const resultadoFiltrosExportar = await getCalculoAvanzadoClima({
                Estaciones,
                FechaDesde: FechaInicio,
                FechaHasta: FechaFin,
                Variables:
                  selVariable instanceof Array ? selVariable : [selVariable],
                SerieTemporal: nombreSerie,
                Estadistica: nombreVariable,
                Pagina: page,
                CantidadRegistros: sizePerPage ? sizePerPage : 10,
              });

              if (componentMounted.current) {
                setResultadoFiltro(resultadoFiltrosExportar);
                setPage(page);
                setTotalSize(resultadoFiltrosExportar.TotalRegistros);
                setSizePerPage(Number(resultadoFiltrosExportar.Limite));
              }
              return () => {
                componentMounted.current(false);
              };
            }
          } catch (error) {
            handleError(error);
          } finally {
            dispatch(setGlobalIsLoading(false));
            dispatch(setExportar(false));
          }
        };
        fetchTable();
      }
    },
    [
      botonExportar,
      Estaciones,
      FechaInicio,
      FechaFin,
      selVariable,
      nombreSerie,
      nombreVariable,
      dispatch,
    ]
  );

  const handleError = (error) => {
    if (error?.response?.data?.statusCode === 403) {
      dispatch(setMensajeInformacion(error?.response?.data?.message));
    } else if (error?.response?.data?.statusCode === 429)
      dispatch(
        setMensajeInformacion(
          "Demasiadas solicitudes, intente nuevamente mas tarde"
        )
      );
    else
      dispatch(
        setMensajeInformacion(
          "Ocurrio un error inesperado, intente nuevamente mas tarde."
        )
      );
  };

  const onTableChange = async (type, { page, sizePerPage }) => {
    //fetchTable(page, sizePerPage)

    dispatch(setGlobalIsLoading(true));
    console.log(nombreVariable);

    try {
      const resultadoFiltrosExportar = await getCalculoAvanzadoClima({
        Estaciones,
        FechaDesde: FechaInicio,
        FechaHasta: FechaFin,
        Variables: selVariable instanceof Array ? selVariable : [selVariable],
        SerieTemporal: nombreSerie,
        Estadistica: nombreVariable,
        Pagina: page,
        CantidadRegistros: sizePerPage ? sizePerPage : 10,
      });

      if (componentMounted.current) {
        setResultadoFiltro(resultadoFiltrosExportar);
        setPage(page);
        setTotalSize(resultadoFiltrosExportar.TotalRegistros);
        setSizePerPage(Number(resultadoFiltrosExportar.Limite));
      }
      return () => {
        componentMounted.current(false);
      };
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setGlobalIsLoading(false));
      dispatch(setExportar(false));
    }
  };

  useEffect(() => {
    const hideColumns = ["IdAutonumerico"];
    if (resultadoFiltro?.Items) {
      const columnas = [];
      for (var item in resultadoFiltro.Items[0]) {
        if (hideColumns.indexOf(item) < 0)
          columnas.push({
            dataField: item,
            text: item.replace("_", " "),
          });
      }

      setNombreColumnas(columnas);
    }
  }, [resultadoFiltro]);

  const exportarCSV = async (e) => {
    e.preventDefault();
    try {
      dispatch(setGlobalIsLoading(true));
      console.log(nombreVariable);
      if (formFiltroReporte) {
        const resultadoFiltrosExportar = await getCalculoAvanzadoClimaCsv({
          Estaciones,
          FechaDesde: FechaInicio,
          FechaHasta: FechaFin,
          Variables: selVariable instanceof Array ? selVariable : [selVariable],
          SerieTemporal: nombreSerie,
          Estadistica: nombreVariable,
        });

        var blob = new Blob([resultadoFiltrosExportar.data], {
          type: resultadoFiltrosExportar.headers["content-type"],
        });
        saveAs(blob, "ReporteClima.csv");
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setGlobalIsLoading(false));
      dispatch(setExportar(false));
    }
  };
  const onVerModalGuiaConsumo = () => {
    const formData = {
      Estaciones,
      FechaDesde: FechaInicio,
      FechaHasta: FechaFin,
      Variables: selVariable instanceof Array ? selVariable : [selVariable],
      SerieTemporal: nombreSerie,
      Estadistica: selEstadisticas,
      Pagina: 1,
      CantidadRegistros: 10,
    };
    setDataModalGuiaConsumo(formData);
  };
  const onVerModalGuiaConsumoCsv = () => {
    const formData = {
      Estaciones,
      FechaDesde: FechaInicio,
      FechaHasta: FechaFin,
      Variables: selVariable instanceof Array ? selVariable : [selVariable],
      SerieTemporal: nombreSerie,
      Estadistica: selEstadisticas,
    };
    setDataModalGuiaConsumoCsv(formData);
  };

  return (
    <>
      <ModalGuiaConsumoApi
        handleClose={() => setDataModalGuiaConsumo(null)}
        queryData={dataModalGuiaConsumo}
        apiData={{ apiUrl: climaUrl, titulo: "Calculo avanzado: Clima" }}
        show={dataModalGuiaConsumo ? true : false}
      />
      <ModalGuiaConsumoApi
        handleClose={() => setDataModalGuiaConsumoCsv(null)}
        queryData={dataModalGuiaConsumoCsv}
        apiData={{ apiUrl: climaCsvUrl, titulo: "Calculo avanzado: Clima Csv" }}
        show={dataModalGuiaConsumoCsv ? true : false}
      />
      <Container>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="btn-group">
                <button
                  name="csv"
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => exportarCSV(e)}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Exportar Csv Clima{" "}
                  {isLoading ? (
                    <i className=" fa fa-spinner fa-spin"></i>
                  ) : null}
                </button>
                <button
                  name="guiaConsumirApi"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onVerModalGuiaConsumo()}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Guia consumo API JSON
                </button>
                <button
                  name="guiaConsumirApi"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onVerModalGuiaConsumoCsv()}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Guia consumo API CSV
                </button>
              </div>
            </CardTitle>
            <Row>
              <Col>
                <div className="table-responsive table-striped table-borderless table-hover">
                  {resultadoFiltro && nombreColumnas?.length > 0 ? (
                    <BootstrapTable
                      remote
                      loading={loading}
                      data={resultadoFiltro.Items ?? []}
                      keyField="IdAutonumerico"
                      columns={nombreColumnas}
                      pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <Alert className="text-center" variant="warning">
                      <Alert.Heading>
                        No se encontraron datos para esta consulta.
                      </Alert.Heading>
                    </Alert>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ListadoFiltroAvanzado;
