import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { recuperarPassword } from '../../services/apiAuthService';
import '../LoginPage/./style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect } from 'react';
import { useParams } from "react-router";
import { ValidarEnlace } from '../../services/apiMenuService';
import { useHistory } from 'react-router-dom'

const RecuperarPassword = () => {
    const recaptchaRef = React.useRef();
    const { solicitud } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usuario = await ValidarEnlace(solicitud)

                if (usuario?.fueCorrecto == true) {
                    setUsuario(usuario.data)
                    setValue('solicitudId', usuario.data.idSolicitud)
                }
                else {
                    toast.error(usuario.mensaje)
                    history.push(`/`);
                }
            }
            catch {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
                history.push(`/`);
            }
        };
        fetchData();
    }, [solicitud]);

    const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            password: '',
            copiaPassword: '',
            solicitudId: ''
        },
        reValidateMode: 'onBlur'
    });

    const submitForm = async (data) => {
        try {
            setLoading(true)

            const captchaToken = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            const result = await recuperarPassword({
                solicitudId: data.solicitudId,
                nuevaPassword: data.password,
                captcha: captchaToken
            })

            if (result?.fueCorrecto == true) {
                toast.success(result.mensaje)
            }
            else {
                toast.error(result.mensaje)
            }
            history.push(`/`);
        }
        catch (exception) {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            console.log(exception)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <>
            {usuario != null ?
                <Grid className="loginWrapper">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                    />
                    <Grid className="loginForm">
                        <h2>Recuperar Password</h2>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                className="inputOutline"
                                                fullWidth
                                                placeholder="Password"
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                name="password"
                                                label="Password"
                                                error={!!error}
                                                type="password"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                        rules={{
                                            required: 'Este campo es requerido.',
                                            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?()\s_.:,;!@$%^&*-]).{8,}$/i//que tenga mayusculas y numers y caracteres
                                        }}
                                    />
                                    {errors?.password && errors?.password.type === "required" && <span className="invalid">{errors?.password.message}</span>}
                                    {errors?.password && errors?.password.type === "pattern" && <span className="invalid">La contraseña debe poseer un mímino de 8 carácteres,debe poseer números, mayúsculas y carácteres especiales.</span>}

                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="copiaPassword"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <TextField
                                                className="inputOutline"
                                                fullWidth
                                                error={!!error}
                                                placeholder="repita password"
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                name="copiaPassword"
                                                type="password"
                                                label="Repita Password"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                        rules={{
                                            required: 'Este campo es requerido.',
                                            validate: value => getValues('password') === value

                                        }}

                                    />
                                    {errors?.copiaPassword && errors?.copiaPassword.type === "required" && <span className="invalid">{errors?.password.message}</span>}
                                    {errors?.copiaPassword && errors?.copiaPassword.type === "validate" && <span className="invalid">La contraseña no coincide.</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid className="formFooter">
                                        <Button fullWidth disabled={isLoading} className="cBtnTheme" type="submit">
                                            {isLoading ? <i className="fa fa-spinner fa-spin fa-2x"></i> : null}
                                            Aceptar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <div className="shape-img">
                            <i className="fi flaticon-honeycomb"></i>
                        </div>
                    </Grid>
                </Grid> : null}
        </>
    )
};

export default withRouter(RecuperarPassword);