import React from 'react'
import LoaderGif from '../../images/Spinner.gif'
import './loader.css';

const PageLoader = () => {
  return (
    <div className='loader-container'>
        <div className='loader'>
            <img src={LoaderGif} alt='loading' />
        </div>
    </div>
  )
}

export default PageLoader