import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Card, Row, Col } from "react-bootstrap-v5";
import { useForm, Controller } from "react-hook-form";
import { getClientePorId, updateCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import TabSuscripcionesMiCliente from "./TabSuscripcionesMiCliente";
import TabEditarMiCliente from "./TabEditarMiCliente";
import TabApiKeyMiCliente from "./TabApiKeyMiCliente";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../appStatus/actions/actionPagina";

const MiCliente = () => {

    const user = useSelector(store => store.session.user)
    const [miCliente, setMiCliente] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        setMiCliente(null)
        const fetchData = async () => {
            dispatch(setIsLoading(true))
            try {
                //obtengo informacion desde BD para obtener detalle de suscripciones
                const clienteBD = await getClientePorId(user.clienteId);
                if (clienteBD) {
                    setMiCliente(clienteBD)
                }
            } catch (error) {
                toast.error("Ocurrio un error inesperado: " + error)

            } finally {
                dispatch(setIsLoading(false))
            }
        };
        fetchData();
    }, [user])

    const updateParent = () => {

    }
    return (
        <Container className='mt-5 mb-5'>

            <Row>
                <Col>
                    <Tabs defaultActiveKey="cliente" id="tab-editar-cliente">
                        <Tab eventKey="cliente" title="Datos Cliente" className="mb-3">
                            <TabEditarMiCliente
                                clienteEditar={miCliente}
                                updateParent={() => { updateParent() }}
                            />
                        </Tab>
                        <Tab eventKey="apiKey" title="Api Key" className="mb-3">
                            <TabApiKeyMiCliente
                                clienteEditar={miCliente}
                            />
                        </Tab>
                        <Tab eventKey="suscripciones" title="Suscripciones">
                            <TabSuscripcionesMiCliente
                                clienteEditar={miCliente}
                            />
                        </Tab>

                    </Tabs>
                </Col>
            </Row>


        </Container>
    );
};

export default MiCliente;