import * as actionTypes from "../constants/pingUsuarioActionTypes";

const initialState = {
  cuotaExcedida: false,
  mensaje: null
};


const setResultadoPingUsuario = (state, { payload }) => {

  return {
    cuotaExcedida: payload.cuotaExcedida,
    mensaje: payload.mensaje
  }

}

const pingUsuarioReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SET_RESULTADO_PING_USUARIO:
      return setResultadoPingUsuario(state, action);
    default:
      return state;
  }
};

export default pingUsuarioReducer;
