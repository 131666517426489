import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setFiltroCargado } from "../../appStatus/actions/actionPagina";
import PowerBi from "../PowerBi";

const GraficosSection = () => {
  const dispatch = useDispatch();
  const IdRegion = useSelector((store) => store.pagina.valueCombobox);
  const filtroCargado = useSelector((store) => store.pagina.filtroCargado);

  useEffect(() => {
    if (IdRegion) {
      dispatch(setFiltroCargado(true));
    }
  }, [IdRegion]);

  console.log(IdRegion);

  let { reporte } = useParams();

  const filterRegion = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CNS_DIMENSIONES DT_REGION",
      column: "Region_id",
    },
    operator: "In",
    values: [6],
    filterType: 1,
    requireSingleSelection: true,
  };

  const filtrosReporteGraficos = [filterRegion];

  return (
    <div>
      {filtroCargado && (
        <PowerBi
          reportId={reporte}
          requiereFiltros={true}
          filtrosReporte={filtrosReporteGraficos}
          
        />
      )}
    </div>
  );
};

export default GraficosSection;
