import React, { useEffect, useState } from "react";
import { ModalFooter, Modal, ModalBody, Button, FormGroup, FormText, Row, Col } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
// import { ModalHeader, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setIsLoading } from "../../appStatus/actions/actionPagina";
import { updateProducto } from "../../services/adminProductoService";

const ModalEditarProducto = ({ show, handleClose, updateParent, instituciones, tiposProductos, producto }) => {
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        reset,
        setValue
    } = useForm({ reValidateMode: "onBlur" });


    useEffect(() => {
        if (producto) {
            reset()
            setValue("Descripcion", producto.Descripcion)
            setValue("Codigo", producto.Codigo)
            setValue("InstitucionId", producto.InstitucionId)
            setValue("TipoProductoId", producto.TipoProductoId)
            setValue("EsSoloAdmin", producto.EsSoloAdmin)
        }
    }, [producto, reset, setValue])


    const onSubmit = async (data) => {
        let formData = {
            ProductoId: producto.ProductoId,
            // Codigo:producto.Codigo,//no se modificara
            // EsSoloAdmin: producto.EsSoloAdmin,//no se modificara
            Descripcion: data.Descripcion,
            TipoProductoId: Number(data.TipoProductoId),
            InstitucionId: Number(data.InstitucionId),
        };
        //console.log(formData)

        dispatch(setIsLoading(true));
        try {
            const res = await updateProducto(formData);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
                updateParent();
                handleClose();
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        }
        finally {
            dispatch(setIsLoading(false));
        }
    };



    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader>Actualizar producto: {producto?.Descripcion}</ModalHeader>
                <ModalBody>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <FormGroup className="my-3">

                                    <FormText>Descripción</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("Descripcion",
                                                {
                                                    required: true
                                                }
                                            )}
                                            name="Descripcion"
                                        />
                                    </div>
                                    {errors.Descripcion && errors.Descripcion.type === "required" && (
                                        <span className="invalid">Campo nombre es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="my-3">
                                    <FormText>Código</FormText>
                                    <div className="input-group">
                                        <input
                                            readOnly={true}
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("Codigo",
                                                {
                                                    required: true,//TODO validacion de que sea unico
                                                }
                                            )}
                                            name="Codigo"
                                        />
                                    </div>
                                    {errors.Codigo && errors.Codigo.type === "required" && (
                                        <span className="invalid">Campo contacto es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="my-3">

                                    <FormText>Institución</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="InstitucionId"
                                            render={({ field }) => (
                                                <select
                                                    className="form-control"
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    defaultValue={producto?.InstitucionId}
                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    {instituciones?.map((r, e) => (
                                                        <option key={e} value={r.InstitucionId}>
                                                            {r.InstitucionNombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </div>
                                    {errors.InstitucionId && errors.InstitucionId.type === "required" && (
                                        <span className="invalid">Campo institución es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="my-3">
                                    <FormText>Tipo Producto</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="TipoProductoId"
                                            render={({ field }) => (
                                                <select
                                                    className="form-control"
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    defaultValue={producto?.TipoProductoId}

                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    {tiposProductos?.map((r, e) => (
                                                        <option key={e} value={r.TipoProductoId}>
                                                            {r.Descripcion}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            rules={{ required: true }}
                                        />

                                    </div>
                                    {errors.TipoProductoId && errors.TipoProductoId.type === "required" && (
                                        <span className="invalid">Campo tipo producto es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-check">
                                    <input type="checkbox"
                                        className="form-check-input"
                                        readOnly={true}
                                        {...register('EsSoloAdmin')}
                                    />
                                    <label className="form-check-label"
                                    >
                                        Es solo admin
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn btn-primary"
                        onClick={(e) => handleSubmit(onSubmit)()}
                    >
                        Actualizar
                    </Button>
                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default ModalEditarProducto;