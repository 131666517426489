import React from "react";
import simg from "../../images/slider/right-img5.png";

const AboutS4 = (props) => {
  return (
    <section
      className={`wpo-about-section-s4 section-padding ${props.abClass}`}
    >
      <div className="container">
        <div className="wpo-about-items">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="wpo-about-wrap">
                <div className="wpo-about-img">
                  <img src={props.abImg} alt="" />
                  <div className="shp-1"></div>
                  <div className="shp-2"></div>
                </div>
                <div className="ab-shape">
                  <img src={simg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <h2>{props.Atitle}</h2>
                <p>
                  Este desarrollo es generado por el Consorcio Tecnológico del
                  Agua, COTH2O, financiado por CORFO, código 20CTECGH-145896,
                  constituyendo una asociación de universidades, centros de
                  investigación, empresas y organizaciones que buscan contribuir
                  a mejorar la gestión eficiente e integrada de los recursos
                  hídricos del país mediante la generación de conocimiento
                  científico, soluciones tecnológicas, innovación, transferencia
                  tecnológica y formación de capital humano. Esta asociación la
                  componen la Universidad de Concepción, Pontificia Universidad
                  Católica de Chile, Instituto de Investigaciones Agropecuarias
                  INIA, UC Davis Chile Life Science Innovation Center,
                  Universidad de Chile, Universidad Bernardo O`Higgins,
                  Universidad Diego Portales y más de 20 empresas y
                  organizaciones relacionadas con el recurso.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutS4;
