import axios from "axios";

const urlApi = process.env.REACT_APP_API_GATEWAY;
const urlApiLocal = process.env.REACT_APP_API_GATEWAY_LOCAL;

const getAllDominios = async () => {
  return axios.get(`${urlApi}/Dominio`).then((res) => {
    return res.data;
  });
};

const getAllRegiones = async () => {
  return axios.get(`${urlApi}/Regiones`).then((res) => {
    return res.data;
  });
};

const getAllEntidades = async () => {
  return axios.get(`${urlApi}/Entidad`).then((res) => {
    return res.data;
  });
};

const getAllCategorias = async () => {
  return axios.get(`${urlApi}/Categoria`).then((res) => {
    return res.data;
  });
};

const getAllVariables = async () => {
  return axios.get(`${urlApi}/Variables`).then((res) => {
    return res.data;
  });
};

const getAllEstaciones = async () => {
  return axios.get(`${urlApi}/Estaciones`).then((res) => {
    return res.data;
  });
};
const getAllReportes = async () => {
  return axios.get(`${urlApi}/Reportes`).then((res) => {
    return res.data;
  });
};
 const getVariablesReportes = async () => {
  return axios.get(`${urlApi}/api/Variables/ReporteVariables`).then((res) => {
    return res.data;
  });
}

const Registro_UserModal = async (data) => {
  //console.log(data);
  return axios.post(`${urlApi}/Registro_UserModal`, data).then((res) => {
    return res.data;
  });
};

const getSeccionReportesById = async (IdReporte) => {
  return axios
    .get(`${urlApi}/SeccionReportes?IdReportePowerBI=${IdReporte}`)
    .then((res) => {
      return res.data;
    });
};

const getReporteCalculadora = async (IdReporteCalculadora) => {
  //return [{"Reporte_Calculadora_Id":404,"Query_Estado":"Completado","Reporte_Token":"308596c7-9eb9-4230-8e90-5d8237105024"}]
  return axios
    .get(
      `${urlApi}/ReportesCalculadora?IdReporteCalculadora=${IdReporteCalculadora}`
    )
    .then((res) => {
      return res.data;
    });
};

const getMensajeCalculadora = async (IdReporteCalculadora) => {
  return axios
    .get(`${urlApi}/Calculadora/${IdReporteCalculadora}`)
    .then((res) => {
      return res.data;
    });
};

const RegistroReporteCalculadora = async (data) => {
  return axios
    .post(`${urlApi}/RegistroCalculadoraInicial`, data)
    .then((res) => {
      return res.data;
    });
};

const getReportePowerBI = async (idReporte, tokenCaptcha, esTemporal) => {
  return axios
    .get(`${urlApi}/Reporte/${idReporte}/${tokenCaptcha}/${esTemporal}`)
    .then((res) => {
      return res.data;
    });
};

const getObtenerEntidadVariable = async (payload) => {
  return axios
  .get(`${urlApi}/api/Variables/ObtenerEntidad`, { params: payload })
  .then((res) => {
    return res.data
  })
}

const getObtenerEstacionesEntidadesVariables = async (payload) => {
  return axios
  .get(`${urlApi}/api/Variables/ObtenerEstacionesEntidad`, { params: payload })
  .then((res) => {
    return res.data
  })
}

const getObtenerEntidadEstaciones = async (payload) => {
  return axios
  .get(`${urlApi}/api/Variables/ObtenerEntidadEstaciones`, { params: payload })
  .then((res) => {
    return res.data
  })
}

const getObtenerEntidadVariables = async (payload) => {
  return axios
  .get(`${urlApi}/api/Variables/ObtenerEntidadesVariables`, { params: payload })
  .then((res) => {
    return res.data
  })
} 

export const getIPCity = async () => {
  return axios
    .get("https://geolocation-db.com/json/4bdf2390-d062-11ec-81c2-0baa51ec38e1")
    .then((res) => {
      return res.data;
    });
};

const RegistroInformacionCalculadoraReporte = async (data) => {
  return axios.post(`${urlApi}/RegistroCalculadora`, data).then((res) => {
    return res.data;
  });
};

export const GenerarEnlaceRecuperacion = async (email, captcha) => {
  return axios
    .get(`${urlApi}/api/Usuario/SolicitudRecuperacion/${email}/${captcha}`)
    .then((res) => {
      return res.data;
    });
};

export const ValidarEnlace = async (link) => {
  return axios
    .get(`${urlApi}/api/Usuario/ValidarEnlace/${link}`)
    .then((res) => {
      return res.data;
    });
};

const getObtenerVariablesEntidad = async () => {
  return axios
    .get(`${urlApi}/api/Variables/ObtenerVariable`)
    .then((res) => {
      return res.data;
    });
};

const getObtenerEntidad = async () => {
  return axios
  .get(`${urlApi}/EntidadV2`)
  .then((res) => {
return res.data;
  });
}

export const getReporteCSV = async (
  IdEstacion,
  FechaDesde,
  FechaHasta,
  IdVariable,
  serieTemporal,
  valorEstadistica
) => {
  return axios
    .get(
      `${urlApi}/ReportesCSV/${IdEstacion}/${FechaDesde}/${FechaHasta}/${IdVariable}/${serieTemporal}/${valorEstadistica}`,
      {
        responseType: "arraybuffer",
        "Content-Type:": "application/csv",
      }
    )
    .then((res) => {
      return res;
    });
};

export const getExportarCsvAnomalias = async (
  IdEstacion,
  FechaDesde,
  FechaHasta,
  IdVariable,
  serieTemporal,
  valorEstadistica,
  selSensibilidad
) => {
  return axios
    .get(
      `${urlApi}/ReporteCSVAnomalias/${IdEstacion}/${FechaDesde}/${FechaHasta}/${IdVariable}/${serieTemporal}/${valorEstadistica}/${selSensibilidad}`,
      {
        responseType: "arraybuffer",
        "Content-Type:": "application/csv",
      }
    )
    .then((res) => {
      return res;
    });
};

export const getExportarCsvClima = async (
  IdEstacion,
  FechaDesde,
  FechaHasta,
  IdVariable,
  serieTemporal,
  valorEstadistica
) => {
  return axios
    .get(
      `${urlApi}/ReporteCSVClima/${IdEstacion}/${FechaDesde}/${FechaHasta}/${IdVariable}/${serieTemporal}/${valorEstadistica}`,
      {
        responseType: "arraybuffer",
        "Content-Type:": "application/csv",
      }
    )
    .then((res) => {
      return res;
    });
};
export const getReporteJSON = async (
  IdEstacion,
  FechaDesde,
  FechaHasta,
  IdVariable,
  serieTemporal,
  valorEstadistica
) => {
  return axios
    .get(
      `${urlApi}/ReportesJSON/${IdEstacion}/${FechaDesde}/${FechaHasta}/${IdVariable}/${serieTemporal}/${valorEstadistica}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getObtenerReporteTabla = async (resultado) => {
  return axios
    .get(
      `${urlApi}/ReportesTablaEstadistica?Estaciones=${resultado.Estaciones}&fechaInicio=${resultado.fechaInicio}&fechaFin=${resultado.fechaFin}&selVariable=${resultado.selVariable}&serieTemporal=${resultado.serieTemporal}&valorEstadistica=${resultado.valorEstadistica}&page=${resultado.page}&take=${resultado.take}`)
      //?IdReportePowerBI=${IdReporte}
    .then((res) => {
      return res.data;
    });
};

export const getObtenerReporteTablaFiltroClima = async (resultado) => {
  return axios
    .get(
      `${urlApi}/ReportesTablaCalculosAvanzados?Estaciones=${resultado.Estaciones}&fechaInicio=${resultado.fechaInicio}&fechaFin=${resultado.fechaFin}&selVariable=${resultado.selVariable}&serieTemporal=${resultado.serieTemporal}&valorEstadistica=${resultado.valorEstadistica}&page=${resultado.page}&take=${resultado.take}`)
    .then((res) => {
      return res.data;
    });
};

export const getObtenerReporteTablaFiltroAnomalias = async (resultado) => {
  return axios
    .get(
      `${urlApi}/ReportesTablaCalculosAvanzadosAnomalias?Estaciones=${resultado.Estaciones}&fechaInicio=${resultado.fechaInicio}&fechaFin=${resultado.fechaFin}&selVariable=${resultado.selVariable}&serieTemporal=${resultado.serieTemporal}&valorEstadistica=${resultado.valorEstadistica}&page=${resultado.page}&take=${resultado.take}`)
    .then((res) => {
      return res.data;
    });
};

export default {
  getAllDominios,
  getAllRegiones,
  getAllEntidades,
  getAllCategorias,
  getAllVariables,
  getAllEstaciones,
  Registro_UserModal,
  getAllReportes,
  getSeccionReportesById,
  RegistroReporteCalculadora,
  getReporteCalculadora,
  getMensajeCalculadora,
  getReportePowerBI,
  getIPCity,
  RegistroInformacionCalculadoraReporte,
  getVariablesReportes,
  getObtenerVariablesEntidad,
  getObtenerEntidad,
  getObtenerEntidadVariable,
  getObtenerEntidadEstaciones,
  getObtenerEstacionesEntidadesVariables,
  getObtenerEntidadVariables,
};
