import React, { useState } from 'react';
import { useEffect } from 'react';
import api from "../../../services/apiMenuService";
import ReCAPTCHA from "react-google-recaptcha";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

const DespliegueReporte = ({ reportId }) => {
    const recaptchaRef = React.useRef();

    const [reportConf, setReportConf] = useState(null);
    const [reportEmbed, setReportEmbed] = useState(null);

    useEffect(() => {
        if (reportId) {
            const fetchData = async () => {
                const captchaToken = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();

                const reporteID = await api.getReportePowerBI(reportId, captchaToken);
                setReportConf(reporteID);
            };
            fetchData();
        }
    }, [reportId]);

    return (
        <>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_KEY_CAPTCHA}
            />
            {reportConf ? (
                <>
                    <PowerBIEmbed
                        embedConfig={{
                            type: "report",
                            id: reportConf?.embedReport[0].reportId,
                            embedUrl: reportConf?.embedReport[0].embedUrl,
                            accessToken: reportConf?.embedToken.token,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: true,
                                        visible: true,
                                    },
                                    pageNavigation: {
                                        visible: true,
                                    },
                                },
                                background: models.BackgroundType.Transparent,
                                layoutType: models.LayoutType.Custom,
                                customLayout: {
                                    pageSize: {
                                        type: models.PageSizeType.Custom,
                                        width: "100%",
                                        height: "100%",
                                    },
                                    displayOption: models.DisplayOption.ActualSize,
                                },
                            },
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    "loaded",
                                    function () {
                                        console.log("Report loaded");
                                    },
                                ],
                                [
                                    "rendered",
                                    async function () {
                                        console.log("Report rendered");
                                    },
                                ],
                                [
                                    "error",
                                    function (event) {
                                        console.log("Error al cargar reporte");
                                        console.log(event.detail);
                                    },
                                ],
                            ])
                        }
                        cssClassName={"report-style-class"}
                        getEmbeddedComponent={(embeddedReport) => {
                            window.report = embeddedReport;
                            setReportEmbed(embeddedReport);
                            console.log("Fin loaded");
                        }}
                    />
                </>
            ) : (
                "Cargando"
            )}
        </>
    );
}

export default DespliegueReporte;