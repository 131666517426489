import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import appReducer from "../reducers/appReducer";
import paginaReducer from "../reducers/paginaReducer";
import pingUsuarioReducer from "../reducers/pingUsuarioReducer";
import modalInformacionReducer from "../reducers/modalInformacionReducer";
import { sessionReducer } from 'redux-react-session';
import { sessionService } from 'redux-react-session';
import { validateSession } from "../actions/sessionActions";

const reducers = {
    pagina: paginaReducer,
    aplicacion: appReducer,
    session: sessionReducer,
    pingUsuario: pingUsuarioReducer,
    modalInformacion: modalInformacionReducer
}

const reducer = combineReducers(reducers)
const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composedEnhancers(applyMiddleware(thunk)))

const options = { refreshOnCheckAuth: true, redirectPath: '/login', driver: 'COOKIES', validateSession };

sessionService.initSessionService(store, options)

export default store; 