import React from "react";
import { Navbar, NavLink } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  closeSidebar,
  openSidebar,
} from "../../appStatus/actions/actionPagina";

const HeaderFiltro = () => {
  const dispatch = useDispatch();
  const sidebarOpened = useSelector((store) => store.pagina.sidebarOpened);

  const toggleSidebar = () => {
    if (sidebarOpened) {
      dispatch(closeSidebar());
    } else {
      dispatch(openSidebar());
    }
  };
  return (
    <Navbar className="root d-print-none">
      <div>
        <NavLink
          onClick={() => toggleSidebar()}
          className="mr-1 navItem "
          href="#"
        >
          <i className="fa fa-filter fa-2x"></i>
        </NavLink>
      </div>
    </Navbar>
  );
};

export default HeaderFiltro;
