import React from 'react'
import {Link}  from 'react-router-dom'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> &copy; 2022 <Link to="/index"></Link>. Todos los derechos reservados.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;