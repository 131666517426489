import React, { useEffect, useState } from "react";
import { ModalFooter, Modal, ModalBody, Button, Row, Col } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
// import { ModalHeader, Label } from "reactstrap";
import { useForm, } from "react-hook-form";
import { useDispatch } from "react-redux";
import { deleteCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import { setIsLoading } from "../../appStatus/actions/actionPagina";

const ModalEliminarCliente = ({ show, handleClose, updateParent, client }) => {
    const dispatch = useDispatch();



    const onEliminarCliente = async () => {
        dispatch(setIsLoading(true))
        try {
            const res = await deleteCliente(client.ClienteId);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
                updateParent();
                handleClose();
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };

    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader>Advertencia</ModalHeader>
                <ModalBody>

                    <Row>
                        <Col>
                            <h5>¿Esta seguro de eliminar el cliente seleccionado?</h5>

                            <p>
                                Cliente: {client?.ClienteNombre}
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="danger"
                        onClick={() => onEliminarCliente()}
                    >
                        Eliminar
                    </Button>
                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default ModalEliminarCliente;