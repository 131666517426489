import config from "../api/axiosAuthInterceptor"
const urlApi = process.env.REACT_APP_API_GATEWAY
const urlApisBirh = process.env.REACT_APP_API_HELADAS
const axios = config.iAxios;



export const climaUrl=`${urlApisBirh}/api/Clima`
export const climaCsvUrl=`${urlApisBirh}/api/ClimaCsv`

export const anomaliasUrl=`${urlApisBirh}/api/Anomalias`
export const anomaliasCsvUrl=`${urlApisBirh}/api/AnomaliasCsv`


export const getSeriesTemporales = async (data) => {
    return axios.post(`${urlApi}/api/Apis/SeriesTemporales`, data).then(res => { return res.data })
}

export const getPrediccionHeladas = async (fecha, codigo) => {
    return axios.get(`${urlApi}/api/Apis/Prediccion_Heladas?Fecha=${fecha}&Codigo=${codigo}`).then(res => { return res.data })
}


export const getEstadisticas = async (payload) => {
    return axios.get(`${urlApi}/api/Apis/Estadisticas`, { params: payload }).then(res => { return res.data })
}
export const getEstadisticasCsv = async (payload) => {
    return axios
        .get(`${urlApi}/api/Apis/EstadisticasCsv`,
            {
                params: payload,
                responseType: "arraybuffer",
                "Content-Type:": "application/csv",
            }
        ).then(res => { return res })
}

export const getCalculoAvanzadoAnomalias = async (payload) => {
    return axios.get(`${urlApi}/api/Apis/Anomalias`, { params: payload }).then(res => { return res.data })
}
export const getCalculoAvanzadoAnomaliasCsv = async (payload) => {
    return axios
        .get(`${urlApi}/api/Apis/AnomaliasCsv`,
            {
                params: payload,
                responseType: "arraybuffer",
                "Content-Type:": "application/csv",
            }
        ).then(res => { return res })
}

export const getCalculoAvanzadoClima = async (payload) => {
    return axios.get(`${urlApi}/api/Apis/Clima`, { params: payload }).then(res => { return res.data })
}
export const getCalculoAvanzadoClimaCsv = async (payload) => {
    return axios
        .get(`${urlApi}/api/Apis/ClimaCsv`,
            {
                params: payload,
                responseType: "arraybuffer",
                "Content-Type:": "application/csv"
            }
        ).then(res => { return res })
}
