import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import Combobox from "../combobox";
import SubMenu from "../SubMenu";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../images/logo2.png";
import { logout } from "../../appStatus/actions/sessionActions";
import { toast } from "react-toastify";
import { PerfilPlataformaEnum } from "../../helpers/Enums";
import { getProductosUsuario } from "../../appStatus/actions/actionPagina";

const Header2 = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector((store) => store.session.authenticated);
  const user = useSelector((store) => store.session.user);

  const checked = useSelector((store) => store.session.checked);
  const romanoCombobox = useSelector((store) => store.pagina.romanoCombobox);
  const productos = useSelector((store) => store.pagina.productos);
  const reportesVariables = useSelector((store) => store.pagina.reportes);
  const [isSearchShow, setIsSearchShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (authenticated && productos == null) {
        dispatch(getProductosUsuario());
      }
    };
    fetchData();
  }, [productos, authenticated]);

  const searchHandler = () => {
    if (isSearchShow) {
      setIsSearchShow(false);
    } else setIsSearchShow(true);
  };

  const onCerrarSession = () => {
    dispatch(logout());
    toast.success("Sesión cerrada.");
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className="a1">
      <div className="wpo-site-header">
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-1 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/home"
                  >
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-md-8 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/home">
                        Home
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Visor
                      </Link>
                      <SubMenu />
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Laboratorio
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/labSitio">
                            Laboratorio de Datos Sitio
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/labMultiSitio">
                            Laboratorio de Datos Multisitio
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/*<li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Gráficos
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/tendencias">
                            Gráficos Especializados Tendencias
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/anomalias">
                            Gráficos Especializados Anomalías
                          </Link>
                        </li>
                      </ul>
  </li>*/}
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Gráficos
                      </Link>
                      <ul className="sub-menu">
                        {reportesVariables?.map((item) => {
                          return (
                            <li key={`${item?.IdVariable}-padre`}>
                              <Link onClick={ClickHandler} to="#">
                                {item?.NombreVariable}
                              </Link>
                              <ul className="sub-menu">
                                {item?.Reportes?.map((hijo) => {
                                  return (
                                    <li key={`${hijo.IdReporte}`}>
                                      <Link
                                        onClick={ClickHandler}
                                        to={`/Graficos/${hijo.IdReporte}`}
                                      >
                                        {hijo?.NombreReporte}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                    {checked && authenticated ? (
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="#">
                          Productos
                        </Link>
                        <ul className="sub-menu">
                          {productos?.map((item, index) => {
                            return (
                              <li key={`${index}-padre`}>
                                <Link onClick={ClickHandler} to="#">
                                  {item?.Institucion}
                                </Link>
                                <ul className="sub-menu">
                                  {item?.Productos?.filter(
                                    (p) => p.MostrarEnMenu
                                  ).map((hijo, indexHijo) => {
                                    return (
                                      <li
                                        key={`${indexHijo}-${hijo.nombreProducto}`}
                                      >
                                        <Link
                                          onClick={ClickHandler}
                                          to={`/productos/${hijo.Codigo}`}
                                        >
                                          {hijo?.nombreProducto}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ) : null}
                    {checked && authenticated && (
                      <li className="menu-item-has-children">
                        {/* TODO: cambiar <a> por <button> aplicando css */}
                        <a href="#">Administración</a>
                        <ul className="sub-menu">
                          {(user.perfilId ===
                            PerfilPlataformaEnum.Super_Usuario.value ||
                            user.perfilId ===
                              PerfilPlataformaEnum.Usuario_Autentificado
                                .value) && (
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/administracion/cliente"
                              >
                                Mi Cliente
                              </Link>
                            </li>
                          )}
                          {(user.perfilId ===
                            PerfilPlataformaEnum.Administrador.value ||
                            user.perfilId ===
                              PerfilPlataformaEnum.Super_Usuario.value) && (
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/administracion/usuarios"
                              >
                                Usuarios
                              </Link>
                            </li>
                          )}
                          {user.perfilId ===
                            PerfilPlataformaEnum.Administrador.value && (
                            <>
                              <li>
                                <Link
                                  onClick={ClickHandler}
                                  to="/administracion/clientes"
                                >
                                  Clientes
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={ClickHandler}
                                  to="/administracion/productos"
                                >
                                  Productos
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-2 col-2">
                <div className="header-right">
                  <div className="close-form">
                    {authenticated ? (
                      <button
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#0b5ed7",
                          borderColor: "#0a58ca",
                        }}
                        onClick={() => onCerrarSession()}
                      >
                        Cerrar Sesión
                      </button>
                    ) : (
                      <Link
                        onClick={ClickHandler}
                        className="btn btn-primary"
                        to="/login"
                      >
                        Iniciar Sesión
                      </Link>
                    )}
                  </div>
                  <div className="header-search-form-wrapper">
                    <div className="cart-search-contact">
                      <button
                        onClick={searchHandler}
                        className="btn btn-success"
                      >
                        {romanoCombobox} Región &nbsp;
                        <i
                          className={`${
                            isSearchShow ? "ti-search" : "ti-pencil"
                          }`}
                        ></i>
                      </button>
                      <div
                        className={`header-search-form ${
                          isSearchShow ? "header-search-content-toggle" : ""
                        }`}
                      >
                        <Combobox />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2;
