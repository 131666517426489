import React from "react";
import PowerBi from "../PowerBi";

const AnomaliasSection = () => {
  

  // let id = "9f2b0eee-cb55-4df3-a9e4-bc938e01925c"
  let id = "17"
  //let page = "anomalias";
  //let id = process.env.ID_REPORTE_VISOR
  return (
    <div>
      <PowerBi
        reportId={id} requiereFiltros={false}
      />
    </div>
  );
};

export default AnomaliasSection;