import React, { useEffect, useState } from "react";
import datosGlosa from "../../api/diccionario.json";

const GlosaSection = (props) => {
  const [search, setSearch] = useState("");
  const [filterlist, setFilterlist] = useState(datosGlosa);

  const onKeyUpHandler = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  useEffect(() => {
    if (search !== "") {
      const filteredList = datosGlosa.filter((item) => {
        let all_str = `${item.id} ${item.letra}`.toLowerCase();
        return all_str.indexOf(search) > -1 && search;
      });
      setFilterlist(filteredList);
    }else{
      const filteredList = datosGlosa
      setFilterlist(filteredList);
    }
  }, [search]);


  return (
    <section className="wpo-features-section-s6 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-features-item">
              <div className="wpo-features-text">
                <input
                  id="searchFilter"
                  type="text"
                  className="form-control"
                  defaultValue={search}
                  placeholder="Ingrese vocal o consonante a buscar"
                  onKeyUp={onKeyUpHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            {filterlist.map((item, i) => {
              return (
                <div key={i}>
                  <strong>
                    <h2>{item.letra}</h2>
                  </strong>
                  <hr />
                  {item.detalle.map((details, i) => {
                    return (
                      <div key={i}>
                        <h4>{details.titulo}</h4>
                        <p>{details.descripcion}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlosaSection;
