import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Row, Col, Card } from "react-bootstrap-v5";
import { useForm } from "react-hook-form";
import { getGenerarApiKeyPorClienteId, updateCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../appStatus/actions/actionPagina";


const TabApiKeyCliente = ({ clienteEditar }) => {

    const dispatch = useDispatch()
    const [apiKeyEditada, setApiKeyEditada] = useState(false)

    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
        setValue,
        getValues

    } = useForm({ reValidateMode: "onBlur" });

    useEffect(() => {
        if (clienteEditar) {
            reset()
            if (clienteEditar.ApiKeyPrefix) {
                setValue("ApiKey", clienteEditar.ApiKeyPrefix.padEnd(25, 'X'))
                setApiKeyEditada(false)
            }
        }
    }, [clienteEditar])


    const onSubmit = async (data) => {
        let formData = {
            "ClienteId": clienteEditar.ClienteId,
            "ApiKey": data.ApiKey,
        }

        try {

            const res = await updateCliente(formData);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        }
    };


    const onGenerarApiKey = async () => {
        dispatch(setIsLoading(true))
        try {
            const resultadoGeneracion = await getGenerarApiKeyPorClienteId(clienteEditar.ClienteId);
            if (resultadoGeneracion && resultadoGeneracion.fueCorrecto) {
                setValue("ApiKey", resultadoGeneracion.data.ApiKey)
                setApiKeyEditada(true)
            } else if (resultadoGeneracion && !resultadoGeneracion.fueCorrecto) {
                toast.error(resultadoGeneracion.mensaje)
            } else {
                toast.error("Ocurrio un error inesperado, intente nuevamente")
            }
        } catch (error) {
            toast.error("Ocurrio un error inesperado, intente nuevamente")
        } finally {
            dispatch(setIsLoading(false))
        }

    }

    return (

        <Card>
            <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="my-2">
                        <Col xs={12} md={10}>
                            <FormGroup>

                                <FormText>Api Key</FormText>

                                <input
                                    className="input-transparent pl-3 form-control"
                                    {
                                    ...register("ApiKey", {
                                        required: true,
                                        maxLength: 100
                                    }
                                    )}
                                    name="ApiKey"
                                    readOnly={true}
                                />
                                {errors.ApiKey && errors.ApiKey.type === "required" && (
                                    <span className="invalid">Campo nombre es obligatorio</span>
                                )}
                                {errors.ApiKey && errors.ApiKey.type === "maxLength" && (
                                    <span className="invalid">Campo nombre puede tener como máximo 100 caracteres</span>
                                )}
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>

                        <Col xs={12} md={3}>
                            <Button className="btn btn-info"
                                onClick={() => onGenerarApiKey()}>
                                {clienteEditar?.ApiKeyPrefix ? 'Regenerar' : 'Generar'}
                            </Button>
                        </Col>
                        <Col xs={12} md={3}>
                            <Button
                                className="btn btn-primary"
                                onClick={(e) => handleSubmit(onSubmit)()}
                                disabled={!apiKeyEditada}
                            >
                                Confirmar
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>

    );
};

export default TabApiKeyCliente;