import * as actionTypes from "../constants/actionTypes";
import { getProductos } from "../../services/privateService";

export const setIsLoading = (payload) => {
  return {
    type: actionTypes.IS_LOADING,
    payload,
  };
};
export const setResultado = (payload) => {
  return {
    type: actionTypes.SET_RESULTADO_FORMULARIO,
    payload,
  };
};

export const setFiltroReporte = (data) => (dispatch) => {
  dispatch(setFiltroReporteSuccess(data));
};

const setFiltroReporteSuccess = (payload) => {
  return {
    type: actionTypes.SET_FILTRO_REPORTE_SUCCESS,
    payload,
  };
};

export const addFiltroReportes = (data) => (dispatch) => {
  dispatch(addFiltroReporteSuccess(data));
  dispatch(setFiltroCargado(true));
};

export const getProductosUsuario = () => async (dispatch) => {
  try {
    const data = await getProductos();

    if (data?.fueCorrecto) dispatch(setProductosSuccess(data?.data));
  } catch (reason) {
    dispatch(setProductosSuccess([]));
  }
};

const setProductosSuccess = (payload) => {
  return {
    type: actionTypes.SET_PRODUCTOS,
    payload,
  };
};

const addFiltroReporteSuccess = (payload) => {
  return {
    type: actionTypes.ADD_FILTRO_REPORTE_SUCCESS,
    payload,
  };
};

export const addDatosReporte = (data) => (dispatch) => {
  dispatch(addDatosReporteSuccess(data));
};

const addDatosReporteSuccess = (payload) => {
  return {
    type: actionTypes.ADD_DATOS_REPORTE_SUCCESS,
    payload,
  };
};

export const addApiValues = (data) => (dispatch) => {
  dispatch(addApiValuesSuccess(data));
};

const addApiValuesSuccess = (payload) => {
  return {
    type: actionTypes.ADD_API_VALUE,
    payload,
  };
};

export const addRegion = (data) => (dispatch) => {
  dispatch(addRegionSuccess(data));
};
const addRegionSuccess = (payload) => {
  return {
    type: actionTypes.ADD_REGION,
    payload,
  };
};
export const addEntidad = (data) => (dispatch) => {
  dispatch(addEntidadSuccess(data));
};
const addEntidadSuccess = (payload) => {
  return {
    type: actionTypes.ADD_ENTIDAD,
    payload,
  };
};
export const addEstacion = (data) => (dispatch) => {
  dispatch(addEstacionSuccess(data));
};
const addEstacionSuccess = (payload) => {
  return {
    type: actionTypes.ADD_ESTACION,
    payload,
  };
};
export const addDominio = (data) => (dispatch) => {
  dispatch(addDominioSuccess(data));
};
const addDominioSuccess = (payload) => {
  return {
    type: actionTypes.ADD_DOMINIO,
    payload,
  };
};
export const addCategoria = (data) => (dispatch) => {
  dispatch(addCategoriaSuccess(data));
};
const addCategoriaSuccess = (payload) => {
  return {
    type: actionTypes.ADD_CATEGORIA,
    payload,
  };
};

export const getReportesVariables = (data) => (dispatch) => {
  dispatch(setReportesVariablesSuccess(data));
};

const setReportesVariablesSuccess = (payload) => {
  return {
    type: actionTypes.SET_REPORTE_VARIABLE,
    payload,
  };
};

export const getVariablesEntidad = (data) => (dispatch) => {
  dispatch(setVariablesEntidadSuccess(data));
};

const setVariablesEntidadSuccess = (payload) => {
  return {
    type: actionTypes.SET_VARIABLE_ENTIDAD,
    payload,
  };
};

export const getEntidades = (data) => (dispatch) => {
  dispatch(setEntidadesSuccess(data));
};

const setEntidadesSuccess = (payload) => {
  return {
    type: actionTypes.SET_ENTIDADES,
    payload,
  };
};

export const setFiltroCargado = (payload) => {
  return {
    type: actionTypes.SET_FILTRO_CARGADOS,
    payload,
  };
};

export const setExportar = (payload) => {
  return {
    type: actionTypes.SET_EXPORTAR,
    payload,
  };
};

export const random = (payload) => {
  return {
    type: actionTypes.RANDOM,
    payload,
  };
};

export const setEsCalculadora = (payload) => {
  return {
    type: actionTypes.SET_ES_CALCULADORA,
    payload,
  };
};

export const setEsJsonApi = (payload) => {
  return {
    type: actionTypes.SET_ES_JSON_API,
    payload,
  };
};

export const setPages = (payload) => {
  return {
    type: actionTypes.SET_PAGE,
    payload,
  };
};

export const LimpiaFiltros = () => (dispatch) => {
  dispatch(addFiltroReporteSuccess(null));
  dispatch(setFiltroCargado(false));
};

export const addNombreSubMenu = (data) => (dispatch) => {
  dispatch(addNombreSubMenuSuccess(data));
  dispatch(setFiltroCargado(true));
};

const addNombreSubMenuSuccess = (payload) => {
  return {
    type: actionTypes.ADD_VALUE_SUBMENU,
    payload,
  };
};

export const addValueCombobox = (data) => (dispatch) => {
  dispatch(addValueComboboxSuccess(data));
};

const addValueComboboxSuccess = (payload) => {
  return {
    type: actionTypes.ADD_VALUE_COMBOBOX,
    payload,
  };
};
export const addRomanoCombobox = (data) => (dispatch) => {
  dispatch(addRomanoComboboxSuccess(data));
};

const addRomanoComboboxSuccess = (payload) => {
  return {
    type: actionTypes.ADD_ROMANO_COMBOBOX,
    payload,
  };
};

export const addMensaje = (data) => (dispatch) => {
  dispatch(addMensajeSuccess(data));
};

const addMensajeSuccess = (payload) => {
  return {
    type: actionTypes.MENSAJE,
    payload,
  };
};

export const addReporteJsonApi = (data) => (dispatch) => {
  dispatch(addReporteJsonApiSuccess(data));
};

const addReporteJsonApiSuccess = (payload) => {
  return {
    type: actionTypes.ADD_REPORTE_JSON_API,
    payload,
  };
};

export const setRomanoCombobox = (payload) => {
  return {
    type: actionTypes.SET_ROMANO_COMBOBOX,
    payload,
  };
};

export const addValorPageReportPowerBI = (data) => (dispatch) => {
  dispatch(addValorPageReportPowerBISuccess(data));
};

const addValorPageReportPowerBISuccess = (payload) => {
  return {
    type: actionTypes.ADD_VALUE_PAGE_REPORT,
    payload,
  };
};

export function openSidebar() {
  return {
    type: actionTypes.OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {
    type: actionTypes.CLOSE_SIDEBAR,
  };
}

export const isRandom = (payload) => {
  return {
    type: actionTypes.IS_RANDOM,
    payload,
  };
};

export const setIsScreenShoot = (payload) => {
  return {
    type: actionTypes.IS_SCREENSHOOT,
    payload,
  };
};

export const setIsDescargarJson = (payload) => {
  return {
    type: actionTypes.IS_DESCARGARJSON,
    payload,
  };
};
