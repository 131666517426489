import React, { Fragment, useEffect } from 'react';
import { Col, Row, Card, Button } from "react-bootstrap-v5";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setIsLoading } from '../../appStatus/actions/actionPagina';
import { pingUsuario } from '../../services/privateService';

const UsuariosConcurrentes = ({ componente }) => {

    const dispatch = useDispatch()

    const authenticated = useSelector(store => store.session.authenticated)
    const cuotaExcedida = useSelector(store => store.pingUsuario.cuotaExcedida)

    useEffect(() => {
        const execute = async () => {
            dispatch(setIsLoading(true))
            await pingUsuario(dispatch);
            dispatch(setIsLoading(false))
        }
        execute()

    }, [])

    const onReintentar = async () => {
        dispatch(setIsLoading(true))
        await pingUsuario(dispatch);
        dispatch(setIsLoading(false))
    }

    return (
        <Fragment>
            {cuotaExcedida ?
                (
                    <>
                        <main className="content">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12}>
                                            <h5>Cuota Excedida</h5>
                                        </Col>
                                        <Col xs={12}>
                                            <p>Estimado usuario, le informamos que la cuota de usuarios concurrentes a sido alcanzada, favor reintente mas tarde</p>
                                            <Button onClick={() => onReintentar()}>Recargar</Button>
                                            {!authenticated
                                                && (
                                                    <>
                                                        <p>Si posee cuenta de acceso pruebe a iniciar sesión en el siguiente enlace: </p>
                                                        <Link className='btn btn-outline-primary' to="/login">Iniciar Sesión</Link>
                                                    </>

                                                )}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </main>

                    </>
                )
                :
                (<>
                    {componente} </>)}

        </Fragment>
    )
};
export default UsuariosConcurrentes;

