import axios from 'axios';
const urlApi = process.env.REACT_APP_API_GATEWAY

export const loginUser = async (usuario, tokenCaptcha) => {
    Object.assign(usuario, { captcha: tokenCaptcha });

    return axios.post(`${urlApi}/api/Usuario/Autenticar`, usuario).then(res => { return res.data })
}

export const updateToken = async (data) => {
    return axios.post(`${urlApi}/api/Usuario/AutenticarByRefresh`, data).then(res => { return res.data })
}

export const logoutUser = async (deviceId) => {
    return axios.get(`${urlApi}/api/Usuario/Logout/${deviceId}`).then(res => { return res.data })
}

export const recuperarPassword = async (data) => {
    return axios.post(`${urlApi}/api/Usuario/UpdatePassword`, data).then(res => { return res.data })
}

export const activarCuenta = async (data) => {
    return axios.post(`${urlApi}/api/Usuario/activar`, data).then(res => { return res.data })
}

export const existActivacionCuenta = async (codigo) => {

    return axios.head(`${urlApi}/api/Usuario/activar/${codigo}`)
        .then(res => {
            return true;
        })
        .catch(error => {
            return false;
        })
}
