import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import LabSitioSection from '../../components/LabSitioSection';
import UsuariosConcurrentes from '../../components/UsuariosConcurrentes/UsuariosConcurrentes';

const LabSitioPage = () => {
    return (
        <Fragment>
            <Navbar2 Logo={Logo} />
            <PageTitle pagesub={'Laboratorio de Datos Sitio'} />
            <UsuariosConcurrentes componente={<LabSitioSection />} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default LabSitioPage;