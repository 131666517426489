import React, { useEffect } from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import Homepage6 from "../HomePage6";
import VisorPage from "../VisorPage";
import GlosaPage from "../GlosaPage";
import LoginPage from "../LoginPage";
import LabSitioPage from "../LabSitioPage";
import LabMultiSitioPage from "../LabMultiSitioPage";
import AnomaliasPage from "../AnomaliasPage";
import TendenciasPage from "../TendenciasPage";
import GraficosPage from "../GraficosPage";
import AdministracionClientesPage from "../AdministracionClientesPage/AdministracionClientesPage";
import AdministracionMiClientePage from "../AdministracionMiClientePage/AdministracionMiClientePage";
import AdministracionProductos from "../AdministracionProductosPage/AdministracionProductos";
import AdministracionUsuariosPage from "../AdministracionUsuariosPage/AdministracionUsuariosPage";
import api from "../../services/apiMenuService";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategoria,
  addRegion,
  addDominio,
  addEntidad,
  addEstacion,
  setIsLoading,
  getReportesVariables,
  getEntidades,
  getVariablesEntidad,
} from "../../appStatus/actions/actionPagina";
import { setMensajeInformacion } from "../../appStatus/actions/actionMensajeInformacion";
import PrivateRoute from "./PrivateRoute";
import RecuperarPassword from "../RecuperacionPassword/RecuperarPassword";
import GeneracionPasswordPage from "../GeneracionPasswordPage/GeneracionPasswordPage";
import PrediccionHeladas from "../Productos/PrediccionHeladas/PrediccionHelada";
import SeriesTemporales from "../Productos/SeriesTemporales/SeriesTemporales";
import Monitoreo from "../Productos/Monitoreo/Monitoreo";
import NoEncontradoPage from "../NoEncontradoPage/NoEncontradoPage";
import PageLoader from "../../components/PageLoader/PageLoader";
import ModalInformacion from "../../components/Modals/ModalInformacion";

const AllRoute = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector((store) => store.session.authenticated);
  const checked = useSelector((store) => store.session.checked);
  const isLoading = useSelector((store) => store.pagina.isLoading);

  const mensajeInformacion = useSelector((store) => store.modalInformacion.mensaje);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      const region = await api.getAllRegiones();
      const entidad = await api.getAllEntidades();
      const dominio = await api.getAllDominios();
      const categoria = await api.getAllCategorias();
      const estaciones = await api.getAllEstaciones();
      const reportesVar = await api.getVariablesReportes();
      const varEntidad = await api.getObtenerVariablesEntidad();
      const entidadSola = await api.getObtenerEntidad();
      dispatch(addRegion(region));
      dispatch(addEntidad(entidad));
      dispatch(addDominio(dominio));
      dispatch(addCategoria(categoria));
      dispatch(addEstacion(estaciones));
      dispatch(getReportesVariables(reportesVar));
      dispatch(getEntidades(entidadSola));
      dispatch(getVariablesEntidad(varEntidad));
    };
    fetchData();
    dispatch(setIsLoading(false));
  }, []);

  return (
    <div className="App">
      <ModalInformacion
        mostrarModal={mensajeInformacion ? true : false}
        onCerrarModal={() => dispatch(setMensajeInformacion(null))}
      />

      {isLoading && <PageLoader />}
      {checked && (
        <HashRouter>
          <Switch>
            <Route exact path="/" component={Homepage6} />
            <Route path="/home" component={Homepage6} />
            <Route path="/visor/:valueSubMenu" component={VisorPage} />
            <Route path="/labSitio" component={LabSitioPage} />
            <Route path="/labMultiSitio" component={LabMultiSitioPage} />
            <Route path="/anomalias" component={AnomaliasPage} />
            <Route path="/tendencias" component={TendenciasPage} />
            <Route path="/graficos/:reporte" component={GraficosPage} />
            <Route path="/glosa" component={GlosaPage} />

            {/* Auth routes */}
            <Route path="/login" component={LoginPage} />
            <Route
              path="/recuperacion/:solicitud"
              exact
              component={RecuperarPassword}
            />
            <Route
              path="/activacion/:solicitud"
              exact
              component={GeneracionPasswordPage}
            />

            {/* Admin routes */}
            <PrivateRoute
              exact
              path="/administracion/cliente"
              component={AdministracionMiClientePage}
              authenticated={authenticated}
            />
            <PrivateRoute
              exact
              path="/administracion/clientes"
              component={AdministracionClientesPage}
              authenticated={authenticated}
            />
            <PrivateRoute
              exact
              path="/administracion/productos"
              component={AdministracionProductos}
              authenticated={authenticated}
            />
            <PrivateRoute
              exact
              path="/administracion/usuarios"
              component={AdministracionUsuariosPage}
              authenticated={authenticated}
            />
            <PrivateRoute
              exact
              path="/administracion/usuarios/:idUsuario"
              component={AdministracionUsuariosPage}
              authenticated={authenticated}
            />

            {/* Products routes */}
            <PrivateRoute
              exact
              path="/productos/PrediccionHeladas"
              component={PrediccionHeladas}
              authenticated={authenticated}
            />
            <PrivateRoute
              exact
              path="/productos/SeriesTemporales"
              component={SeriesTemporales}
              authenticated={authenticated}
            />
            <PrivateRoute
              exact
              path="/productos/Monitoreo"
              component={Monitoreo}
              authenticated={authenticated}
            />

            {/* 404 NotFound */}
            <Route path="*" component={NoEncontradoPage} />
          </Switch>
        </HashRouter>
      )}
    </div>
  );
};

export default AllRoute;
