import config from "../api/axiosAuthInterceptor"

const urlApi = process.env.REACT_APP_API_GATEWAY
const axios = config.iAxios;

export const getUsuarios = async () => {
    return axios.get(`${urlApi}/api/Usuario`).then(res => { return res.data })
}

export const existUsuarioByEmail = async (email, excludeEmail) => {

    let url = `${urlApi}/api/Usuario/email/${email}`
    if (excludeEmail) {
        url += `/${excludeEmail}`
    }
    return axios.head(url)
        .then(res => {
            return true;
        })
        .catch(error => {
            return false;
        })
}

export const saveUsuario = async (payload) => {
    return axios.post(`${urlApi}/api/Usuario/Guardar`, payload).then(res => { return res.data })
}

export const updateUsuario = async (payload) => {
    return axios.put(`${urlApi}/api/Usuario/Actualizar`, payload).then(res => { return res.data })
}
export const deleteUsuario = async (usuarioId) => {
    return axios.delete(`${urlApi}/api/Usuario/${usuarioId}`).then(res => { return res.data })
}
