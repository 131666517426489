import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const SubMenu = () => {

  const dominio = useSelector(store => store.pagina.dominio);
  const [dominioLimpio, setDominioLimpio] = useState([])

  useEffect(() => {
    if (dominio) {
      let dominiosSinNull = dominio.filter((e) => e.IdDominio !== -1);
      setDominioLimpio(dominiosSinNull);
    }

  }, [dominio]);

  /*const ClickHandler = (value) => {
    window.scrollTo(10, 0);
    dispatch(addNombreSubMenu(value))

  };*/

  return (
    <ul className="sub-menu">
      {dominioLimpio.map((item) => {
        return (
          <li key={item.IdDominio}>
            <Link to={`/visor/${item.IdDominio}`}>
              {item.NombreCodigo}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
export default SubMenu;
