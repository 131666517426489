import React, { useEffect, useState } from "react";
import { ModalFooter, Modal, ModalBody, Button, FormGroup, FormText, Row, Col, Tabs, Tab, Card } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
import { useForm, Controller } from "react-hook-form";
import { getClientePorId, updateCliente } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import TabSuscripcionesCliente from "./TabSuscripcionesCliente";
import TabProductosCliente from "./TabProductosCliente";
import TabEditarCliente from "./TabEditarCliente";
import TabApiKeyCliente from "./TabApiKeyCliente";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../appStatus/actions/actionPagina";

const ModalEditarCliente = ({ show, handleClose, updateParent, instituciones, productos, client }) => {

    const [clienteEditar, setClienteEditar] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {

        if (client) {
            setClienteEditar(null)
            const fetchData = async () => {
                dispatch(setIsLoading(true))
                try {
                    //obtengo informacion desde BD para obtener detalle de suscripciones
                    const clienteBD = await getClientePorId(client.ClienteId);
                    if (clienteBD) {
                        setClienteEditar(clienteBD)
                    }
                } catch (error) {
                    toast.error("Ocurrio un error inesperado: " + error)

                } finally {
                    dispatch(setIsLoading(false))
                }
            };
            fetchData();
        }
    }, [client])

 
    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader>Cliente: {client?.ClienteNombre}</ModalHeader>
                <ModalBody>
                    <Tabs defaultActiveKey="cliente" id="tab-editar-cliente">
                        <Tab eventKey="cliente" title="Datos Cliente" className="mb-3">
                            <TabEditarCliente
                                clienteEditar={clienteEditar}
                                updateParent={() => { updateParent(); handleClose() }}
                                instituciones={instituciones}
                            />
                        </Tab>
                        <Tab eventKey="apiKey" title="Api Key" className="mb-3">
                            <TabApiKeyCliente
                                clienteEditar={clienteEditar}
                            />
                        </Tab>
                        <Tab eventKey="suscripciones" title="Suscripciones">
                            <TabSuscripcionesCliente
                                clienteEditar={clienteEditar}
                            />
                        </Tab>
                        <Tab eventKey="productos" title="Productos">
                            <TabProductosCliente
                                clienteEditar={clienteEditar}
                                productos={productos}
                            />
                        </Tab>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default ModalEditarCliente;