import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import { Col, Container, Row } from 'react-bootstrap-v5';

const NoEncontradoPage = () => {
    return (
        <Fragment>
            <Navbar2 Logo={Logo} />
            <Container className='my-5'>

                <Row className='text-center my-5'>

                    <Col xs={12}>
                        <h1 >404</h1>
                    </Col>
                    <Col xs={12}>
                        <h5>
                            Página no encontrada
                        </h5>
                    </Col>
                </Row>
            </Container>

            <Scrollbar />
            <Footer />
        </Fragment>
    )
};
export default NoEncontradoPage;