import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import LoaderClock from '../../images/Clock.gif';
import './loaderModal.css';

const PageLoaderModal = ({  mensajeCalculadora }) => {

  const mensaje = useSelector((store) => store.pagina.mensaje);  

  return (
    <div className='loader-container-modal'>
        <div className='loader-modal'>
            <img src={LoaderClock} alt='loading' />
            <p>{mensajeCalculadora}</p>
        </div>
    </div>
  )
}

export default PageLoaderModal