import * as actionTypes from "../constants/actionTypes";

const initialState = {
  formularioFiltroReporte: null,
  valueCombobox: null,
  valueSubMenu: null,
  romanoCombobox: null,
  valueButton: null,
  sidebarOpened: false,
  filtroCargado: false,
  exportarArchivo: false,
  identificadorPagina: null,
  apisValues: null,
  esCalculadora: false,
  random: null,
  reporte: null,
  esJsonApi: false,
  reporteJsonApi: null,
  entidad: null,
  estacion: null,
  categoria: null,
  region: null,
  dominio: null,
  isLoading: false,
  mensaje: null,
  isRandom: false,
  isScreenShoot: false,
  productos: null,
  isDescargarJson: false,
  reportes: null,
  entidades: null,
  varEntidad: null,
};

const setProductos = (state, { payload }) => {
  return {
    ...state,
    productos: payload,
  };
};

const fetchLoading = (state, { payload }) => {
  return {
    ...state,
    isLoading: payload,
  };
};

const setFiltroReporte = (state, { payload }) => {
  return {
    ...state,
    formularioFiltroReporte: payload,
  };
};

const setFiltroCargado = (state, { payload }) => {
  return {
    ...state,
    filtroCargado: payload,
  };
};

const setExportar = (state, { payload }) => {
  return {
    ...state,
    exportarArchivo: payload,
  };
};

const setCalculadora = (state, { payload }) => {
  return {
    ...state,
    esCalculadora: payload,
  };
};

const setJsonApi = (state, { payload }) => {
  return {
    ...state,
    esJsonApi: payload,
  };
};
const setPages = (state, { payload }) => {
  return {
    ...state,
    identificadorPagina: payload,
  };
};

const addRandom = (state, { payload }) => {
  return {
    ...state,
    random: payload,
  };
};

const addFiltroReporte = (state, { payload }) => {
  return {
    ...state,
    formularioFiltroReporte: payload,
  };
};

const addReporteJsonApi = (state, { payload }) => {
  return {
    ...state,
    reporteJsonApi: payload,
  };
};

const addDatosReportes = (state, { payload }) => {
  return {
    ...state,
    reporte: payload,
  };
};

const addApisValues = (state, { payload }) => {
  return {
    ...state,
    apisValues: payload,
  };
};

const AddEntidad = (state, { payload }) => {
  return {
    ...state,
    entidad: payload,
  };
};

const AddEstacion = (state, { payload }) => {
  return {
    ...state,
    estacion: payload,
  };
};
const AddCategoria = (state, { payload }) => {
  return {
    ...state,
    categoria: payload,
  };
};
const AddRegion = (state, { payload }) => {
  return {
    ...state,
    region: payload,
  };
};
const AddDominio = (state, { payload }) => {
  return {
    ...state,
    dominio: payload,
  };
};

const AddEntidades = (state, { payload }) => {
  return {
    ...state,
    entidades: payload,
  };
};

const AddVarEntidades = (state, { payload }) => {
  return {
    ...state,
    varEntidad: payload,
  };
};

const setReportes = (state, { payload }) => {
  return {
    ...state,
    reportes: payload,
  };
};

const setValueCombobox = (state, { payload }) => {
  return {
    ...state,
    valueCombobox: payload,
  };
};

const addValueCombobox = (state, { payload }) => {
  return {
    ...state,
    valueCombobox: payload,
  };
};

const setRomanoCombobox = (state, { payload }) => {
  return {
    ...state,
    romanoCombobox: payload,
  };
};

const addRomanoCombobox = (state, { payload }) => {
  return {
    ...state,
    romanoCombobox: payload,
  };
};

const addMensaje = (state, { payload }) => {
  return {
    ...state,
    mensaje: payload,
  };
};

const setValueSubMenu = (state, { payload }) => {
  return {
    ...state,
    valueSubMenu: payload,
  };
};

const addValueSubMenu = (state, { payload }) => {
  return {
    ...state,
    valueSubMenu: payload,
  };
};
const setValueButton = (state, { payload }) => {
  return {
    ...state,
    valueButton: payload,
  };
};

const addValueButton = (state, { payload }) => {
  return {
    ...state,
    valueButton: payload,
  };
};

const isRandomOK = (state, { payload }) => {
  return {
    ...state,
    isRandom: payload,
  };
};

const isScreen = (state, { payload }) => {
  return {
    ...state,
    isScreenShoot: payload,
  };
};

const isDescargarJson = (state, { payload }) => {
  return {
    ...state,
    isDescargarJson: payload,
  };
};

const setResultadoPingUsuario = (state, { payload }) => {
  return {
    ...state,
    pingUsuario: {
      cuotaExcedida: payload.cuotaExcedidad,
      mensaje: payload.mensaje,
    },
  };
};

const paginaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTRO_REPORTE_SUCCESS:
      return setFiltroReporte(state, action);
    case actionTypes.ADD_FILTRO_REPORTE_SUCCESS:
      return addFiltroReporte(state, action);
    case actionTypes.ADD_API_VALUE:
      return addApisValues(state, action);
    case actionTypes.IS_LOADING:
      return fetchLoading(state, action);
    case actionTypes.ADD_REGION:
      return AddRegion(state, action);
    case actionTypes.ADD_ENTIDAD:
      return AddEntidad(state, action);
    case actionTypes.ADD_CATEGORIA:
      return AddCategoria(state, action);
    case actionTypes.ADD_DOMINIO:
      return AddDominio(state, action);
    case actionTypes.ADD_ESTACION:
      return AddEstacion(state, action);
    case actionTypes.SET_ENTIDADES:
      return AddEntidades(state, action);
    case actionTypes.SET_VARIABLE_ENTIDAD:
      return AddVarEntidades(state, action);
    case actionTypes.SET_VALUE_COMBOBOX:
      return setValueCombobox(state, action);
    case actionTypes.ADD_VALUE_COMBOBOX:
      return addValueCombobox(state, action);
    case actionTypes.ADD_ROMANO_COMBOBOX:
      return addRomanoCombobox(state, action);
    case actionTypes.SET_ROMANO_COMBOBOX:
      return setRomanoCombobox(state, action);
    case actionTypes.SET_VALUE_SUBMENU:
      return setValueSubMenu(state, action);
    case actionTypes.ADD_VALUE_SUBMENU:
      return addValueSubMenu(state, action);
    case actionTypes.SET_VALUE_BUTTON:
      return setValueButton(state, action);
    case actionTypes.ADD_VALUE_BUTTON:
      return addValueButton(state, action);
    case actionTypes.SET_FILTRO_CARGADOS:
      return setFiltroCargado(state, action);
    case actionTypes.SET_EXPORTAR:
      return setExportar(state, action);
    case actionTypes.SET_PAGE:
      return setPages(state, action);
    case actionTypes.SET_ES_CALCULADORA:
      return setCalculadora(state, action);
    case actionTypes.SET_ES_JSON_API:
      return setJsonApi(state, action);
    case actionTypes.ADD_DATOS_REPORTE_SUCCESS:
      return addDatosReportes(state, action);
    case actionTypes.RANDOM:
      return addRandom(state, action);
    case actionTypes.ADD_REPORTE_JSON_API:
      return addReporteJsonApi(state, action);
    case actionTypes.IS_RANDOM:
      return isRandomOK(state, action);
    case actionTypes.IS_SCREENSHOOT:
      return isScreen(state, action);
    case actionTypes.MENSAJE:
      return addMensaje(state, action);
    case actionTypes.IS_DESCARGARJSON:
      return isDescargarJson(state, action);
    case actionTypes.OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: true,
      });
    case actionTypes.CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: false,
      });
    case actionTypes.SET_PRODUCTOS:
      return setProductos(state, action);
    case actionTypes.SET_REPORTE_VARIABLE:
      return setReportes(state, action);
    default:
      return state;
  }
};

export default paginaReducer;
