import React, { useState } from 'react';
import Navbar2 from '../../../components/Navbar2';
import PageTitle from '../../../components/pagetitle';
import Footer from '../../../components/footer';
import Scrollbar from '../../../components/scrollbar';
import Logo from '../../../images/logo2.png';
import { Tab, Tabs, Row, Col, Container, Form } from "react-bootstrap-v5";
import PrediccionTabs from './PrediccionTabs';
import GraficoPrediccionTabs from './GraficoPrediccionTabs';

const PrediccionHeladas = () => {
    return (
        <>
            <Navbar2 Logo={Logo} />
            <PageTitle pagesub={'Predicción de heladas'} />
            <Container style={{ minHeight: '700px' }}>
                <Row className="justify-content-md-center" style={{ marginBottom: '10px' }}>
                    <Col xs={12} lg={12} md={12}>
                        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mt-2">
                            <Tab eventKey="profile" title="Predicción de heladas" style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }}>
                                <PrediccionTabs></PrediccionTabs>
                            </Tab>
                            <Tab eventKey="grafico" title="Reporte Predicción de heladas" style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }}>
                                <GraficoPrediccionTabs></GraficoPrediccionTabs>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>

            <Footer />
            <Scrollbar />
        </>
    )
};
export default PrediccionHeladas;