import React, { useState } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import ModalMessage from "../../components/Modals/ModalMessage/index";
import { Provider } from "react-redux";
import store from "../../appStatus/store/store";

const App = () => {
  const [mostrarModal, setMostrarModal] = useState(true);

  return (
    <Provider store={store}>
      <div className="App" id="scrool">
        <ModalMessage
          mostrarModal={mostrarModal}
          onCerrarModal={() => setMostrarModal(false)}
        />

        <AllRoute />
        <ToastContainer />
      </div>
    </Provider>
  );
};

export default App;
