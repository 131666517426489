import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
import Calculadora from "../../Calculadora";
import PageLoaderModal from "../../PageLoaderModal/PageLoaderModal";

export const ModalDownload = ({
  mostrarModal,
  huboErrorEnCalculadora,
  limpiarErrorEnCalculadora,
  cerrarModalCalculadora,
  mensajeCalculadora,
  ocultarModal,
}) => {
  const Cargando = useSelector((store) => store.pagina.isLoading);
  //const mensaje = useSelector((store) => store.pagina.mensaje);
  const random = useSelector((store) => store.pagina.random);


  return (
    <Modal
      show={mostrarModal}
      centered
      onHide={ocultarModal}
      backdrop="static"
      keyboard={false}
    >
      {!random ? (
        <ModalHeader closeButton>
          <ModalTitle id="contained-modal-title-lg">Calculadora</ModalTitle>
        </ModalHeader>
      ) : (
        <ModalHeader>
          <ModalTitle id="contained-modal-title-lg">Calculadora</ModalTitle>
        </ModalHeader>
      )}
      <ModalBody>
        {Cargando && (
          <PageLoaderModal mensajeCalculadora={mensajeCalculadora} />
        )}

        {!Cargando && !huboErrorEnCalculadora && <Calculadora />}

        {!Cargando && huboErrorEnCalculadora && (
          <div>
            <p>
              El cálculo seleccionado no entregó información. ¿Desea generar
              otra consulta?.
            </p>
            <button
              className="btn btn-danger"
              onClick={() => cerrarModalCalculadora(false)}
            >
              Cerrar
            </button>
            <button
              className="btn btn-success"
              onClick={() => limpiarErrorEnCalculadora()}
            >
              Volver a Consultar
            </button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalDownload;
