import config from "../api/axiosAuthInterceptor"
import { getIPCity } from './apiMenuService'
import { setIsLoading } from "../appStatus/actions/actionPagina";
import { setResultadoPingUsuario } from "../appStatus/actions/actionPingUsuario";
const urlApi = process.env.REACT_APP_API_GATEWAY
const axios = config.iAxios;

export const getProductos = async () => {
    return axios.get(`${urlApi}/api/Usuario/Productos`).then(res => { return res.data })
}

export const getInstituciones = async () => {
    return axios.get(`${urlApi}/api/Institucion`).then(res => { return res.data })
}

export const getPerfiles = async () => {
    return axios.get(`${urlApi}/api/Perfil`).then(res => { return res.data })
}

export const pingUsuario = async (dispatch) => {

    try {
        const data = await getIPCity();

        const formData = {
            ip: data.IPv4,
            deviceId: localStorage.getItem('deviceId')
        };

        const result = await axios.post(`${urlApi}/api/Usuario/ping`, formData).then(res => { return res.data })
        const obj = {
            cuotaExcedida: true,
            mensaje: ''
        }
        if (result) {
            if (result.data?.deviceId) {
                localStorage.setItem('deviceId', result.data.deviceId)
            }

            obj.mensaje = result.mensaje;
            obj.cuotaExcedida = !result.fueCorrecto;
            dispatch(setResultadoPingUsuario(obj))
        } else {
            obj.mensaje = 'Ocurrio un error al obtener el resultado'
            dispatch(setResultadoPingUsuario(obj))
        }
        return;
    } catch (error) {
        console.log(error)
    }

}

