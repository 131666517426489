import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import Footer from '../../components/footer'
import Logo from '../../images/logo2.png'
import { useSelector } from "react-redux";
import PageTitle from '../../components/pagetitle';
import PageLoader from '../../components/PageLoader/PageLoader';

import MiCliente from '../../components/AdministracionMiCliente/MiCliente';
const AdministracionMiClientePage = () => {


    // const authenticated = useSelector(store => store.session)
    const isLoading = useSelector(store => store.pagina.isLoading)
    return (
        <Fragment>
            {isLoading && (<PageLoader />)}
            <Navbar2 Logo={Logo} />
            <PageTitle pagesub={'Administración / Mi Cliente'} />
            <MiCliente />
            <Footer />
        </Fragment>
    )
};
export default AdministracionMiClientePage;