import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap-v5";
import { Controller, useForm } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  addFiltroReportes,
  LimpiaFiltros,
} from "../../appStatus/actions/actionPagina";
import apiMenuService from "../../services/apiMenuService";

const FiltroVisor = ({ valorDominio }) => {
  const dispatch = useDispatch();
  const regionSeleccionada = useSelector((store) => store.pagina.valueCombobox);
  const dominioVariable = useSelector((store) => store.pagina.categoria);

  const [variableSeleccionada, setvariableSeleccionada] = useState(null);
  const [estacionSeleccionada, setEstacionSeleccionada] = useState(null);
  const [variableEntidad, setVariableEntidad] = useState(null);
  const [entidadEstaciones, setEntidadEstaciones] = useState(null);
  const [estacionesObtenidas, setEstacionesObtenidas] = useState(null);
  const [variableSelected, setVariableSelected] = useState(null);

  const {
    control,
    handleSubmit,
    //setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Variable: null,
      Entidad: null,
      Estacion: null,
    },
  });

  useEffect(() => {
    if (valorDominio && dominioVariable) {
      let variableSeleccionada = dominioVariable.filter(
        (v) => v.dom_Dominio_Variable_Id === Number(valorDominio)
      );
      setVariableSelected(variableSeleccionada);
    }
  }, [valorDominio, dominioVariable]);

  useEffect(() => {
    if (variableSeleccionada !== null) {
      let VariableSeleccionada = variableSeleccionada[0].var_Variable_Id;
      const fetchData = async () => {
        const obtenerEntidad = await apiMenuService.getObtenerEntidadVariable({
          Variables:
            VariableSeleccionada instanceof Array
              ? VariableSeleccionada
              : [VariableSeleccionada],
        });
        setVariableEntidad(obtenerEntidad);
      };
      fetchData();
    }
  }, [variableSeleccionada]);

  useEffect(() => {
    if (
      entidadEstaciones !== null &&
      entidadEstaciones.length > 0 &&
      regionSeleccionada &&
      variableSeleccionada
    ) {
      let EntidadEstaciones = entidadEstaciones[0].IdEntidad;
      const fetchData = async () => {
        const obtenerEstaciones =
          await apiMenuService.getObtenerEntidadEstaciones({
            Entidades:
              EntidadEstaciones instanceof Array
                ? EntidadEstaciones
                : [EntidadEstaciones],
            IdRegion: regionSeleccionada,
            IdVariable: variableSeleccionada[0].var_Variable_Id,
          });
        setEstacionesObtenidas(obtenerEstaciones);
      };
      fetchData();
    }
  }, [entidadEstaciones, regionSeleccionada, variableSeleccionada]);
  /* Parte nueva de variable entidad y estacion */
  useEffect(() => {
    if (valorDominio) {
      reset();
      dispatch(LimpiaFiltros());
    }
  }, [valorDominio]);

  const submitForm = async (data) => {
    try {
      if (data) {
        const form = {
          Variable: data.Variable[0].var_Variable_Id,
          Entidad: data.Entidad[0].IdEntidad,
          Estacion: data.Estacion[0].est_Estacion_Id,
        };
        dispatch(addFiltroReportes(form));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <nav className="sidebar-root">
      <header className="logo">
        <span className="title"></span>
      </header>
      <Accordion>
        <form onSubmit={handleSubmit(submitForm)} name="formDatosVisor">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="">
              Filtros
            </Accordion.Toggle>
            <Card.Body>
              <div className="mb-3">
                <label>Variable </label>
                <Controller
                  control={control}
                  name="Variable"
                  render={({ field: { value, onChange } }) => (
                    <Multiselect
                      displayValue="var_Variable_Nombre"
                      selectionLimit="1"
                      options={variableSelected ?? []}
                      showCheckbox={false}
                      loading={variableSelected ? false : true}
                      loadingMessage={"Cargando..."}
                      hidePlaceholder={true}
                      closeOnSelect={false}
                      onSelect={(val) => {
                        setvariableSeleccionada(val);
                        onChange(val);
                      }}
                      onRemove={onChange}
                      selectedValues={value}
                      emptyRecordMsg={"No se encontraron opciones."}
                      placeholder="Seleccione una opción"
                      //ref={multiselectRef}
                    />
                  )}
                  rules={{ required: "Este campo es requerido." }}
                />
                {errors.Variable && errors.Variable.type === "required" && (
                  <span className="valid-message">
                    Campo Variable es obligatorio
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label>Entidad </label>
                <Controller
                  control={control}
                  name="Entidad"
                  render={({ field: { value, onChange } }) => (
                    <Multiselect
                      //ref={multiselectRef2}
                      displayValue="NombreEntidad"
                      selectionLimit="1"
                      options={variableEntidad ?? []}
                      showCheckbox={false}
                      loading={variableEntidad ? false : true}
                      loadingMessage={"Cargando..."}
                      hidePlaceholder={true}
                      closeOnSelect={false}
                      onSelect={(val) => {
                        setEntidadEstaciones(val);
                        onChange(val);
                      }}
                      onRemove={onChange}
                      //selectedValues={value}
                      emptyRecordMsg={"No se encontraron opciones."}
                      placeholder="Seleccione una opción"
                    />
                  )}
                  rules={{ required: "Este campo es requerido." }}
                />
                {errors.Entidad && errors.Entidad.type === "required" && (
                  <span className="valid-message">
                    Campo Entidad es obligatorio
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label>Estación </label>
                <Controller
                  control={control} //est_Estacion_Id
                  name="Estacion"
                  render={({ field: { value, onChange } }) => (
                    <Multiselect
                      //ref={multiselectRef3}
                      displayValue="est_Estacion_Nombre"
                      selectionLimit="1"
                      options={estacionesObtenidas ?? []}
                      showCheckbox={false}
                      loading={estacionesObtenidas ? false : true}
                      loadingMessage={"Cargando..."}
                      hidePlaceholder={true}
                      closeOnSelect={false}
                      onSelect={(val) => {
                        setEstacionSeleccionada(val);
                        onChange(val);
                      }}
                      onRemove={onChange}
                      selectedValues={value}
                      emptyRecordMsg={"No se encontraron opciones."}
                      placeholder="Seleccione una opción"
                    />
                  )}
                  rules={{ required: "Este campo es requerido." }}
                />

                {errors.Estacion && errors.Estacion.type === "required" && (
                  <span className="valid-message">
                    Campo Estación es obligatorio
                  </span>
                )}
              </div>
            </Card.Body>
          </Card>
          <Card>
            <div className="btn-group">
              <button
                className="btn btn-success"
                type="submit"
                //onClick={(e) => ValidarForm(e)}
              >
                {" "}
                Mostrar Datos
              </button>
            </div>
          </Card>
        </form>
      </Accordion>
    </nav>
  );
};

export default FiltroVisor;
