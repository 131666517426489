import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import LabMultiSitioSection from '../../components/LabMultiSitioSection';
import UsuariosConcurrentes from '../../components/UsuariosConcurrentes/UsuariosConcurrentes';

const LabMultiSitioPage =() => {
    return(
        <Fragment>
            <Navbar2 Logo={Logo}/>
            <PageTitle pagesub={'Laboratorio de Datos MultiSitio'}/> 
            <UsuariosConcurrentes componente={<LabMultiSitioSection/>} />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default LabMultiSitioPage;