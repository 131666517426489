import React, { useEffect } from "react";
import { Button, FormGroup, FormText, Row, Col, Card } from "react-bootstrap-v5";
import { useForm, Controller } from "react-hook-form";
import { saveSuscripcion } from "../../services/adminClienteService";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator'
import { DatePicker } from "react-nice-dates";
import { es } from "date-fns/locale";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../appStatus/actions/actionPagina";
const dayjs = require('dayjs')

const TabSuscripcionesCliente = ({ clienteEditar }) => {

    const dispatch = useDispatch()

    const {
        control: controlSuscripciones,
        handleSubmit: handleSubmitSuscripciones,
        formState: { errors: errorsSuscripciones },
        register: registerSuscripciones,
        reset: resetSuscripciones,

    } = useForm({ reValidateMode: "onBlur" });

    useEffect(() => {
        if (clienteEditar) {
            resetSuscripciones()
        }
    }, [clienteEditar])


    const onSubmitSuscripciones = async (data) => {
        let formData = {
            "ClienteId": clienteEditar.ClienteId,
            "CuotaSuscripcion": Number(data.CuotaSuscripcion),
            "CuotaUsuariosConcurrentes": Number(data.CuotaUsuariosConcurrentes),
            "FechaInicioSuscripcion": data.FechaInicioSuscripcion,
            "FechaFinSuscripcion": data.FechaFinSuscripcion
        }


        dispatch(setIsLoading(true))
        try {
            const res = await saveSuscripcion(formData);
            if (res.fueCorrecto) {
                resetSuscripciones()
                toast.success(res.mensaje);

                clienteEditar.Suscripciones.push(res.data);
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };


    const columns = [
        {
            dataField: 'FechaCreacion',
            text: 'Fecha Creación',
            sort: true,
            formatter: (cell, row) => {
                return (<>{dayjs(row.FechaCreacion).format('DD/MM/YYYY')}</>)
            }
        },
        {
            dataField: 'FechaInicio',
            text: 'Fecha inicio',
            sort: true,
            formatter: (cell, row) => {
                return (<>{dayjs(row.FechaInicio).format('DD/MM/YYYY')}</>)
            }
        },
        {
            dataField: 'FechaFin',
            text: 'Fecha fin',
            sort: true,
            formatter: (cell, row) => {
                return (<p>{dayjs(row.FechaFin).format('DD/MM/YYYY')}</p>)
            }
        },
        {
            dataField: 'Cuota',
            text: 'Cuota',
        },
        {
            dataField: 'CuotaUsuariosConcurrentes',
            text: 'Cuota Usuarios',
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {from} a {to} de {size} resultados
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }]
    };
    return (

        <Card>
            <Card.Body>

                <form>
                    <Row>
                        <Col xs={12} md={6} >
                            <FormGroup className="my-3 ">
                                <FormText>Fecha inicio</FormText>
                                <div className="input-group maxcustom">
                                    <Controller
                                        control={controlSuscripciones}
                                        name="FechaInicioSuscripcion"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker
                                                date={field.value}
                                                onDateChange={field.onChange}
                                                locale={es}
                                                format="dd/MM/yyyy"
                                            >
                                                {({ inputProps, focused }) => (
                                                    <input
                                                        className={'input-transparent pl-3 form-control input'}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </DatePicker>
                                        )}
                                    />
                                </div>
                                {errorsSuscripciones.FechaInicioSuscripcion && errorsSuscripciones.FechaInicioSuscripcion.type === "required" && (
                                    <span className="invalid">Campo Fecha inicio es obligatorio</span>
                                )}
                            </FormGroup>

                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className="my-3">
                                <FormText>Fecha fin</FormText>
                                <div className="input-group maxcustom">
                                    <Controller
                                        control={controlSuscripciones}
                                        name="FechaFinSuscripcion"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker
                                                // date={date}
                                                // onDateChange={setDate}
                                                date={field.value}
                                                onDateChange={field.onChange}
                                                locale={es}
                                                format="dd/MM/yyyy"
                                            >
                                                {({ inputProps, focused }) => (
                                                    <input
                                                        // className={'input' + (focused ? '-focused' : '')}
                                                        className={'input-transparent pl-3 form-control input'}
                                                        {...inputProps}
                                                    />
                                                )}
                                            </DatePicker>
                                        )}
                                    />
                                </div>
                                {errorsSuscripciones.FechaFinSuscripcion && errorsSuscripciones.FechaFinSuscripcion.type === "required" && (
                                    <span className="invalid">Campo Fecha fin es obligatorio</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className="my-3">
                                <FormText>Cuota mensual (MB)</FormText>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="input-transparent pl-3 form-control"
                                        {
                                        ...registerSuscripciones("CuotaSuscripcion",
                                            {
                                                required: true,
                                            }
                                        )}
                                        name="CuotaSuscripcion"
                                    />
                                </div>
                                {errorsSuscripciones.CuotaSuscripcion && errorsSuscripciones.CuotaSuscripcion.type === "required" && (
                                    <span className="invalid">Campo cuota es obligatorio</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup className="my-3">
                                <FormText>Cuota Usuarios</FormText>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="input-transparent pl-3 form-control"
                                        {
                                        ...registerSuscripciones("CuotaUsuariosConcurrentes",
                                            {
                                                required: true,
                                            }
                                        )}
                                        name="CuotaUsuariosConcurrentes"
                                    />
                                </div>
                                {errorsSuscripciones.CuotaUsuariosConcurrentes && errorsSuscripciones.CuotaUsuariosConcurrentes.type === "required" && (
                                    <span className="invalid">Campo cuota es obligatorio</span>
                                )}
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Button
                                className="btn btn-primary"
                                onClick={(e) => handleSubmitSuscripciones(onSubmitSuscripciones)()}
                            >
                                Agregar Suscripción
                            </Button>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col xs={12}><h5>Suscripciones Registradas</h5></Col>
                        <Col xs={12}>
                            {clienteEditar && clienteEditar.Suscripciones && (
                                <BootstrapTable
                                    bootstrap4
                                    keyField='SuscripcionId'
                                    data={clienteEditar.Suscripciones}
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                />
                            )}

                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>

    );
};

export default TabSuscripcionesCliente;