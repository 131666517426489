import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Row, Col, Container, Card, CardBody, CardTitle, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import { toast } from 'react-toastify';
import { setIsLoading } from '../../appStatus/actions/actionPagina';
import { getInstituciones } from '../../services/privateService';
import { getProductos, getTiposProductos } from '../../services/adminProductoService';
import ModalEditarProducto from './ModalEditarProducto';

const ListadoProductos = () => {

    const dispatch = useDispatch()

    const [instituciones, setInstituciones] = useState([])
    const [tiposProductos, setTiposProductos] = useState([])
    const [productos, setProductos] = useState([])
    const [updateProductList, setUpdateProductList] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        if (updateProductList) {
            const fetchData = async () => {
                dispatch(setIsLoading(true))
                try {
                    const productosResult = await getProductos();
                    setProductos(productosResult);

                } catch (error) {
                    toast.error("Ocurrio un error inesperado: " + error.message)

                } finally {
                    dispatch(setIsLoading(false))
                    setUpdateProductList(false);
                }
            }
            fetchData();
        }
    }, [updateProductList])

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true))
            try {

                const institucionesRes = await getInstituciones();
                setInstituciones(institucionesRes);

                const tiposProductosRes = await getTiposProductos();
                setTiposProductos(tiposProductosRes);

                const productosResult = await getProductos();
                setProductos(productosResult);
                
            } catch (error) {
                toast.error("Ocurrio un error inesperado: " + error.message)
            } finally {
                dispatch(setIsLoading(false))
            }

        }
        fetchData();
    }, [])


    const columns = [{
        dataField: 'Descripcion',
        text: 'Nombre'
    },
    {
        dataField: 'Institucion.Descripcion',
        text: 'Institución'
    },
    {
        dataField: 'TipoProducto.Descripcion',
        text: 'Tipo Producto'
    },
    {
        text: 'Ver',
        dataField: 'ProductoId',
        formatter: (cell, row) =>
        (
            <Button onClick={() => setSelectedProduct(row)}>Editar</Button>
        )
    }
    ];

    return (
        <>
            <ModalEditarProducto
                show={selectedProduct ? true : false}
                handleClose={() => setSelectedProduct(null)}
                updateParent={() => setUpdateProductList(true)}
                instituciones={instituciones}
                tiposProductos={tiposProductos}
                producto={selectedProduct}
            />
            <Container className='mt-5 mb-5'>

                <Card>
                    <CardBody>
                        <CardTitle className='mt-2 mb-5' tag="h4">
                            Productos
                        </CardTitle>
                        <Row>
                            <Col md={12}>
                                {productos && productos.length > 0 && (
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='ProductoId'
                                        data={productos}
                                        columns={columns} />
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>


            </Container>
        </>
    )
};
export default ListadoProductos;