import React from 'react';
import DespliegueReporte from '../powerBi/DespliegueReporte';

const DashboardMonitoreoTabs = () => {
    const id = '21'

    return (
        <>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <DespliegueReporte
                    reportId={id}
                ></DespliegueReporte>
            </div>
        </>
    )
};
export default DashboardMonitoreoTabs;