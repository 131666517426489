import React, { useEffect, useState } from "react";
import { ModalFooter, Modal, ModalBody, Button as ButtonBootstrap } from "react-bootstrap-v5";
import { ModalHeader, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import { GenerarEnlaceRecuperacion } from "../../services/apiMenuService";

const ModalRecuperacion = ({ mostrarModal, onCerrarModal, captcha }) => {
    const [isLoading, setLoading] = useState(false);

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            email: ''
        }
    });

    const cerrarModal = () => {
        onCerrarModal()
        reset()
    }
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            const captchaToken = await captcha.current.executeAsync()
            captcha.current.reset();
            const result = await GenerarEnlaceRecuperacion(data.email, captchaToken)

            if (result.fueCorrecto) {
                toast.success(result.mensaje)
                cerrarModal()
            }
            else {
                toast.error(result.mensaje)
            }
        } catch (error) {
            console.log(error)
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            setLoading(false)
        }
    };

    return (
        <Modal show={mostrarModal} centered>
            <ModalHeader>Recuperar contraseña</ModalHeader>
            <ModalBody>
                <Grid className="recuperacionForm">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <label className="form-label">Ingrese su e-mail registrado en el sitio, le enviaremos un enlace para recuperar su contraseña.</label>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            className="inputOutline"
                                            fullWidth
                                            placeholder="E-mail"
                                            value={value}
                                            onChange={onChange}
                                            variant="outlined"
                                            name="email"
                                            label="E-mail"
                                            error={!!error}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                    rules={{
                                        required: 'Este campo es requerido.',
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    }}
                                />
                                {errors?.email && errors?.email.type === "required" && <span className="invalid">{errors?.email.message}</span>}
                                {errors?.email && errors?.email.type === "pattern" && <span className="invalid">Ingrese un Email válido.</span>}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </ModalBody>
            <ModalFooter>
                <ButtonBootstrap className="btn btn-secondary" onClick={() => cerrarModal()}>
                    Cerrar
                </ButtonBootstrap>
                <Button className="cBtnTheme" disabled={isLoading} type="button" onClick={handleSubmit(onSubmit)}>
                    {isLoading ? <><i className="fa fa-spinner fa-spin fa-1x"></i> &nbsp;&nbsp;</> : null}
                    Aceptar
                </Button>
            </ModalFooter>

        </Modal>
    );
};

export default ModalRecuperacion;
