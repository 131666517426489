import * as actionTypes from "../constants/pingUsuarioActionTypes";



export const setResultadoPingUsuario = (payload) => {
  return {
    type: actionTypes.SET_RESULTADO_PING_USUARIO,
    payload,
  }
}

