import React, { useEffect, useState } from "react";
import { ModalFooter, Modal, ModalBody, Button, Row, Col } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
// import { ModalHeader, Label } from "reactstrap";
import { useForm, } from "react-hook-form";
import { useDispatch } from "react-redux";
import { deleteUsuario } from "../../services/adminUsuarioService";
import { toast } from "react-toastify";
import { setIsLoading } from "../../appStatus/actions/actionPagina";

const ModalEliminarUsuario = ({ show, handleClose, updateParent, user }) => {
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        reset,
        setValue
    } = useForm({ reValidateMode: "onBlur" });

    const [clientes, setClientes] = useState([]);
    const [perfiles, setPerfiles] = useState([]);

    useEffect(() => {
        if (user) {
            reset()
            setValue("Email", user.Username)
            setValue("NombreUsuario", user.Nombre)
            setValue("Representante", user.Representante)
            setValue("Contacto", user.Contacto)
            setValue("PerfilId", user.PerfilId)
            setValue("ClienteId", user.ClienteId)
        }
    }, [user, setValue])


    const onEliminarUsuario = async () => {
        dispatch(setIsLoading(true))
        try {
            const res = await deleteUsuario(user.UsuarioId);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
                updateParent();
                handleClose();
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };

    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader>Advertencia</ModalHeader>
                <ModalBody>

                    <Row>
                        <Col>
                            <h5>¿Esta seguro de eliminar el usuario seleccionado?</h5>

                            <p>
                                Nombres: {user?.Nombre}
                                <br></br>
                                Usuario:{user?.Username}
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="danger"
                        onClick={() => onEliminarUsuario()}
                    >
                        Eliminar
                    </Button>
                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default ModalEliminarUsuario;