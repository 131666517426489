import config from "../api/axiosAuthInterceptor"

const urlApi = process.env.REACT_APP_API_GATEWAY
const axios = config.iAxios;

export const getProductos = async () => {
    return axios.get(`${urlApi}/api/Producto`).then(res => { return res.data })
}

export const getTiposProductos = async () => {
    return axios.get(`${urlApi}/api/TipoProducto`).then(res => { return res.data })
}

export const updateProducto = async (payload) => {
    return axios.put(`${urlApi}/api/Producto`, payload).then(res => { return res.data })
}

