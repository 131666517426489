import React, { useEffect } from "react";
import { ModalFooter, Modal, ModalBody, Button, FormGroup, FormText, Row, Col } from "react-bootstrap-v5";
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader";
import { useForm, Controller } from "react-hook-form";
import { existUsuarioByEmail, saveUsuario } from "../../services/adminUsuarioService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../appStatus/actions/actionPagina";

const ModalAgregarUsuario = ({ show, handleClose, updateParent, clientes, perfiles }) => {
    const dispatch = useDispatch();
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
        reset
    } = useForm({ reValidateMode: "onBlur" });



    useEffect(() => {
        if (show) {
            reset()
        }
    }, [show])


    const onSubmit = async (data) => {

        //console.log(data)
        let formData = {
            ClienteId: Number(data.ClienteId),
            PerfilId: Number(data.PerfilId),
            Contacto: data.Contacto,
            Username: data.Email,
            Nombre: data.NombreUsuario,
            Representante: data.Representante
        };
        dispatch(setIsLoading(true))
        try {
            const res = await saveUsuario(formData);
            if (res.fueCorrecto) {
                toast.success(res.mensaje);
                updateParent();
                handleClose();
            } else {
                toast.error(res.mensaje);
            }

        } catch (error) {
            console.log(error);
            toast.error("Ocurrio un error inesperado, intentelo nuevamente");
        } finally {
            dispatch(setIsLoading(false))
        }
    };




    const ValidarFormulario = (e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
    };

    const validaEmailUsuario = async (value) => {
        if (!value)
            return "Campo email es obligatorio.";

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            return "Ingrese un email válido."
        }

        const existeEmail = await existUsuarioByEmail(value);
        if (existeEmail) {
            return "El email ingresado ya existe."
        }

        return true;
    }

    return (
        <div>
            <Modal show={show} onHide={() => handleClose()} size="lg">
                <ModalHeader>Nuevo usuario</ModalHeader>
                <ModalBody>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <FormGroup className="my-3">

                                    <FormText>Email</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("Email",
                                                {
                                                    validate: (value) => validaEmailUsuario(value)
                                                }
                                            )}
                                            name="Email"
                                        />
                                    </div>
                                    {errors?.Email && <span className="invalid">{errors?.Email.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="my-3">
                                    <FormText>Nombre</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("NombreUsuario",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            name="NombreUsuario"
                                        />
                                    </div>
                                    {errors.NombreUsuario && errors.NombreUsuario.type === "required" && (
                                        <span className="invalid">Campo nombre es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="my-3">

                                    <FormText>Representante</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("Representante",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            name="Representante"
                                        />
                                    </div>
                                    {errors.Representante && errors.Representante.type === "required" && (
                                        <span className="invalid">Campo representante es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="my-3">
                                    <FormText>Contacto</FormText>
                                    <div className="input-group">
                                        <input
                                            className="input-transparent pl-3 form-control"
                                            {
                                            ...register("Contacto",
                                                {
                                                    required: true,
                                                }
                                            )}
                                            name="Contacto"
                                        />
                                    </div>
                                    {errors.Contacto && errors.Contacto.type === "required" && (
                                        <span className="invalid">Campo contacto es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="my-3">

                                    <FormText>Perfil</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="PerfilId"
                                            render={({ field: { onChange, onBlur } }) => (
                                                <select
                                                    className="form-control"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                // onChange={(e) => changeRegion(field, e.target.value)}
                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    {perfiles.map((r, e) => (
                                                        <option key={e} value={r.PerfilId}>
                                                            {r.Descripcion}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </div>
                                    {errors.PerfilId && errors.PerfilId.type === "required" && (
                                        <span className="invalid">Campo perfil es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="my-3">
                                    <FormText>Cliente</FormText>
                                    <div className="input-group">
                                        <Controller
                                            control={control}
                                            name="ClienteId"
                                            render={({ field }) => (
                                                <select
                                                    className="form-control"
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}

                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    {clientes.map((r, e) => (
                                                        <option key={e} value={r.ClienteId}>
                                                            {r.ClienteNombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            rules={{ required: true }}
                                        />

                                    </div>
                                    {errors.ClienteId && errors.ClienteId.type === "required" && (
                                        <span className="invalid">Campo cliente es obligatorio</span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn btn-primary"
                        onClick={(e) => ValidarFormulario(e)}
                    >
                        Ingresar
                    </Button>
                    <Button className="btn btn-secondary" onClick={() => handleClose()}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAgregarUsuario;