import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { setIsLoading as setGlobalIsLoading } from "../../appStatus/actions/actionPagina";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { format, subDays } from "date-fns";
import { saveAs } from "file-saver";
import { setExportar } from "../../appStatus/actions/actionPagina";
import { setMensajeInformacion } from "../../appStatus/actions/actionMensajeInformacion";
import {
  getCalculoAvanzadoAnomalias,
  getCalculoAvanzadoAnomaliasCsv,
} from "../../services/apisService";
import ModalGuiaConsumoApi from "./ModalGuiaConsumoApi";
import { toast } from "react-toastify";
import { Alert } from "react-bootstrap-v5";
import { useRef } from "react";
const urlApisBirh = process.env.REACT_APP_API_HELADAS;

const ListadoFiltroAnomalias = () => {
  const dispatch = useDispatch();

  const componentMounted = useRef(true);

  const formFiltroReporte = useSelector((store) => store.pagina.formularioFiltroReporte);
  const botonExportar = useSelector((store) => store.pagina.exportarArchivo);
  const [isLoading, setIsLoading] = useState(false);
  const [resultadoFiltro, setResultadoFiltro] = useState([]);
  const [nombreVariable, setNombreVariable] = useState(null);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [selSensibilidad, setSelSensibilidad] = useState(0);

  const [dataModalGuiaConsumo, setDataModalGuiaConsumo] = useState(null);
  const [dataModalGuiaConsumoCsv, setDataModalGuiaConsumoCsv] = useState(null);

  const anomaliasUrl = `${urlApisBirh}Anomalias`;
  const anomaliasCsvUrl = `${urlApisBirh}AnomaliasCsv`;

  const {
    selVariable,
    selEstadisticas,
    FechaInicio,
    FechaFin,
    nombreSerieTemporal,
    Estaciones,
    reportSensibilidad,
  } = formFiltroReporte || [];

  const { tokenSensibilidadFinal } = reportSensibilidad || [];

  const { nombreSerieFinal } = nombreSerieTemporal || [];
  let nombreSerie;
  [nombreSerie] = nombreSerieFinal || [];
  if (nombreSerie === undefined || nombreSerie === null) {
    nombreSerie = "Original";
  }

  useEffect(() => {
    if (tokenSensibilidadFinal !== undefined) {
      let valor = tokenSensibilidadFinal[0].NombreSeccion;
      let valorFinal = valor.substring(0, 2);
      let valorSensibilidad = valorFinal / 100;
      setSelSensibilidad(Number(valorSensibilidad));
    }
  }, [tokenSensibilidadFinal]);

  useMemo(() => {
    //2950, 2022-08-23/2021-08-23/1, 2/ Año  / Promedio/1
    if (selVariable[0] === 6) {
      setNombreVariable("Acumulado");
    } else {
      setNombreVariable("Promedio");
    }
  }, [selVariable]);

  const handleError = (error) => {
    if (error?.response?.data?.statusCode === 403) {
      dispatch(setMensajeInformacion(error?.response?.data?.message))
    }
    else if (error?.response?.data?.statusCode === 429)
      dispatch(setMensajeInformacion("Demaciadas solicitudes intente nuevamente mas tarde"))
    else
      dispatch(setMensajeInformacion("Ocurrio un error inesperado, intente nuevamente mas tarde."))
  };

  useEffect(
    (page, sizePerPage) => {
      if (botonExportar) {
        const fetchTable = async () => {
          dispatch(setGlobalIsLoading(true));
          try {
            const resultadoFiltrosExportar = await getCalculoAvanzadoAnomalias({
              Estaciones: Estaciones, //2840
              FechaDesde: FechaInicio, //2022-03-01
              FechaHasta: FechaFin, //2022-03-03
              Variables:
                selVariable instanceof Array ? selVariable : [selVariable], //1
              SerieTemporal: nombreSerie, //Original
              Estadistica: nombreVariable, //Promedio
              SelSensibilidad: selSensibilidad, //0.6
              Pagina: page,
              CantidadRegistros: sizePerPage ? sizePerPage : 10,
            });

            if (componentMounted.current) {
              setResultadoFiltro(resultadoFiltrosExportar);
              setPage(page);
              setTotalSize(resultadoFiltrosExportar.TotalRegistros);
              setSizePerPage(Number(resultadoFiltrosExportar.Limite));
            }

            return () => {
              componentMounted.current(false);
            }
          } catch (error) {
            handleError(error);
          } finally {
            dispatch(setGlobalIsLoading(false));
            dispatch(setExportar(false));
          }
        };
        fetchTable();
      }
    },
    [
      botonExportar,
      Estaciones,
      FechaInicio,
      FechaFin,
      selVariable,
      nombreSerie,
      nombreVariable,
      selSensibilidad,
    ]
  );

  const onTableChange = async (type, { page, sizePerPage }) => {
    //fetchTable(page, sizePerPage)

    dispatch(setGlobalIsLoading(true));
    try {
      const resultadoFiltrosExportar = await getCalculoAvanzadoAnomalias({
        Estaciones: Estaciones, //2840
        FechaDesde: FechaInicio, //2022-03-01
        FechaHasta: FechaFin, //2022-03-03
        Variables: selVariable instanceof Array ? selVariable : [selVariable], //1
        SerieTemporal: nombreSerie, //Original
        Estadistica: nombreVariable, //Promedio
        SelSensibilidad: selSensibilidad, //0.6
        Pagina: page,
        CantidadRegistros: sizePerPage ? sizePerPage : 10,
      });

      if (componentMounted.current) {
        setResultadoFiltro(resultadoFiltrosExportar);
        setPage(page);
        setTotalSize(resultadoFiltrosExportar.TotalRegistros);
        setSizePerPage(Number(resultadoFiltrosExportar.Limite));
      }
      return () => {
        componentMounted.current(false);
      }

    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setGlobalIsLoading(false));
      dispatch(setExportar(false));
    }
  };

  const columns = [
    {
      text: "Id Entidad",
      dataField: "IdEntidad",
    },
    {
      text: "Id Estación",
      dataField: "IdEstacion",
    },
    {
      text: "Id Variable",
      dataField: "IdVariable",
    },
    {
      text: "Nombre Variable",
      dataField: "NombreVariable",
    },
    {
      text: "Nombre Estación",
      dataField: "EstacionNombre",
    },
    {
      text: "Nombre Entidad",
      dataField: "EntidadNombre",
    },
    {
      text: "Año",
      dataField: "Anio",
    },
    {
      text: "Mes",
      dataField: "Mes",
    },
    {
      text: "Fecha",
      dataField: "MedicionFecha",
    },
    {
      text: "Medición Valor",
      dataField: "MedicionValor",
    },
    {
      text: "IQR",
      dataField: "IQR",
    },
    {
      text: "Límite Inferior",
      dataField: "LimiteInferior",
    },
    {
      text: "Límite Superior",
      dataField: "LimiteSuperior",
    },
    {
      text: "Percentil Alto",
      dataField: "ValorPercentilAlto",
    },
    {
      text: "Percentil Bajo",
      dataField: "ValorPercentilBajo",
    },
  ];

  const exportarCSV = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (formFiltroReporte) {
        const resultadoFiltrosExportar = await getCalculoAvanzadoAnomaliasCsv({
          Estaciones,
          FechaDesde: FechaInicio,
          FechaHasta: FechaFin,
          Variables: selVariable instanceof Array ? selVariable : [selVariable],
          SerieTemporal: nombreSerie,
          Estadistica: nombreVariable,
          SelSensibilidad: selSensibilidad, //0.6
        });

        var blob = new Blob([resultadoFiltrosExportar.data], {
          type: resultadoFiltrosExportar.headers["content-type"],
        });
        saveAs(blob, "ReporteAnomalias.csv");
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setExportar(false));
      setIsLoading(false);
    }
  };

  const onVerModalGuiaConsumo = () => {
    const formData = {
      Estaciones: Estaciones, //2840
      FechaDesde: FechaInicio, //2022-03-01
      FechaHasta: FechaFin, //2022-03-03
      Variables: selVariable instanceof Array ? selVariable : [selVariable],
      SerieTemporal: nombreSerie, //Original
      Estadistica: nombreVariable, //Promedio
      SelSensibilidad: selSensibilidad, //0.6
      Pagina: 1,
    };
    setDataModalGuiaConsumo(formData);
  };
  const onVerModalGuiaConsumoCsv = () => {
    const formData = {
      Estaciones: Estaciones.map((x) => Number(x)), //2840
      FechaDesde: FechaInicio, //2022-03-01
      FechaHasta: FechaFin, //2022-03-03
      Variables: selVariable instanceof Array ? selVariable : [selVariable],
      SerieTemporal: nombreSerie, //Original
      Estadistica: nombreVariable, //Promedio
      SelSensibilidad: selSensibilidad, //0.6
    };
    setDataModalGuiaConsumoCsv(formData);
  };
  return (
    <>
      <ModalGuiaConsumoApi
        handleClose={() => setDataModalGuiaConsumo(null)}
        queryData={dataModalGuiaConsumo}
        apiData={{
          apiUrl: anomaliasUrl,
          titulo: "Cálculo avanzado: Anomalias",
        }}
        show={dataModalGuiaConsumo ? true : false}
      />
      <ModalGuiaConsumoApi
        handleClose={() => setDataModalGuiaConsumoCsv(null)}
        queryData={dataModalGuiaConsumoCsv}
        apiData={{
          apiUrl: anomaliasCsvUrl,
          titulo: "Cálculo avanzado: Anomalias Csv",
        }}
        show={dataModalGuiaConsumoCsv ? true : false}
      />
      <Container>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="btn-group" role="group">
                <button
                  name="csv"
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => exportarCSV(e)}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Exportar Csv Anomalías&nbsp;{" "}
                  {isLoading ? <i className="fa fa-spinner fa-spin"></i> : null}
                </button>
                <button
                  name="guiaConsumirApi"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onVerModalGuiaConsumo()}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Guia consumo API JSON
                </button>
                <button
                  name="guiaConsumirApi"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onVerModalGuiaConsumoCsv()}
                  disabled={
                    resultadoFiltro
                      ? resultadoFiltro?.Items?.length
                        ? false
                        : true
                      : true
                  }
                >
                  Guia consumo API CSV
                </button>
              </div>
            </CardTitle>
            <Row>
              <Col>
                <div className="table-responsive table-striped table-borderless table-hover">
                  {resultadoFiltro ? (
                    <BootstrapTable
                      remote
                      loading={loading}
                      data={resultadoFiltro.Items ?? []}
                      keyField="IdAutonumerico"
                      noDataIndication="No se encontraron registros."
                      columns={columns}
                      pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                      })}
                      onTableChange={onTableChange}
                    />
                  ) : (
                    <Alert className="text-center" variant="warning">
                      <Alert.Heading>
                        No se encontraron datos para esta consulta.
                      </Alert.Heading>
                    </Alert>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ListadoFiltroAnomalias;
